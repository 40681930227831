import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUsers } from '../Interface/index';

interface UsersState {
  userselected: IUsers,
}

const initialState: UsersState = {
  userselected: {
    guidcontrol: '',
    name: '',
  },
};

export const UsersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    saveUsers: (state, action: PayloadAction<IUsers>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.userselected = payload;
    },
  },
});

export default UsersSlice.reducer;
export const { saveUsers } = UsersSlice.actions;
