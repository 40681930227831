import { ILayoutPage } from 'Module/Layout/Interface';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HOME } from 'Shared/Constant/Routes';
import { ILogoutPage } from '../interface/ILogout';
import LogoutPage from '../Page/index';

function LogoutController(props: ILayoutPage) {
  const navigate = useNavigate();

  const setLogout = React.useCallback(() => new Promise<boolean>((resolve, reject) => {
    const isResult = true;
    try {
      // eslint-disable-next-line react/destructuring-assignment
      props.toggleDelProfile({
        id: '',
        nome: '',
        cargo: '',
        email: '',
        documento: '',
        userid: ''
      })
      navigate(HOME);
      navigate(0);
      resolve(isResult);
    } catch (err) {
      console.log('LogoutController.setLogout.err');
      console.log(err);
      reject(err);
    }
  }), [navigate, props]);

  React.useEffect(() => {
    console.log('LogoutController.props');
    console.log(props);
  }, [props]);

  const handlersPage: ILogoutPage = {
    layout: props?.layout,
    profile: props?.profile,
    setLogout,
  };

  return (
    <LogoutPage {...handlersPage} />
  );
}

export default LogoutController;
