import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import { ITargetsProduct, ITargetsProductContext, ITargetsProductFilters, ITargetsProductPage, ITargetsProductResponse } from '../Interface';
import TargetsProductPage from '../Page/index';
import { getTargetsProductService } from '../Service/Get/index';
import { KEY_TARGETPRODUCT_LIST, KEY_TARGETPRODUCT_SELECTED } from 'Shared/Constant/Cache';
import { IHomeTargetsSupplier, IHomeTargetsSupplierFilters, IHomeTargetsSupplierResponse } from 'Module/HomeUser/Interface/HomeTargetsSupplier';
import { getTargetSupplierService } from 'Module/HomeUser/Services/GetTargetSupplier';

function TargetsProductController(props: ILayoutPage) {
  const [contextTargetsProduct] = React.useState<ITargetsProductContext>({ result: [] });
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [filters, setFilters] = React.useState<ITargetsProductFilters>({
    codProduto: '',
    nomeProduto: '',
    ean: '',

    codigo: '',
    nome: '',
    documento: '',

    codigoFornec: '',
    documentFornec: '',
    nomeFornec: '',
  });

  const [showModalAssociateDetail, setShowModalAssociateDetail] = React.useState<boolean>(false);
  const toggleShowModalAssociateDetail = (value: boolean) => {
    setShowModalAssociateDetail(value);
  }

  const [titleModalTargetsSupplier, setTitleModalTargetsSupplier] = React.useState<string>('Meta');
  const toggleTitleModalTargetsSupplier = (value: string) => {
    setTitleModalTargetsSupplier(value);
  }

  const [listModalTargetsSupplier, setListModalTargetsSupplier] = React.useState<[IHomeTargetsSupplier?]>([])

  const toggleGetHomeTargetsSupplier = React.useCallback(async (filters: IHomeTargetsSupplierFilters) => {
    await getTargetSupplierService(filters)
      .then((response: IHomeTargetsSupplierResponse) => {
        setListModalTargetsSupplier(response.list || []);
      }).catch((error: IHomeTargetsSupplierResponse) => {
        console.log(error.message);
      })
  }, [])

  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'filters':
        setFilters(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  const [listTargetsProduct, setListTargetsProduct] = React.useState<[ITargetsProduct?]>([]);
  const toggleGetTargetsProduct = React.useCallback(async (filters: ITargetsProductFilters) => {
    toggleChangeState('showLoadingThisPage', true);
    toggleChangeState('filters', filters)

    await getTargetsProductService(filters)
      .then((response: ITargetsProductResponse) => {
        setListTargetsProduct(response.list || []);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', momenttz().tz('America/Sao_Paulo').format());

        const _array: [ITargetsProduct?] = response.list || [];
        props.toggleSetTargetsProductList(_array)
      })
      .catch((error: ITargetsProductResponse) => {
        let layoutAlert: ILayout = {
          context: props.layout.context,
          titleMessage: 'Pesquisar registros',
          typeMessage: 'error',
          message: error.message,
          loading: false,
        };

        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', momenttz().tz('America/Sao_Paulo').format());
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSetTargetsProduct = (targetsProduct: ITargetsProduct, action: string) => {

  };


  React.useEffect(() => {
    if (filters.nome || filters.nomeFornec || filters.nomeProduto) {
      toggleGetTargetsProduct(filters);
    }
    else {
      if (props.toggleDelCache) {
        props.toggleDelCache(KEY_TARGETPRODUCT_LIST);
      }
      toggleChangeState('showLoadingThisPage', false);
    }
  }, [filters, toggleGetTargetsProduct]); //eslint-disable-line

  React.useEffect(() => {
    let _showModal = window.location.hash.includes('#Create') ? true : false;
    toggleChangeState('showNewLeadModal', _showModal);
    if (props.toggleDelCache) {
      props.toggleDelCache(KEY_TARGETPRODUCT_SELECTED)
    }
  }, [props])

  React.useEffect(() => {
    let _array: [IHomeTargetsSupplier?] = [];
    if (props.targetProductSelected && props.targetProductSelected.id) {
      toggleTitleModalTargetsSupplier(`${props.targetProductSelected.codigo} - ${props.targetProductSelected.nome} `);

      const filters: IHomeTargetsSupplierFilters = {
        codigo: props.targetProductSelected.codigo
      }
      toggleGetHomeTargetsSupplier(filters)
    }
    setListModalTargetsSupplier(_array)
  }, [props.targetProductSelected, props.dateUpdateInfo, toggleGetHomeTargetsSupplier])

  const handlersPage: ITargetsProductPage = {
    ...props,
    context: contextTargetsProduct,
    listTargetsProduct,
    showLoadingThisPage,
    dateUpdateInfo,
    filters,
    itemSelected: {},
    showModalAssociateDetail,
    listModalTargetsSupplier,
    titleModalTargetsSupplier,
    toggleShowModalAssociateDetail,
    toggleChangeState,
    toggleGetTargetsProduct,
    toggleSetTargetsProduct,
  };

  return (
    <TargetsProductPage {...handlersPage} />
  );
}

export default TargetsProductController;
