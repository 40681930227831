import {
  Box, Typography, Grid,
} from '@mui/material';

import LogoImg from 'Assets/logologin.png';
import MoneyImg from 'Assets/money1.png';
import BoxImg from 'Assets/boxs1.png';
import SuccessImg from 'Assets/icon_velocimetro_01.png';
import SuccessLightImg from 'Assets/icon_velocimetro_02.png';
import WaringImg from 'Assets/icon_velocimetro_03.png';
import WaringLightImg from 'Assets/icon_velocimetro_04.png';
import DangerImg from 'Assets/icon_velocimetro_06.png';
import DangerLightImg from 'Assets/icon_velocimetro_05.png';
import ParserHTML from 'html-react-parser';
import PanelCustomConstant from 'Shared/Constant/PanelCustom/index';

import { IPanelCustom } from './Interface';
import React from 'react';

function PanelCustomPage(props: IPanelCustom) {

  const [classNameBGColor, setClassNameBGColor] = React.useState<string>('PanelCustomBGColorSuccess')
  const [classNameTitleColor, setClassNameTitleColor] = React.useState<string>('PanelCustomTitleColorSuccess')
  const [classNameTextColor, setClassNameTextColor] = React.useState<string>('PanelCustomTitleColorSuccess')
  const [classNameMessageColor, setClassNameMessageColor] = React.useState<string>('PanelCustomTitleColorSuccess')

  React.useEffect(() => {
    switch (props.alert) {
      case PanelCustomConstant.WARNING:
      case PanelCustomConstant.WARNING_LIGHT:
        setClassNameBGColor('PanelCustomBGColorWarning');
        setClassNameTitleColor('PanelCustomTitleColorWarning');
        setClassNameTextColor('PanelCustomTextBigColorWarning');
        setClassNameMessageColor('PanelCustomMessageColorWarning');
        break;
      case PanelCustomConstant.DANGER:
      case PanelCustomConstant.DANGER_LIGHT:
        setClassNameBGColor('PanelCustomBGColorDanger');
        setClassNameTitleColor('PanelCustomTitleColorDanger');
        setClassNameTextColor('PanelCustomTextBigColorDanger');
        setClassNameMessageColor('PanelCustomMessageColorDanger');
        break;
      default:
        setClassNameBGColor('PanelCustomBGColorSuccess');
        setClassNameTitleColor('PanelCustomTitleColorSuccess');
        setClassNameTextColor('PanelCustomTitleColorSuccess');
        setClassNameMessageColor('PanelCustomTitleColorSuccess');
        break;
    }
  }, [props])

  const RenderImage = (type: string) => {
    switch (type) {
      case PanelCustomConstant.TYPE_FAT:
        return (
          <img
            className='PanelCustomImg'
            src={`${MoneyImg}?w=283&h=164&fit=crop&auto=format`}
            srcSet={`${MoneyImg}?w=283&h=164&fit=crop&auto=format&dpr=2 1x`}
            alt="Imagem Faturamento"
            loading="lazy"
          />
        )
      case PanelCustomConstant.TYPE_MIX:
        return (
          <img
            className='PanelCustomImg'
            src={`${BoxImg}?w=283&h=164&fit=crop&auto=format`}
            srcSet={`${BoxImg}?w=283&h=164&fit=crop&auto=format&dpr=2 1x`}
            alt="Imagem Mix"
            loading="lazy"
          />
        )
      default:
        return (
          <img
            className='PanelCustomImg'
            src={`${LogoImg}?w=283&h=164&fit=crop&auto=format`}
            srcSet={`${LogoImg}?w=283&h=164&fit=crop&auto=format&dpr=2 1x`}
            alt="Logo"
            loading="lazy"
          />
        )
    }
  }

  const RenderAlert = (alert: string) => {
    switch (alert) {
      case PanelCustomConstant.SUCCESS:
        return (
          <img
            className='PanelCustomAlertImg'
            src={`${SuccessImg}?w=283&h=164&fit=crop&auto=format`}
            srcSet={`${SuccessImg}?w=283&h=164&fit=crop&auto=format&dpr=2 1x`}
            alt="Imagem Velocímetro bom"
            loading="lazy"
          />
        )
      case PanelCustomConstant.SUCCESS_LIGHT:
        return (
          <img
            className='PanelCustomAlertImg'
            src={`${SuccessLightImg}?w=283&h=164&fit=crop&auto=format`}
            srcSet={`${SuccessLightImg}?w=283&h=164&fit=crop&auto=format&dpr=2 1x`}
            alt="Imagem Velocímetro bom"
            loading="lazy"
          />
        )
      case PanelCustomConstant.WARNING:
        return (
          <img
            className='PanelCustomAlertImg'
            src={`${WaringImg}?w=283&h=164&fit=crop&auto=format`}
            srcSet={`${WaringImg}?w=283&h=164&fit=crop&auto=format&dpr=2 1x`}
            alt="Imagem Velocímetro Alerta"
            loading="lazy"
          />
        )
      case PanelCustomConstant.WARNING_LIGHT:
        return (
          <img
            className='PanelCustomAlertImg'
            src={`${WaringLightImg}?w=283&h=164&fit=crop&auto=format`}
            srcSet={`${WaringLightImg}?w=283&h=164&fit=crop&auto=format&dpr=2 1x`}
            alt="Imagem Velocímetro Alerta"
            loading="lazy"
          />
        )
      case PanelCustomConstant.DANGER_LIGHT:
        return (
          <img
            className='PanelCustomAlertImg'
            src={`${DangerLightImg}?w=283&h=164&fit=crop&auto=format`}
            srcSet={`${DangerLightImg}?w=283&h=164&fit=crop&auto=format&dpr=2 1x`}
            alt="Imagem Velocímetro Alerta"
            loading="lazy"
          />
        )
      default:
        return (
          <img
            className='PanelCustomAlertImg'
            src={`${DangerImg}?w=283&h=164&fit=crop&auto=format`}
            srcSet={`${DangerImg}?w=283&h=164&fit=crop&auto=format&dpr=2 1x`}
            alt="Imagem Velocímetro Ruim"
            loading="lazy"
          />
        )
    }
  }

  return (
    <Box id="PanelCustom" key="PanelCustom" className={`PanelCustomRoot ${classNameBGColor}`}>
      <Typography component="div" align="center">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} >
              <Grid item xs={8} sm={9} md={10} lg={10} xl={10}>
                <Typography component={'div'} align='left' sx={{ borderBottom: '1px solid #000000' }}>
                  <span className={`PanelCustomTitle ${classNameTitleColor}`}>{ParserHTML(props.title)}</span>
                </Typography>
              </Grid>
              <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                {RenderImage(props.type)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography component={'div'} align='left' >
              <span className={`PanelCustomTextBig ${classNameTextColor}`}>{ParserHTML(props.value)}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} >
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                {props.anotation &&
                  <Typography component={'div'} align='left' >
                    <span className={`PanelCustomMessage ${classNameMessageColor}`}>{ParserHTML(props.anotation)}</span>
                  </Typography>
                }
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography component={'div'} align='right' >
                  {RenderAlert(props.alert)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Typography>
    </Box >
  );
}

export default PanelCustomPage;
