import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHomeAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Box, Grid, Typography, Breadcrumbs, Button,
} from '@mui/material';

import { Link } from 'react-router-dom';
import { Form } from '@unform/web';

import { HOME, REPORTS } from 'Shared/Constant/Routes';
import { IReportDetailPage } from '../Interface';
import ReportHeaderPage from './ReportHeader/index';
import FieldsByGroupCustomPage from 'Shared/Components/Form/FieldsByGroupCustom/index';

function ReportDetailPage(props: IReportDetailPage) {
  const formDefault = React.useRef<any>(null);

  const handleSubmit = (data: any) => {

  }

  return (
    <Box id="ReportDetail" key="ReportDetail" className="ChildrenRoot">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left" sx={{ alignContent: 'center', alignItems: 'center' }}>
            <Box id="BreadcrumbsBox" className="Breadcrumbs">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={HOME}>
                  <FontAwesomeIcon icon={faHomeAlt} />
                </Link>
                <Link color="inherit" to={REPORTS}>
                  Relatório
                </Link>
              </Breadcrumbs>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReportHeaderPage {...props} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form
            ref={formDefault}
            id="myFormDefault"
            name="myFormDefault"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
                  <span>&nbsp;</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component="div" align="left">
                  <FieldsByGroupCustomPage
                    expanded={true}
                    title='Informações do Relatório'
                    structure={props.structure}
                    fields={props.item}
                    isTypeGroupEdit={props.isTypeGroupEdit ? true : false}
                    dateUpdateInfo={props.dateUpdateInfo}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Box>
  )
}


export default ReportDetailPage;
