import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import { IAssociates, IAssociatesContext, IAssociatesFilters, IAssociatesPage, IAssociatesResponse } from '../Interface';
import AssociatesPage from '../Page/index';
import { getAssociatesService } from '../Service/Get/index';
import { KEY_ASSOCIATES_SELECTED } from 'Shared/Constant/Cache';

function AssociatesController(props: ILayoutPage) {
  const [contextAssociates] = React.useState<IAssociatesContext>({ result: [] });
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [filters, setFilters] = React.useState<IAssociatesFilters>({ codigo: '', nome: '', documento: '', active: 'Todos' });

  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'filters':
        setFilters(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  const [listAssociates, setListAssociates] = React.useState<[IAssociates?]>([]);
  const toggleGetAssociates = React.useCallback(async (filters: IAssociatesFilters) => {
    toggleChangeState('showLoadingThisPage', true);
    toggleChangeState('filters', filters)

    await getAssociatesService(filters)
      .then((response: IAssociatesResponse) => {
        setListAssociates(response.list || []);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
      .catch((error: IAssociatesResponse) => {
        let layoutAlert: ILayout = {
          context: props.layout.context,
          titleMessage: 'Pesquisar registros',
          typeMessage: 'error',
          message: error.message,
          loading: false,
        };

        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSetAssociates = (Associates: IAssociates, action: string) => {

  };


  React.useEffect(() => {
    toggleGetAssociates(filters);
  }, [filters, toggleGetAssociates]);

  React.useEffect(() => {
    let _showModal = window.location.hash.includes('#Create') ? true : false;
    toggleChangeState('showNewLeadModal', _showModal);
    if (props.toggleDelCache) {
      props.toggleDelCache(KEY_ASSOCIATES_SELECTED)
    }
  }, [props])

  const handlersPage: IAssociatesPage = {
    ...props,
    context: contextAssociates,
    listAssociates,
    showLoadingThisPage,
    dateUpdateInfo,
    filters,
    itemSelected: {},
    toggleChangeState,
    toggleGetAssociates,
    toggleSetAssociates,
  };

  return (
    <AssociatesPage {...handlersPage} />
  );
}

export default AssociatesController;
