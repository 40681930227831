import React from 'react';
import momenttz from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

import {
  Grid,
  Box,
  Typography,
  MenuItem,
  IconButton,
  Menu,
  Link as LinkMUI,
}
  from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIcons } from '@fortawesome/free-solid-svg-icons';

import LogoTop from '../../../Assets/logologin.png';
import ProfilePage from './_profile';
import { IHeaderPage } from './interface';
import { IMenuListTop } from 'Shared/Interfaces/MenuTop/iMenuTop';
import { Link, NavLink } from 'react-router-dom';
import { HOME, LOGIN, SUPPLIERS } from 'Shared/Constant/Routes';

import packageJson from '../../../../package.json';

function HeaderPage(props: IHeaderPage) {
  const navigate = useNavigate();

  const [version, setVersion] = React.useState<string>('');
  React.useEffect(() => {
    setVersion(packageJson.version ? packageJson.version : '1.0.0');
  }, []);
  const [list, setList] = React.useState<IMenuListTop[]>([]);
  const toggleList = (values: IMenuListTop[]) => {
    setList(values);
  }

  const [pathName, setPathName] = React.useState<string>('/');
  const toggleSetHomePath = (pathname: string, hash: string) => {
    // eslint-disable-next-line react/destructuring-assignment
    props.toggleSetHomePath(pathname, hash);
  };

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };


  const setLogout = React.useCallback(() => new Promise<boolean>((resolve, reject) => {
    const isResult = true;
    try {
      // eslint-disable-next-line react/destructuring-assignment
      props.toggleSetProfile({
        id: '',
        nome: '',
        cargo: '',
        email: '',
        documento: '',
        userid: ''
      });
      navigate(LOGIN);
      navigate(0);
      resolve(isResult);
    } catch (err) {
      console.log('LogoutController.setLogout.err');
      console.log(err);
      reject(err);
    }
  }), [navigate, props]);

  const toggleLogout = () => {
    handleCloseNavMenu();
    // eslint-disable-next-line react/destructuring-assignment
    setLogout();
  };


  const toggleModalChangePassword = () => {
    handleCloseNavMenu();
    // eslint-disable-next-line react/destructuring-assignment
    if (props.toggleShowModalChangePassword) {
      props.toggleShowModalChangePassword(true);
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [anchorElAssociates, setAnchorElAssociates] = React.useState(null);
  const openAssociates = Boolean(anchorElAssociates);
  const handleClickSubMenu = (event: any, link: string) => {
    switch (link.toUpperCase()) {
      case SUPPLIERS.toUpperCase():
        setAnchorElAssociates(event.currentTarget);
        break;
      default:
        setAnchorElAssociates(null);
        break;
    }

  };
  const handleCloseSubMenu = (link: string, hash: string) => {
    switch (link.toUpperCase()) {
      case SUPPLIERS.toUpperCase():
        setAnchorElAssociates(null);
        setRoute(SUPPLIERS, hash);
        break;
      default:
        setAnchorElAssociates(null);
        break;
    }
  }


  const [dataUpdate, setDataUpdate] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format('yyyymmdd hhmmss'));
  const setRoute = (link: string, hash: string) => new Promise<boolean>((resolve, reject) => {
    const isResult = true;
    setDataUpdate(momenttz().tz('America/Sao_Paulo').format('yyyyMMdd hhmmss'));
    try {
      toggleSetHomePath(link, hash);
      resolve(isResult);
    } catch (err) {
      console.log('LogoutController.setLogout.err');
      console.log(err);
      reject(err);
    }
  });

  React.useEffect(() => {
    if (props?.menu?.pathName) {
      setPathName(props.menu.pathName);
    }
  }, [props?.menu?.pathName, dataUpdate, props?.menu?.hash]);

  React.useEffect(() => {

    toggleList(props.menu?.list || []);
  }, [props.menu, props.menu?.list])


  const handlerProfile: IHeaderPage = {
    ...props,
    toggleSetHomePath,
  };

  const RenderAssociates = () => {
    return (
      <Menu
        id='HeaderSubMenuGrid'
        aria-labelledby="associates-positioned-button"
        anchorEl={anchorElAssociates}
        open={openAssociates}
        onClose={() => { handleCloseSubMenu(SUPPLIERS, '') }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <NavLink
          key={'CriarAssociates'}
          onClick={() => {
            handleCloseSubMenu(SUPPLIERS, `#Create${momenttz().tz('America/Sao_Paulo').format('HHmmss')}`);
          }}
          to={{
            pathname: `${SUPPLIERS}`,
            hash: `#Create${momenttz().tz('America/Sao_Paulo').format('HHmmss')}`,
          }}
        >
          <MenuItem>
            <span>
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
              &nbsp;Criar Associado
            </span>
          </MenuItem>
        </NavLink>
        <NavLink
          key={'SearchAssociates'}
          onClick={() => {
            handleCloseSubMenu(SUPPLIERS, '#Search');
          }}
          to={{
            pathname: `${SUPPLIERS}`,
            hash: '#Search',
          }}
        >
          <MenuItem>
            <span>
              <i className="fas fa-search" aria-hidden="true"></i>
              &nbsp;Pesquisar
            </span>
          </MenuItem>
        </NavLink>
      </Menu>
    )
  }

  const RenderMenu = () => {
    return (
      <>
        {list.length > 0 && list.map((item, index) => {
          switch (item.link?.toUpperCase()) {
            case SUPPLIERS.toUpperCase():
              return (<React.Fragment key={`${index}${item.link}`}>
                <LinkMUI
                  id="HeaderMenuItem"
                  key={`${index}${item.link}`}
                  onClick={(env) => { handleClickSubMenu(env, SUPPLIERS) }}
                >
                  <FontAwesomeIcon
                    aria-label={`${item.label}`}
                    icon={item.icon || faIcons}
                    className={item.link === pathName ? 'HeaderMenuGridItemSelect' : ''}
                  />
                  <span
                    className={item.link === pathName ? 'HeaderMenuGridItemSelect' : ''}
                  >
                    &nbsp;{item.label}&nbsp;
                    <i className="fas fa-chevron-down" aria-hidden="true"></i>
                  </span>
                </LinkMUI>
                <RenderAssociates />
              </React.Fragment>
              )
            default:
              return (
                <React.Fragment key={`${index}${item.link}`}>
                  <Link
                    id="HeaderMenuItem"
                    key={`${index}${item.link}`}
                    to={item.link || HOME}
                    onClick={() => {
                      handleCloseSubMenu(item.link || HOME, '')
                      setRoute(item.link || HOME, '')
                    }}
                  >
                    <FontAwesomeIcon
                      aria-label={`${item.label}`}
                      icon={item.icon || faIcons}
                      className={item.link === pathName ? 'HeaderMenuGridItemSelect' : ''}
                    />
                    <span
                      className={item.link === pathName ? 'HeaderMenuGridItemSelect' : ''}
                    >
                      &nbsp;{item.label}&nbsp;
                    </span>
                  </Link>
                </React.Fragment>
              )
          }
        })
        }

      </>
    )
  }
  const RenderMenuMobile = () => {
    return (
      <>
        <div className='HeaderMenuIcon'>
          <IconButton
            id='HeaderMenuIcon'
            size="small"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        </div>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none', marginTop: '30px' },
          }}
        >
          <MenuItem
            key={90}
            component={Link}
            to={LOGIN}
            onClick={toggleModalChangePassword}
          >
            <span className="HeaderMenuText">Alterar Senha</span>
          </MenuItem>
          <MenuItem
            key={89}
            component={Link}
            to={LOGIN}
            onClick={toggleLogout}
          >
            <span className="HeaderMenuText">Sair</span>
          </MenuItem>
          <MenuItem
            key={91}
            onClick={handleCloseNavMenu}
          >
            <span className="HeaderVersion">
              Versão: &nbsp;
              {version}
            </span>
          </MenuItem>
        </Menu>
      </>
    )
  }

  return (
    <Box id="Header" className="HeaderRoot" data-testid="HeaderRoot">
      {/* <AppBar position="static"> */}
      {/* <Toolbar disableGutters> */}
      <Grid container>
        <Grid item xs={8} sm={6} md={9} lg={9} xl={9}>
          <Typography
            component="div"
            align="left"
            sx={{
              padding: '0px',
              margin: '0px',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img
              src={`${LogoTop}`}
              srcSet={`${LogoTop}`}
              alt="Logo"
              loading="lazy"
              className='imgLogoUser'
            />
          </Typography>
        </Grid>
        <Grid item xs={4} sm={6} md={3} lg={3} xl={3}>
          <Typography
            component="div"
            align="right"
            sx={{
              display: { xs: 'none', md: 'flex' },
              alignItems: 'flex-end',
              justifyItems: 'flex-end',
              alignContent: 'flex-end',
              padding: '0px',
              margin: '0px',
            }}
          >
            <ProfilePage {...handlerProfile} />
          </Typography>

          <Typography
            id="HeaderMenuMobileGrid"
            component="div"
            align="right"
            sx={{
              display: { xs: 'flex', md: 'none' },
              alignContent: "flex-end",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              margin: "3px 3px 0px 0px",
              flexGrow: 1,
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            {RenderMenuMobile()}
          </Typography>
        </Grid>
        <Typography
          id="HeaderMenuGrid"
          component="div"
          align="center"
          className='HeaderMenuGrid'
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignContent: "flex-end",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            margin: "0px 0px 0px 0px",
            flexGrow: 1,
            color: 'inherit',
            textDecoration: 'none'
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {RenderMenu()}
          </Grid>
        </Typography>
      </Grid>
      {/* </Toolbar> */}
      {/* </AppBar> */}
    </Box>
  );
}

export default HeaderPage;
