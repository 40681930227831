import { IAssociatesResponse } from 'Module/Associates/Interface';
import { IHomeTargetsSupplier } from 'Module/HomeUser/Interface/HomeTargetsSupplier';
import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';

export const setAssociateTargetService = async (fields: IHomeTargetsSupplier, id?: string) => {
  return new Promise<IAssociatesResponse>(async (resolve, reject) => {
    let regReturn: IAssociatesResponse = {
      status: '200',
      message: '',
    };

    try {
      const _url = id ? `/meta/${id}` : '/meta';

      await Api.post(_url,
        {
          "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT),
          "fields": fields
        })
        .then(resp => {
          console.log('SetAssociateMeta.Service.SetID.resp');
          console.log(resp)
          resolve(regReturn);
        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = JSON.stringify(msgerror);
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('Associates.Service.SetID.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}