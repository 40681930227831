import { IFieldStructure } from "Shared/Interfaces/ObjectSalesforce";

const List: IFieldStructure[] = [
  { nameField: 'guidcontrol', label: 'Id', group: 'Informações do Usuário', viewFormMain: true, orderFormMain: 0, viewFormDetail: true, orderFormDetail: 5, edit: false, type: 'string', options: [], hiddenFormMain: true },
  { nameField: 'rcacode', label: 'Código', group: 'Informações do Usuário', viewFormMain: true, orderFormMain: 1, viewFormDetail: true, orderFormDetail: 1, edit: true, type: 'string', options: [], hiddenFormMain: false, width: "45px" },
  { nameField: 'name', label: 'Nome', group: 'Informações do Usuário', viewFormMain: true, orderFormMain: 2, viewFormDetail: true, orderFormDetail: 2, edit: true, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'documentnumber', label: 'CPF/CNPJ', group: 'Informações do Usuário', viewFormMain: true, orderFormMain: 3, viewFormDetail: true, orderFormDetail: 3, edit: true, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'department', label: 'Departamento', group: 'Informações do Usuário', viewFormMain: true, orderFormMain: 4, viewFormDetail: true, orderFormDetail: 4, edit: true, type: 'checkbox', options: [{ value: 'Administradores', label: 'Administradores' }, { value: 'Associados', label: 'Associados' }, { value: 'Fornecedores', label: 'Fornecedores' }, { value: 'Consultar', label: 'Consultar' }], hiddenFormMain: false, width: "100px" },

  { nameField: 'active', label: 'Ativo', group: 'Informações do Usuário', viewFormMain: true, orderFormMain: 5, viewFormDetail: true, orderFormDetail: 1, edit: true, type: 'checkbox', options: [{ value: true, label: 'Sim' }, { value: false, label: 'Não' }], hiddenFormMain: false },
];

export default List;