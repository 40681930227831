import * as React from 'react';
import { Form } from '@unform/web';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Box, Grid, Typography } from '@mui/material';
import { IModalQuestionYesNoPage } from './Interface';
import TypeAnswerConstant from 'Shared/Constant/TypeAnswer/index';

function PaperComponent(props: PaperProps) {
  const nodeRef = React.useRef(null);
  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

export default function ModalQuestionYesNoPage(props: IModalQuestionYesNoPage) {
  const [open] = React.useState<boolean>(true);
  const formDefault = React.useRef<any>(null);

  const handleAnswer = (key: string) => {
    if (props.toggleAnswer) {
      props.toggleAnswer(key);
    }
    if (props.toggleShowModalQuestion) {
      props.toggleShowModalQuestion(false)
    }
  }

  const handleSubmit = (data: any) => {
    handleAnswer(TypeAnswerConstant.YES);
  }

  const handleClose = () => {
    handleAnswer(TypeAnswerConstant.NO);
  };

  return (
    <Box>
      <Dialog
        maxWidth='md'
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className='ModalRoot'
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography component={'div'} align='left'>
                <span className='AssociatesReportModalTitle'>{props.titlePage}</span>
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <Form
              ref={formDefault}
              id="myFormDefault"
              name="myFormDefault"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
                    <span>{props.question}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography component={'div'} align='left'>
                <Button
                  variant="outlined"
                  color="success"
                  className="ButtonOK"
                  type="button"
                  onClick={handleSubmit}
                >
                  <span>Sim</span>
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography component={'div'} align='right'>
                <Button
                  variant="outlined"
                  color="error"
                  className="ButtonDanger"
                  type="button"
                  onClick={handleClose}
                >
                  <span>Não</span>
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
