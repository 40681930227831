import SecureLS from 'secure-ls';

const executeCommand = (key: string) => {
  const ls = new SecureLS({
    encodingType: 'aes',
    isCompression: false,
  });

  try {
    return ls.get(key);
  } catch (err) {
    console.log('_getVariable.err');
    console.log(err);
    return '';
  }
};

export default executeCommand;
