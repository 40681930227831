
import React from 'react';
import sortBy from 'sort-by';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IFieldStructure } from 'Shared/Interfaces/ObjectSalesforce';
import InputCustom from 'Shared/Components/Form/InputReactCustom/index';
import InputCurrencyMask from 'Shared/Components/Form/InputCurrencyMask/index';
import SelectCustom from 'Shared/Components/Form/SelectReactCustom/index';

interface IField {
  title: string;
  fields: any;
  isTypeGroupEdit: boolean;
  structure: IFieldStructure[];
  dateUpdateInfo: string;
  expanded: boolean;
  handleChangeCurrency?(event: any, value: any, maskedValue: any): unknown;
  handleChange?(event: any, value: any): unknown;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


function FieldsByGroupPage(props: IField) {
  const [expanded, setExpanded] = React.useState(props.expanded);
  const toggleExpanded = (value: boolean) => {
    setExpanded(value);
  };

  const handleChangeCurrency = (event: any, value: any, maskedValue: any) => {
    if (props.handleChangeCurrency) {
      props.handleChangeCurrency(event, value, maskedValue);
    }
  };

  const handleChange = (event: any, field?: string) => {
    if (props.handleChange) {
      props.handleChange(event, field)
    }
  }

  return (
    <Box id={`FieldsByGroupPage-${props.title}`} className='FieldsByGroupBox' data-update={props.dateUpdateInfo} >
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Accordion
            expanded={expanded}
            onChange={() => { toggleExpanded(!expanded); }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component="div" align="left">
                <span className="FormTitle">{props.title}</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                {props.structure.length > 0 && props.structure.filter(elStruct => elStruct.group === props.title && elStruct.viewFormDetail).sort(sortBy('orderFormDetail')).map((item, index) => {
                  const _value = item.nameField ? props.fields[item.nameField] : '';
                  return (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={4} xl={4}>
                      <Typography component="div" align="left">
                        {item.type === 'string' &&
                          <InputCustom
                            autoComplete="off"
                            handleChangeCustom={(e: any) => {
                              handleChange(e, item.nameField)
                            }}
                            disabled={!item.edit || !props.isTypeGroupEdit ? true : false}
                            id={item.nameField}
                            name={item.nameField}
                            label={item.label}
                            type='text'
                            defaultValue={_value}
                            // autoComplete={item.nameField}
                            classCustom={(item.edit && props.isTypeGroupEdit) ? "inputCustomDefault" : "inputCustomDefaultDisabled"}
                          />
                        }
                        {item.type === 'currency' &&
                          <InputCurrencyMask
                            autoComplete="off"
                            disabled={!item.edit || !props.isTypeGroupEdit ? true : false}
                            id={item.nameField}
                            name={item.nameField}
                            label={item.label}
                            // autoComplete={item.nameField}
                            type="currency"
                            defaultValue={_value}
                            handleChangeCustom={handleChangeCurrency}
                            classCustom={(item.edit && props.isTypeGroupEdit) ? "inputCustomDefault" : "inputCustomDefaultDisabled"}
                          />
                        }
                        {(item.type === 'checkbox' || item.type === 'picklist') &&
                          <SelectCustom
                            isDisabled={!item.edit || !props.isTypeGroupEdit ? true : false}
                            id={item.nameField}
                            name={item.nameField}
                            label={item.label}
                            options={item.options}
                            defaultValue={_value ? item.options?.find((option: any) => option.value === _value) : item.options ? item.options[0] : {}}
                            onChange={
                              (e: any) => {
                                handleChange(e, item.nameField)
                              }
                            }
                            classCustom={(item.edit && props.isTypeGroupEdit) ? "inputCustomDefault" : "inputCustomDefaultDisabled"}
                          />
                        }
                      </Typography>
                    </Grid>)
                })
                }
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(FieldsByGroupPage);
