import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import { IAssociatesReport, IAssociatesReportContext, IAssociatesReportFilters, IAssociatesReportPage, IAssociatesReportResponse } from '../Interface';
import AssociatesReportPage from '../Page/index';
import { getAssociatesReportService } from '../Service/Get/index';
import { KEY_ASSOCIATESREPORT_SELECTED, KEY_TARGETPRODUCT_GOAL_ASSOCIATES } from 'Shared/Constant/Cache';
import { IHomeTargetsSupplier, IHomeTargetsSupplierFilters, IHomeTargetsSupplierResponse } from 'Module/HomeUser/Interface/HomeTargetsSupplier';
import { getTargetSupplierService } from 'Module/HomeUser/Services/GetTargetSupplier';
import { getTargetsProductAssociadoService } from 'Module/TargetsProduct/Service/GetByAssociado/index';

import { ITargetsProduct, ITargetsProductFilters, ITargetsProductResponse } from 'Module/TargetsProduct/Interface';

function AssociatesReportController(props: ILayoutPage) {
  const [contextAssociatesReport] = React.useState<IAssociatesReportContext>({ result: [] });
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [filters, setFilters] = React.useState<IAssociatesReportFilters>({ codigo: '', nome: '', documento: '', active: 'Todos' });

  const [showModalAssociateDetail, setShowModalAssociateDetail] = React.useState<boolean>(false);
  const toggleShowModalAssociateDetail = (value: boolean) => {
    setShowModalAssociateDetail(value);
  }

  const [titleModalTargetsSupplier, setTitleModalTargetsSupplier] = React.useState<string>('Meta');
  const toggleTitleModalTargetsSupplier = (value: string) => {
    setTitleModalTargetsSupplier(value);
  }

  const [listModalTargetsSupplier, setListModalTargetsSupplier] = React.useState<[IHomeTargetsSupplier?]>([])
  const toggleGetHomeTargetsSupplier = React.useCallback(async (filters: IHomeTargetsSupplierFilters) => {
    await getTargetSupplierService(filters)
      .then((response: IHomeTargetsSupplierResponse) => {
        setListModalTargetsSupplier(response.list || []);
      })
      .catch((error: IHomeTargetsSupplierResponse) => {
        console.log(error.message);
      })
  }, [])

  const [showModalAssociateProductDetail, setShowModalAssociateProductDetail] = React.useState<boolean>(false);
  const toggleShowModalAssociateProductDetail = (value: boolean) => {
    setShowModalAssociateProductDetail(value);
  }

  const [titleModalTargetProduct, setTitleModalTargetProduct] = React.useState<string>('Meta');
  const toggleTitleModalTargetProduct = (value: string) => {
    setTitleModalTargetProduct(value);
  }

  const [listModalTargetsProduct, setListModalTargetsProduct] = React.useState<[ITargetsProduct?]>([])
  const toggleGetTargetsProduct = React.useCallback(async (filters: ITargetsProductFilters) => {
    await getTargetsProductAssociadoService(filters)
      .then((response: ITargetsProductResponse) => {
        setListModalTargetsProduct(response.list || []);
      }).catch((error: ITargetsProductResponse) => {
        console.log(error.message);
      })
  }, [])


  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'filters':
        setFilters(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  const [listAssociatesReport, setListAssociatesReport] = React.useState<[IAssociatesReport?]>([]);
  const toggleGetAssociatesReport = React.useCallback(async (filters: IAssociatesReportFilters) => {
    toggleChangeState('showLoadingThisPage', true);
    toggleChangeState('filters', filters)

    if (props.toggleGetTargetProductGoalAssociate) {
      props.toggleGetTargetProductGoalAssociate(filters);
    }

    await getAssociatesReportService(filters)
      .then((response: IAssociatesReportResponse) => {
        setListAssociatesReport(response.list || []);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');

        const _array: [IAssociatesReport?] = response.list || [];
        props.toggleSetAssociatesReportList(_array)
      })
      .catch((error: IAssociatesReportResponse) => {
        let layoutAlert: ILayout = {
          context: props.layout.context,
          titleMessage: 'Pesquisar registros',
          typeMessage: 'error',
          message: error.message,
          loading: false,
        };

        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSetAssociatesReport = (associatesReport: IAssociatesReport, action: string) => {

  };


  React.useEffect(() => {
    toggleGetAssociatesReport(filters);
  }, [filters, toggleGetAssociatesReport]);

  React.useEffect(() => {
    let _showModal = window.location.hash.includes('#Create') ? true : false;
    toggleChangeState('showNewLeadModal', _showModal);
    if (props.toggleDelCache) {
      props.toggleDelCache(KEY_ASSOCIATESREPORT_SELECTED)
    }
  }, [props])

  React.useEffect(() => {
    let _array: [IHomeTargetsSupplier?] = [];
    if (props.associatesReportSelected && props.associatesReportSelected.id) {
      toggleTitleModalTargetsSupplier(`${props.associatesReportSelected.codigo} - ${props.associatesReportSelected.nome} `);

      const filters: IHomeTargetsSupplierFilters = {
        codigo: props.associatesReportSelected.codigo
      }
      toggleGetHomeTargetsSupplier(filters)
    }
    setListModalTargetsSupplier(_array)
  }, [props.associatesReportSelected, props.dateUpdateInfo, toggleGetHomeTargetsSupplier])

  React.useEffect(() => {
    if (props.targetSupplierSelected && props.targetSupplierSelected.id) {
      toggleTitleModalTargetProduct(`${props.targetSupplierSelected.codigo}: ${props.targetSupplierSelected.nome} -  ${props.targetSupplierSelected.codigoFornec}: ${props.targetSupplierSelected.nomeFornec} `);

      const filters: ITargetsProductFilters = {
        codigoFornec: props.targetSupplierSelected.codigoFornec,
        codigo: props.targetSupplierSelected.codigo,
      }
      toggleGetTargetsProduct(filters)
    }

    // let _array: [IHomeTargetsSupplier?] = [];
    // setListModalTargetsSupplier(_array)
  }, [props.targetSupplierSelected, props.dateUpdateInfo, toggleGetTargetsProduct])


  const handlersPage: IAssociatesReportPage = {
    ...props,
    context: contextAssociatesReport,
    listAssociatesReport,
    showLoadingThisPage,
    dateUpdateInfo,
    filters,
    itemSelected: {},
    showModalAssociateDetail,
    listModalTargetsSupplier,
    titleModalTargetsSupplier,
    showModalAssociateProductDetail,
    listModalTargetsProduct,
    titleModalTargetProduct,
    toggleShowModalAssociateDetail,
    toggleShowModalAssociateProductDetail,
    toggleChangeState,
    toggleGetAssociatesReport,
    toggleSetAssociatesReport,
  };

  return (
    <AssociatesReportPage {...handlersPage} />
  );
}

export default AssociatesReportController;
