import { ILogin, ILoginResponse, ILoginUser } from 'Module/Login/Interface';
import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';
import SetTokenAPIUtil from 'Shared/Utils/ConfigEnvironment/SetTokenAPI';

export const auth = async (params: ILogin) => {
  return new Promise<ILoginResponse>(async (resolve, reject) => {
    let regReturn: ILoginResponse = {
      status: '200',
      message: '',
    };

    try {
      await Api.post('/signin_token',
        {
          "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT),
          "email": params.login,
          "password": params.password
        })
        .then(resp => {
          let _regUser: ILoginUser = resp.data.data ? resp.data.data : resp.data.result.data;
          regReturn.user = {
            name: _regUser.name || '',
            email: _regUser.email || '',
            documentnumber: _regUser.documentnumber || '',
            userid: _regUser.userid || '',
            companyid: _regUser.companyid || '',
            companyname: _regUser.companyname || '',
            department: _regUser.department || '',
            houseparticipates: _regUser.houseparticipates || '',
            photo: _regUser.photo || '',
            rcacode: _regUser.rcacode || '',
            ranking: _regUser.ranking || '',
            guidcontrol: _regUser.guidcontrol || '',
            managerlistcodes: _regUser.managerlistcodes || '',
            listRCAManager: _regUser.listRCAManager || [],
          }

          regReturn.token = resp.data.token ? resp.data.token : resp.data.result.token;

          SetTokenAPIUtil(resp.data.token);
          resolve(regReturn);

        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Email/Senha inválidos';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = msgerror;
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('Reports.Service.getAll.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}
