import * as React from 'react';
import sortBy from 'sort-by';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Column } from '@material-table/core';
import { Table } from 'Shared/Components/Mui/Table/index';
import RegisterStructure from 'Shared/Constant/TargetsProduct/RecordStructure';
import { ITargetsProduct } from 'Module/TargetsProduct/Interface/index';
import { IFieldStructure } from 'Shared/Interfaces/ObjectSalesforce';
import { Grid, Typography } from '@mui/material';
import { ISuppliersReportPage } from 'Module/SuppliersReport/Interface';

function PaperComponent(props: PaperProps) {
  const nodeRef = React.useRef(null);
  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

export default function SuppliersReportProductModal(props: ISuppliersReportPage) {
  const [open] = React.useState(true);

  const [columns, setColumns] = React.useState<Column<object>[]>([]);
  const toggleSetColumns = (values: Column<object>[]) => {
    setColumns(values);
  }

  const [data, setData] = React.useState<object[]>([]);
  const toggleSetData = (values: [ITargetsProduct?]) => {
    const _array: object[] = values.map(reg => reg || {});
    setData(_array);
  }

  const toggleSetItemSelected = (item: any) => {

  }

  const getFieldsStructure = React.useCallback((list: IFieldStructure[]) => {
    let _array: Column<object>[] = [];
    if (list && list.length > 0) {
      for (let regField of list.sort(sortBy('orderFormMain'))) {
        let _column: Column<object> = {}
        if (regField.viewFormMain) {
          switch (regField.type) {
            case 'checkbox':
              _column = {
                title: regField.label,
                field: regField.nameField,
                editable: regField.edit ? 'always' : 'never',
                hidden: regField.hiddenFormMain,
                editComponent: (props) => {
                  console.log(props);
                  return (
                    <input
                      type="checkbox"
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  );
                },
                render: (rowdata: any) => {
                  if (regField.nameField === 'active') {
                    return (<input type="checkbox" checked={rowdata.active} readOnly />)
                  }
                  else if (regField.nameField === 'isMeta') {
                    return (<input type="checkbox" checked={rowdata.isMeta} readOnly />)
                  }
                }
              }
              break;
            case 'currency':
              _column = {
                title: regField.label,
                field: regField.nameField,
                type: 'string',
                align: 'right',
                editable: regField.edit ? 'always' : 'never',
                hidden: regField.hiddenFormMain
              }
              break;
            default:
              _column = {
                title: regField.label,
                field: regField.nameField,
                type: 'string',
                editable: regField.edit ? 'always' : 'never',
                hidden: regField.hiddenFormMain
              }
              break;
          }
          if (regField.defaultSort) {
            _column.defaultSort = regField.defaultSort === 'asc' ? 'asc' : 'desc';
          }
          if (regField.width) {
            _column.width = regField.width;
          }
          if (regField.cellStyle) {
            _column.cellStyle = regField.cellStyle;
          }
          _array.push(_column)
        }
      }
    }
    toggleSetColumns(_array);

  }, [])

  const handleClose = () => {
    if (props.toggleShowModalAssociateProductDetail) {
      props.toggleShowModalAssociateProductDetail(false);
    }
  };

  React.useEffect(() => {
    getFieldsStructure(RegisterStructure);
    toggleSetData(props?.listModalTargetsProduct || []);
  }, [props.listModalTargetsProduct, props.dateUpdateInfo, getFieldsStructure])

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={8} md={9} lg={10} xl={10}>
              <Typography component={'div'} align='left'>
                <span className='AssociatesReportModalTitle'>{props?.titleModalTargetProduct || 'Metas'}</span>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
              <Typography component={'div'} align='right'>
                <Button
                  variant="contained"
                  color="success"
                  className="ButtonDanger"
                  type="button"
                  autoFocus
                  onClick={handleClose}
                >
                  <span>Fechar</span>
                </Button>
              </Typography>
            </Grid>
          </Grid>

        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <Table data={data} columns={columns} title='Produtos' href={''} toggleSelectItem={toggleSetItemSelected} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
}
