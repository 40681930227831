import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import React from 'react';
import momenttz from 'moment-timezone';

import HomeUserSupplierPage from '../Page/index';
import { IPanelCustom } from 'Shared/Components/PanelCustom/Interface';
import PanelCustomConstant from 'Shared/Constant/PanelCustom/index';
import convertToNumber from 'Shared/Utils/ConvertToNumber';
import { IHomeTargetSupplierAssociate, IHomeTargetSupplierAssociateFilters, IHomeTargetsOptionList, IHomeTargetSupplierAssociatePage, IHomeTargetSupplierAssociateResponse } from '../Interface/HomeTargetSupplierAssociate';
import { IHomeResultPanel } from 'Module/Home/Interface';
import { ITargetsProduct, ITargetsProductFilters, ITargetsProductGoals, ITargetsProductResponse } from 'Module/TargetsProduct/Interface';
import { getTargetsProductAssociadoService } from 'Module/TargetsProduct/Service/GetByAssociado/index';
import { getTargetsProductSupplierService } from 'Module/TargetsProduct/Service/GetBySupplier/index';
import { getSuppliersReportService } from 'Module/SuppliersReport/Service/Get/index';
import { getTargetSupplierAssociateService } from '../Services/GetTargetSupplierAssociateService/index';
import { getSupplierByCodPrincipal } from '../Services/GetSupplierByCodPrincipal/index';
import { getSupplierByDocument } from '../Services/GetSupplierByDocument/index';
import { ISuppliers, ISuppliersFilters, ISuppliersResponse } from 'Module/Suppliers/Interface';
import { ISuppliersReport, ISuppliersReportFilters, ISuppliersReportResponse } from 'Module/SuppliersReport/Interface';
import { KEY_TARGETPRODUCT_GOAL_SUPPLIERS } from 'Shared/Constant/Cache';

function HomeUserSupplierController(props: ILayoutPage) {

  const [filters, setFilters] = React.useState<IHomeTargetSupplierAssociateFilters>({ codigo: '', nome: '', documento: '', nomeFornec: '' });
  const [showButtonResume, setShowButtonResume] = React.useState<boolean>(false);
  const [showResume, setShowResume] = React.useState<boolean>(false);
  const toggleShowResume = React.useCallback(async (value: boolean) => {
    setShowResume(value);
  }, [])

  const [fornecedorLogado, setFornecedorLogado] = React.useState<ISuppliers>({})
  const toggleFornecedorLogado = React.useCallback(async (filters: ISuppliersFilters) => {
    await getSupplierByDocument(filters)
      .then((response: ISuppliersResponse) => {
        let _item: ISuppliers = {};
        if (response.list && response.list.length > 0) {
          for (let reg of response.list) {
            _item = {
              id: reg?.id,
              codigo: reg?.codigo,
              nome: reg?.nome,
              documento: reg?.documento,
              codigoPrincipal: reg?.codigoPrincipal,
              email: reg?.email,
            }
          }
        }
        setFornecedorLogado(_item)
      }).catch((error: ISuppliersResponse) => {
        console.log(error.message);
      })

  }, [])

  const [OptSelectListConst, setOptSelectListConst] = React.useState<[IHomeTargetsOptionList]>([
    { value: '', label: 'Todas' }
  ]);

  const toggleSelectListConst = React.useCallback(async (filters: ISuppliersFilters) => {
    await getSupplierByCodPrincipal(filters)
      .then((response: ISuppliersResponse) => {
        let _array: [IHomeTargetsOptionList] = [{ value: '', label: 'Todas' }];
        let _isShowButtonResume = false;
        if (response.list && response.list.length > 0) {
          for (let reg of response.list) {
            if (reg?.codigo && reg?.nome) {
              _array.push({
                value: reg.codigo, label: `${reg.codigo} - ${reg.nome}`
              })

              if (reg?.codigo === reg?.codigoPrincipal) {
                if (props.profile.documento === reg?.documento) {
                  _isShowButtonResume = true;
                }
              }
            }
          }

          setShowButtonResume(_isShowButtonResume);
          toggleShowResume(_isShowButtonResume);
          setOptSelectListConst(_array)
        }
        else {
          setOptSelectListConst(_array)
        }

      }).catch((error: ISuppliersResponse) => {
        console.log(error.message);
      })

  }, [props.profile.documento, toggleShowResume])

  const [itemSelectList, setItemSelectList] = React.useState<string>(OptSelectListConst[0].value);

  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [showLoadingMixThisPage, setShowLoadingMixThisPage] = React.useState<boolean>(true);
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());

  const [resultPanelSuppliers, setResultPanelSuppliers] = React.useState<IHomeResultPanel>({ result: [] })
  const toggleResultPanelSuppliers = (values: IHomeResultPanel) => {
    setResultPanelSuppliers(values)
  }

  const [showModalAssociateProductDetail, setShowModalAssociateProductDetail] = React.useState<boolean>(false);
  const toggleShowModalAssociateProductDetail = (value: boolean) => {
    setShowModalAssociateProductDetail(value);
  }

  const [titleModalTargetProduct, setTitleModalTargetProduct] = React.useState<string>('Meta');
  const toggleTitleModalTargetProduct = (value: string) => {
    setTitleModalTargetProduct(value);
  }

  const [listSuppliersReport, setListSuppliersReport] = React.useState<[ISuppliersReport?]>([])
  const toggleGetSuppliersReport = React.useCallback(async (filters: ISuppliersReportFilters) => {
    await getSuppliersReportService(filters)
      .then((response: ISuppliersReportResponse) => {
        setListSuppliersReport(response.list || []);
      }).catch((error: ISuppliersReportResponse) => {
        console.log(error.message);
      })
  }, [])

  const [listModalTargetsProduct, setListModalTargetsProduct] = React.useState<[ITargetsProduct?]>([])
  const toggleGetTargetsProduct = React.useCallback(async (filters: ITargetsProductFilters) => {
    toggleChangeState('showLoadingMixThisPage', true);
    await getTargetsProductAssociadoService(filters)
      .then((response: ITargetsProductResponse) => {
        setListModalTargetsProduct(response.list || []);
        toggleChangeState('showLoadingMixThisPage', false);
      }).catch((error: ITargetsProductResponse) => {
        console.log(error.message);
        toggleChangeState('showLoadingMixThisPage', false);
      })
  }, [])//eslint-disable-line

  const [listModalTargetsProductSupplier, setListModalTargetsProductSupplier] = React.useState<[ITargetsProduct?]>([])
  const toggleGetTargetsProductSupplier = React.useCallback(async (filters: ITargetsProductFilters) => {
    toggleChangeState('showLoadingMixThisPage', true);
    await getTargetsProductSupplierService(filters)
      .then((response: ITargetsProductResponse) => {
        setListModalTargetsProductSupplier(response.list || []);
        toggleChangeState('showLoadingMixThisPage', false);
      }).catch((error: ITargetsProductResponse) => {
        console.log(error.message);
        toggleChangeState('showLoadingMixThisPage', false);
      })
  }, [])//eslint-disable-line

  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'itemSelectList':
        const _filters: IHomeTargetSupplierAssociateFilters = {
          codigo: '',
          nome: '',
          documento: '',
          codClientePrinc: '',
          codigoFornec: value ? value : '',
          nomeFornec: filters.nomeFornec,
          documentFornec: filters.documentFornec,
          codFornecPrinc: value ? '' : filters.codFornecPrinc,
        }
        setFilters(_filters);

        setItemSelectList(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'filters':
        setFilters(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingMixThisPage':
        setShowLoadingMixThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  const [listHomeTargetSupplierAssociate, setListHomeTargetSupplierAssociate] = React.useState<[IHomeTargetSupplierAssociate?]>([]);
  const [listHomeTargetSupplierAssociateResume, setListHomeTargetSupplierAssociateResume] = React.useState<[IHomeTargetSupplierAssociate?]>([]);

  const toggleGetHomeTargetSupplierAssociate = React.useCallback(async (filters: IHomeTargetSupplierAssociateFilters) => {
    toggleChangeState('showLoadingThisPage', true);
    toggleChangeState('filters', filters)

    if (props.toggleGetTargetProductGoalSupplier) {
      const _filter: ITargetsProductFilters = {
        codProduto: '',
        nomeProduto: '',
        ean: '',
        codProdutoPrinc: '',

        codigo: filters.codigo,
        nome: filters.nome,
        documento: filters.documento,
        codClientePrinc: filters.codClientePrinc,

        codigoFornec: filters.codigoFornec,
        nomeFornec: filters.nomeFornec,
        documentFornec: filters.documentFornec,
        codFornecPrinc: filters.codFornecPrinc,

        agrupar: 'true',
      }
      props.toggleGetTargetProductGoalSupplier(_filter);
    }

    await getTargetSupplierAssociateService(filters)
      .then((response: IHomeTargetSupplierAssociateResponse) => {
        toggleChangeState('showLoadingThisPage', false);
        setListHomeTargetSupplierAssociate(response.list || []);
        setListHomeTargetSupplierAssociateResume(response.listResume || []);
      })
      .catch((error: IHomeTargetSupplierAssociateResponse) => {
        let layoutAlert: ILayout = {
          context: props.layout.context,
          titleMessage: 'Pesquisar registros',
          typeMessage: 'error',
          message: error.message,
          loading: false,
        };

        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const _arrayPanel: [IPanelCustom?] = [];
    let _totFaturamento: number = 0;
    let _totMetaFaturamento: number = 0;
    let _percentFaturamento: number = 0;
    let _totMix: number = 0;
    let _totMetaMix: number = 0;
    let _percentMix: number = 0;

    if (filters && (filters.codigo || filters.nome)) {
      if (listHomeTargetSupplierAssociate && listHomeTargetSupplierAssociate.length > 0) {
        for (let reg of listHomeTargetSupplierAssociate) {
          _totFaturamento += convertToNumber(reg?.faturamento);
          _totMetaFaturamento += convertToNumber(reg?.metaFaturamento);
          // _totMix += convertToNumber(reg?.mix);
          // _totMetaMix += convertToNumber(reg?.metaMix);
        }
      }
    }
    else {
      if (listSuppliersReport && listSuppliersReport.length > 0) {
        for (let reg of listSuppliersReport) {
          _totFaturamento += convertToNumber(reg?.faturamento);
          _totMetaFaturamento += convertToNumber(reg?.metaFaturamento);
          // _totMix += convertToNumber(reg?.mix);
          // _totMetaMix += convertToNumber(reg?.metaMix);
        }
      }
    }

    let _infoMix: ITargetsProductGoals = {
      list: [],
      totRegisters: 0,
      qtdeCxFat: 0,
    };
    if (props.targetsProductGoalSuppliers) {
      _infoMix.totRegisters = props.targetsProductGoalSuppliers.totRegisters ? props.targetsProductGoalSuppliers.totRegisters : 0;
      _infoMix.qtdeCxFat = props.targetsProductGoalSuppliers.qtdeCxFat ? props.targetsProductGoalSuppliers.qtdeCxFat : 0;

    }
    else {
      if (props.toggleGetCache) {
        const _regCache: any = props.toggleGetCache(KEY_TARGETPRODUCT_GOAL_SUPPLIERS);
        if (_regCache) {
          _infoMix.totRegisters = _regCache.totRegisters ? _regCache.totRegisters : 0;
          _infoMix.qtdeCxFat = _regCache.qtdeCxFat ? _regCache.qtdeCxFat : 0;
        }
      }
    }

    _totMetaMix = _infoMix.totRegisters || 0;
    _totMix = _infoMix.qtdeCxFat || 0;

    // Trata valores de Faturamento
    if (_totMetaFaturamento > 0) {
      _percentFaturamento = (_totFaturamento / _totMetaFaturamento) * 100;
    }

    _arrayPanel.push({
      title: 'Faturamento',
      value: isNaN(_totFaturamento) ? 'R$ 0,00' : `R$ ${_totFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `,
      type: PanelCustomConstant.TYPE_FAT,
      anotation: `Meta: R$ ${_totMetaFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br/> ( ${_percentFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}% )`,
      alert: _percentFaturamento >= 100 ? PanelCustomConstant.SUCCESS :
        _percentFaturamento >= 80 ? PanelCustomConstant.SUCCESS_LIGHT :
          _percentFaturamento >= 50 ? PanelCustomConstant.WARNING :
            _percentFaturamento >= 40 ? PanelCustomConstant.WARNING_LIGHT :
              _percentFaturamento >= 30 ? PanelCustomConstant.DANGER_LIGHT :
                PanelCustomConstant.DANGER,
    })

    // Trata valores de Mix
    if (_totMetaMix > 0) {
      _percentMix = (_totMix / _totMetaMix) * 100;
    }

    _arrayPanel.push({
      title: 'Mix',
      value: isNaN(_totMix) ? '0' : _totMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
      type: PanelCustomConstant.TYPE_MIX,
      anotation: `Meta: ${_totMetaMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 })} <br/> ( ${_percentMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 })}% )`,
      alert: _percentMix >= 100 ? PanelCustomConstant.SUCCESS :
        _percentMix >= 80 ? PanelCustomConstant.SUCCESS_LIGHT :
          _percentMix >= 50 ? PanelCustomConstant.WARNING :
            _percentMix >= 40 ? PanelCustomConstant.WARNING_LIGHT :
              _percentMix >= 30 ? PanelCustomConstant.DANGER_LIGHT :
                PanelCustomConstant.DANGER,
    })

    toggleResultPanelSuppliers({ result: _arrayPanel });
  }, [listHomeTargetSupplierAssociate, listSuppliersReport, props.dateUpdateInfoMixSupplier, props.targetsProductGoalSuppliers])//eslint-disable-line


  React.useEffect(() => {
    if (props.targetSupplierSelected && props.targetSupplierSelected.id) {
      toggleTitleModalTargetProduct(`${props.targetSupplierSelected.codigo}: ${props.targetSupplierSelected.nome} - ${props.targetSupplierSelected.codigoFornec}: ${props.targetSupplierSelected.nomeFornec} `);

      let _filters: ITargetsProductFilters = {
        codigo: props.targetSupplierSelected.codigo,
      }
      if (showResume) {
        _filters.codFornecPrinc = props.targetSupplierSelected.codFornecPrinc
      }
      else {
        _filters.codigoFornec = props.targetSupplierSelected.codigoFornec
      }
      toggleGetTargetsProduct(_filters)
    }
    else {
      if (filters && (filters.codigo || filters.nome)) {
        const _filters: ITargetsProductFilters = {
          codigoFornec: itemSelectList ? itemSelectList : fornecedorLogado.codigo,
          codigo: filters.codigo ? filters.codigo : '',
          nome: filters.nome ? filters.nome : '',
        }
        toggleGetTargetsProduct(_filters)
      }
    }
  }, [showResume, props.targetSupplierSelected, props.dateUpdateInfo, toggleGetTargetsProduct, filters, itemSelectList, fornecedorLogado.codigo])


  React.useEffect(() => {
    let _filters: IHomeTargetSupplierAssociateFilters = {
      nomeFornec: filters.nomeFornec,
      codigo: '',
      codClientePrinc: '',
      documento: '',
      codFornecPrinc: '',
      codigoFornec: '',
    };

    if (itemSelectList) {
      _filters.codigoFornec = itemSelectList
    }
    else {
      if (fornecedorLogado.codigo) {
        if (fornecedorLogado.codigo === fornecedorLogado.codigoPrincipal) {
          _filters.codFornecPrinc = fornecedorLogado.codigo
        }
        else {
          _filters.codigoFornec = fornecedorLogado.codigo
        }

      }
      else {
        toggleChangeState('showLoadingThisPage', false);
      }
    }

    toggleChangeState('filters', _filters)

  }, [fornecedorLogado.codigo, itemSelectList])//eslint-disable-line

  React.useEffect(() => {
    if (filters.codigoFornec || filters.codFornecPrinc) {
      toggleGetHomeTargetSupplierAssociate(filters)
    }
  }, [filters, toggleGetHomeTargetSupplierAssociate])

  React.useEffect(() => {
    let _filters: ISuppliersFilters = {
      documento: props.profile.documento
    };
    toggleFornecedorLogado(_filters)
  }, [props.profile.documento, toggleFornecedorLogado])

  React.useEffect(() => {
    if (fornecedorLogado && fornecedorLogado.codigo) {
      const _filter: ISuppliersFilters = {
        codigo: fornecedorLogado.codigo
      }
      toggleSelectListConst(_filter);

      let _filterSupplier: ISuppliersReportFilters = {};
      let _filterTargetProductSupplier: ITargetsProductFilters = {};

      if (itemSelectList) {
        _filterSupplier.codigo = itemSelectList;
        _filterTargetProductSupplier.codigoFornec = itemSelectList;
      }
      else {
        if (fornecedorLogado.codigo === fornecedorLogado.codigoPrincipal) {
          _filterSupplier.codigoPrincipal = fornecedorLogado.codigo;
        }
        else {
          _filterSupplier.codigo = fornecedorLogado.codigo;
        }
        _filterTargetProductSupplier.nomeFornec = fornecedorLogado.nome;
      }
      toggleGetSuppliersReport(_filterSupplier)
      toggleGetTargetsProductSupplier(_filterTargetProductSupplier);
    }
  }, [fornecedorLogado, itemSelectList, toggleGetSuppliersReport, toggleGetTargetsProductSupplier, toggleSelectListConst])

  const handlersPage: IHomeTargetSupplierAssociatePage = {
    ...props,
    dateUpdateInfo,
    showLoadingThisPage,
    showLoadingMixThisPage,
    optionsSelectList: OptSelectListConst,
    itemSelectList,
    listHomeTargetSupplierAssociate,
    listHomeTargetSupplierAssociateResume,
    listModalTargetsProductSupplier,
    showModalAssociateProductDetail,
    listModalTargetsProduct,
    titleModalTargetProduct,
    toggleGetHomeTargetSupplierAssociate,
    toggleChangeState,
    toggleShowModalAssociateProductDetail,
    resultPanelSuppliers,
    filters,
    showButtonResume,
    showResume,
    toggleShowResume
  };

  return (
    <HomeUserSupplierPage {...handlersPage} />
  );
}

export default HomeUserSupplierController;
