const Config = {
  ENVIRONMENT_LOCAL: 'LOCAL',
  ENVIRONMENT_DEV: 'DEVELOP',
  ENVIRONMENT_HML: 'HOMOLOG',
  ENVIRONMENT_PRD: 'PRODUCTION',
  URL_API_LOCAL: 'https://localhost:21018',
  URL_API_DEV: 'https://localhost:21018',
  URL_API_HML: 'https://localhost:21018',
  URL_API_PRD: 'https://yesupcloud.com.br:21018',
  SYSTEMAUTH_LOCAL: 'CampanhaVendas',
  SYSTEMAUTH_DEV: 'CampanhaVendas',
  SYSTEMAUTH_HML: 'CampanhaVendas',
  SYSTEMAUTH_PRD: 'CampanhaVendas',
};

export default Config;
