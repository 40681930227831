import React from 'react';

import { IProfile } from 'Module/Profile/Interface';
import { Link } from 'react-router-dom';
import {
  Grid,
  Box,
  Typography,
  IconButton
}
  from '@mui/material';

import LinkMUI from '@mui/material/Link';

import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { PROFILE, LOGOUT, HOME } from 'Shared/Constant/Routes';
import packageJson from '../../../../../package.json';
import { IHeaderPage } from '../../Interface/index';

function SlideMenuProfilePage(props: IHeaderPage) {
  const [version, setVersion] = React.useState<string>('');
  React.useEffect(() => {
    setVersion(packageJson.version ? packageJson.version : '1.0.0');
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [profile, setProfile] = React.useState<undefined | IProfile>(undefined);
  const toggleProfile = (data: IProfile | undefined) => {
    setProfile(data);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
    // eslint-disable-next-line react/destructuring-assignment
    props.toggleSetHomePath(PROFILE, '');
  };

  const toggleModalResetPasswordUser = () => {
    setAnchorEl(null);
    // eslint-disable-next-line react/destructuring-assignment
    if (props.toggleShowModalResetPasswordUser) {
      props.toggleShowModalResetPasswordUser(true);
    }
  };


  const toggleModalChangePassword = () => {
    setAnchorEl(null);
    // eslint-disable-next-line react/destructuring-assignment
    if (props.toggleShowModalChangePassword) {
      props.toggleShowModalChangePassword(true);
    }
  };

  React.useEffect(() => {
    toggleProfile(props?.profile);
  }, [props]);

  return (
    <Box id="HeaderPerfilBox" data-testid="HeaderProfile">
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="right" id="HeaderPerfil">
            <LinkMUI
              id='HeaderPerfilGrid'
              onClick={handleMenu}
            >
              <IconButton
                id='HeaderPerfilIcon'
                size="large"
                aria-label="perfil do usuário atual"
                aria-controls="HeaderProfileMenu"
                aria-haspopup="true"
                sx={{ width: '2rem', height: '2rem', color: '#ffffff', marginRight: '3px' }}
              >
                <AccountCircle />
              </IconButton>
              <span className="HeaderTextHello">&nbsp;Olá,</span>
              <span className="HeaderTextHello">{profile && profile.primeironome ? profile.primeironome : profile && profile.nome ? profile.nome : ''}</span>
              <span className="HeaderTextHello">!&nbsp;</span>
              <i className="fas fa-sort-down HeaderTextHello">&nbsp;</i>
            </LinkMUI>
          </Typography>
        </Grid>
      </Grid>
      <Menu
        id="HeaderProfileMenu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to={PROFILE}
          onClick={handleCloseProfile}
        >
          <span className="HeaderMenuText">-Perfil</span>
        </MenuItem>
        {props.isTypeGroupEdit &&
          <MenuItem
            key={90}
            component={Link}
            to={HOME}
            onClick={toggleModalResetPasswordUser}
          >
            <span className="HeaderMenuText">-Resetar Senha Usuário</span>
          </MenuItem>
        }
        <MenuItem
          key={95}
          component={Link}
          to={HOME}
          onClick={toggleModalChangePassword}
        >
          <span className="HeaderMenuText">-Alterar Senha</span>
        </MenuItem>
        <MenuItem
          component={Link}
          to={LOGOUT}
          onClick={handleClose}
        >
          <span className="HeaderMenuText">-Sair</span>
        </MenuItem>
        <MenuItem
          onClick={handleClose}
        >
          <span className="HeaderVersion">
            Versão: &nbsp;
            {version}
          </span>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default SlideMenuProfilePage;
