import * as React from 'react';
import { Link } from 'react-router-dom';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ISideMenuPage, IMenuList, IHeaderPage } from '../Interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIcons } from '@fortawesome/free-solid-svg-icons';
import { HOME, USERS } from 'Shared/Constant/Routes';
import LogoTop from '../../../Assets/logotop.png';
import ProfilePage from './ProfileMenu/index';
import { Grid } from '@mui/material';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    background: 'inherit',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function SideMenuPage(props: ISideMenuPage) {
  // const theme = useTheme();

  const [open, setOpen] = React.useState(props.menu.open);
  const [direction, setDirection] = React.useState('right');
  const [list, setList] = React.useState<IMenuList[]>([]);
  const toggleList = (values: IMenuList[]) => {
    setList(values);
  }

  const [pathName, setPathName] = React.useState<string>('/');

  const handleDrawerOpen = () => {
    setOpen(true);
    setDirection('left');
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setDirection('right');
  };

  React.useEffect(() => {
    setPathName(props.menu.pathName);
  }, [props.menu.pathName, props.dataUpdate]);

  React.useEffect(() => {
    toggleList(props.menu.list || []);
  }, [props.menu, props.menu.list])

  const handlerProfile: IHeaderPage = {
    ...props,
    menu: { open: true, pathName: props.pathname || HOME, hash: '' }
  };

  return (
    <Box
      id='SideMenuRoot'
      key='SideMenuRoot'
      sx={{ display: 'flex' }}
      data-field={props.dataUpdate}
      className="SideMenuRoot"
    >
      <AppBar position="fixed" open={open} className='SideMenuDrawer'>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Grid container>
            <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
              <Typography
                component="div"
                align="left"
                sx={{
                  padding: '0px',
                  margin: '0px',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                <img
                  src={`${LogoTop}`}
                  srcSet={`${LogoTop}`}
                  alt="Logo"
                  loading="lazy"
                  className='imgLogo'
                />
              </Typography>
            </Grid>
            <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
              <Typography
                component="div"
                align="right"
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  alignItems: 'flex-end',
                  justifyItems: 'flex-end',
                  alignContent: 'flex-end',
                  padding: '0px',
                  margin: '0px',
                }}
              >
                <ProfilePage {...handlerProfile} />
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <CssBaseline />
      <Drawer variant="permanent" open={open} className='SideMenuDrawer'>
        <DrawerHeader>
          {direction === 'right' ?
            <IconButton onClick={handleDrawerOpen}>
              <ChevronRightIcon />
            </IconButton>
            :
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          }
        </DrawerHeader>
        <Divider />
        <List className='SideMenuDrawer'>
          {list.length > 0 && list.map((item, index) => {
            let isView = true;
            switch (item.link) {
              case USERS:
                if (!props.isTypeGroupEdit) {
                  isView = false;
                }
                break;
              default:
                break;
            }
            if (isView) {
              return (
                <Link
                  className='SideMenuDrawer'
                  key={`${item.link}`}
                  to={item.link || HOME}
                  onClick={() => { props.setRoute(item.link || HOME) }}
                >
                  <ListItem
                    key={`${index}${item.id}`}
                    disablePadding sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      id='SideMenuItem'
                      title={`${item.label}`}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={item.icon || faIcons}
                          className={item.link === pathName ? 'MenuItemSelected' : 'MenuItem'}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.label}
                        sx={{ opacity: open ? 1 : 0 }}
                        className={item.link === pathName ? 'MenuItemSelected' : 'MenuItem'}
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
              )
            }
            else {
              return (
                <React.Fragment>
                  <span></span>
                </React.Fragment>
              )
            }
          }
          )}
        </List>
      </Drawer>
    </Box>
  );
}


export default SideMenuPage;