import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import AssociateDetailPage from '../Page/index';
import { IAssociateDetailPage } from '../Interface';
import { IAssociates, IAssociatesContext, IAssociatesResponse } from 'Module/Associates/Interface';
import { ASSOCIATES } from 'Shared/Constant/Routes';
import RecordStructureConstant from 'Shared/Constant/Associates/RecordStructure';
import { IHomeTargetsSupplier, IHomeTargetsSupplierFilters, IHomeTargetsSupplierResponse } from 'Module/HomeUser/Interface/HomeTargetsSupplier';
import { getTargetSupplierService } from 'Module/HomeUser/Services/GetTargetSupplier';
import { setAssociatesService } from '../Service/SetAssociado/index';
import { setAssociateTargetService } from '../Service/SetMeta/index';

function AssociateDetailController(props: ILayoutPage) {
  const [contextAssociates] = React.useState<IAssociatesContext>({ result: [] });
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [item, setItem] = React.useState<IAssociates>({});
  const toggleSetItem = (itemSelected: IAssociates) => {
    setItem(itemSelected);
  }

  const [showAssociateTargetModal, setShowAssociateTargetModal] = React.useState<boolean>(false);
  const toggleShowAssociateTargetModal = (value: boolean) => {
    setShowAssociateTargetModal(value);
  }

  const [showModalQuestionYesNo, setShowModalQuestionYesNo] = React.useState<boolean>(false);
  const toggleShowModalQuestionYesNo = (value: boolean) => {
    setShowModalQuestionYesNo(value);
  }

  const [showModalQuestionYesNoDetail, setShowModalQuestionYesNoDetail] = React.useState<boolean>(false);
  const toggleShowModalQuestionYesNoDetail = (value: boolean) => {
    setShowModalQuestionYesNoDetail(value);
  }

  const [listModalTargetsSupplier, setListModalTargetsSupplier] = React.useState<[IHomeTargetsSupplier?]>([])
  const toggleGetHomeTargetsSupplier = React.useCallback(async (filters: IHomeTargetsSupplierFilters) => {
    await getTargetSupplierService(filters)
      .then((response: IHomeTargetsSupplierResponse) => {
        setListModalTargetsSupplier(response.list || []);
      })
      .catch((error: IHomeTargetsSupplierResponse) => {
        console.log(error.message);
      })
  }, [])

  const [titleModalTargetsSupplier, setTitleModalTargetsSupplier] = React.useState<string>('Meta');
  const toggleTitleModalTargetsSupplier = (value: string) => {
    setTitleModalTargetsSupplier(value);
  }

  const toggleSetAssociateTarget = React.useCallback(async (associatetarget: IHomeTargetsSupplier, id: string) => {
    toggleChangeState('showLoadingThisPage', true);
    console.log(id)
    console.log(associatetarget)
    await setAssociateTargetService(associatetarget, id)
      .then((response: IAssociatesResponse) => {
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'success',
          titleMessage: 'Salvo',
          message: 'Registro salvo com sucesso',
        }
        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
      })
      .catch((error: IAssociatesResponse) => {
        console.log(error.message);
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'error',
          titleMessage: 'Erro ao salvar',
          message: error.message,
        }
        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
      })
  }, []);//eslint-disable-line

  const toggleSetAssociates = React.useCallback(async (associates: IAssociates, id: string) => {
    toggleChangeState('showLoadingThisPage', true);
    console.log(id)
    console.log(associates)
    await setAssociatesService(associates, id)
      .then((response: IAssociatesResponse) => {
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'success',
          titleMessage: 'Salvo',
          message: 'Registro salvo com sucesso',
        }
        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
      })
      .catch((error: IAssociatesResponse) => {
        console.log(error.message);
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'error',
          titleMessage: 'Erro ao salvar',
          message: error.message,
        }
        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
      })
  }, []);//eslint-disable-line

  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'item':
        toggleSetItem(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  React.useEffect(() => {
    let _item: IAssociates = {};
    if (props.associateSelected) {
      _item = props.associateSelected

      toggleTitleModalTargetsSupplier(`${_item.codigo} - ${_item.nome} `);
      let _filters: IHomeTargetsSupplierFilters = {
        codigo: _item.codigo
      };
      toggleGetHomeTargetsSupplier(_filters);
    }
    toggleChangeState('item', _item);

    if (!_item.nome) {
      let layoutAlert: ILayout = {
        loading: false,
        typeMessage: 'error',
        titleMessage: 'Não encontrado',
        message: 'Erro ao pesquisar os detalhes do associado. Tente pesquisar novamente',
      }
      props.toggleSetLayout(layoutAlert);
      setTimeout(() => {
        window.location.href = `${ASSOCIATES}#search`;
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.associateSelected])

  React.useEffect(() => {
    console.log(props.showModalImportFile);
  }, [props])

  const handlerPage: IAssociateDetailPage = {
    ...props,
    context: contextAssociates,
    showLoadingThisPage,
    dateUpdateInfo,
    item,
    structure: RecordStructureConstant,
    toggleChangeState,
    toggleSetAssociates,
    toggleSetAssociateTarget,
    showAssociateTargetModal,
    toggleShowAssociateTargetModal,
    listModalTargetsSupplier,
    titleModalTargetsSupplier,
    showModalQuestionYesNo,
    toggleShowModalQuestionYesNo,
    showModalQuestionYesNoDetail,
    toggleShowModalQuestionYesNoDetail,
  }
  return (
    <React.Fragment>
      <AssociateDetailPage {...handlerPage} />

    </React.Fragment>
  )
}


export default AssociateDetailController;
