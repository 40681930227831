import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import React from 'react';
import momenttz from 'moment-timezone';

import HomeUserPage from '../Page/index';
import { IPanelCustom } from 'Shared/Components/PanelCustom/Interface';
import PanelCustomConstant from 'Shared/Constant/PanelCustom/index';
import convertToNumber from 'Shared/Utils/ConvertToNumber';
import { IHomeTargetsSupplier, IHomeTargetsSupplierFilters, IHomeTargetsOptionList, IHomeTargetsSupplierPage, IHomeTargetsSupplierResponse } from '../Interface/HomeTargetsSupplier';
import { IHomeResultPanel } from 'Module/Home/Interface';
import { ITargetsProduct, ITargetsProductFilters, ITargetsProductGoals, ITargetsProductResponse } from 'Module/TargetsProduct/Interface';
import { getTargetSupplierService } from '../Services/GetTargetSupplier/index';
import { getTargetsProductAssociadoService } from 'Module/TargetsProduct/Service/GetByAssociado';
import { getAssociatesByCodPrincipal } from '../Services/GetAssociatesByCodPrincipal/index';
import { getAssociatesByDocument } from '../Services/GetAssociadoByDocument/index';
import { IAssociates, IAssociatesFilters, IAssociatesResponse } from 'Module/Associates/Interface';
import { KEY_TARGETPRODUCT_GOAL_ASSOCIATES } from 'Shared/Constant/Cache';

function HomeUserController(props: ILayoutPage) {

  const [filters, setFilters] = React.useState<IHomeTargetsSupplierFilters>({ codigo: '', nome: '', documento: '', nomeFornec: '' });
  const [filtersProduct, setFiltersProduct] = React.useState<ITargetsProductFilters>({ codigo: '', nome: '', documento: '', nomeFornec: '' });
  const [showButtonResume, setShowButtonResume] = React.useState<boolean>(false);
  const [showResume, setShowResume] = React.useState<boolean>(false);
  const toggleShowResume = React.useCallback(async (value: boolean) => {
    setShowResume(value);
  }, [])

  const [associadoLogado, setAssociadoLogado] = React.useState<IAssociates>({})
  const toggleAssociadoLogado = React.useCallback(async (filters: IAssociatesFilters) => {
    await getAssociatesByDocument(filters)
      .then((response: IAssociatesResponse) => {
        let _item: IAssociates = {};
        if (response.list && response.list.length > 0) {
          for (let reg of response.list) {
            _item = {
              id: reg?.id,
              codigo: reg?.codigo,
              nome: reg?.nome,
              documento: reg?.documento,
              codigoPrincipal: reg?.codigoPrincipal,
              email: reg?.email,
            }
          }
        }
        setAssociadoLogado(_item)
      }).catch((error: IAssociatesResponse) => {
        console.log(error.message);
      })

  }, [])

  const [OptSelectListConst, setOptSelectListConst] = React.useState<[IHomeTargetsOptionList]>([
    { value: '', label: 'Todas' }
  ]);

  const [listAssociates, setListAssociates] = React.useState<[IAssociates?]>([]);
  const toggleSelectListConst = React.useCallback(async (filters: IAssociatesFilters) => {
    await getAssociatesByCodPrincipal(filters)
      .then((response: IAssociatesResponse) => {
        let _array: [IHomeTargetsOptionList] = [{ value: '', label: 'Todas' }];
        let _isShowButtonResume = false;
        if (response.list && response.list.length > 0) {
          setListAssociates(response.list);
          for (let reg of response.list) {
            if (reg?.codigo && reg?.nome) {
              _array.push({
                value: reg.codigo, label: `${reg.codigo} - ${reg.nome}`
              })

              if (reg?.codigo === reg?.codigoPrincipal) {
                if (props.profile.documento === reg?.documento) {
                  _isShowButtonResume = true;
                }
              }

            }
          }

          setShowButtonResume(_isShowButtonResume);
          toggleShowResume(_isShowButtonResume);
          setOptSelectListConst(_array)
        }
        else {
          setListAssociates([]);
          setOptSelectListConst(_array)
        }

      }).catch((error: IAssociatesResponse) => {
        console.log(error.message);
      })

  }, [props.profile.documento, toggleShowResume])

  const [itemSelectList, setItemSelectList] = React.useState<string>(OptSelectListConst[0].value);

  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [showLoadingProductThisPage, setShowLoadingProductThisPage] = React.useState<boolean>(false);

  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());

  const [resultPanelAssociates, setResultPanelAssociates] = React.useState<IHomeResultPanel>({ result: [] })
  const toggleResultPanelAssociates = (values: IHomeResultPanel) => {
    setResultPanelAssociates(values)
  }

  const [showModalAssociateProductDetail, setShowModalAssociateProductDetail] = React.useState<boolean>(false);
  const toggleShowModalAssociateProductDetail = (value: boolean) => {
    setShowModalAssociateProductDetail(value);
  }

  const [titleModalTargetProduct, setTitleModalTargetProduct] = React.useState<string>('Meta');
  const toggleTitleModalTargetProduct = (value: string) => {
    setTitleModalTargetProduct(value);
  }

  const [listModalTargetsProduct, setListModalTargetsProduct] = React.useState<[ITargetsProduct?]>([])
  const [listTargetsProduct, setModalTargetsProduct] = React.useState<[ITargetsProduct?]>([])

  const toggleGetTargetsProduct = React.useCallback(async (filters: ITargetsProductFilters, type: string) => {
    toggleChangeState('showLoadingProductThisPage', true);
    toggleChangeState('filtersProduct', filters)

    await getTargetsProductAssociadoService(filters)
      .then((response: ITargetsProductResponse) => {
        switch (type) {
          case 'Modal':
            setListModalTargetsProduct(response.list || []);
            break;
          default:
            setModalTargetsProduct(response.list || []);
            break;
        }
        toggleChangeState('showLoadingProductThisPage', false);
      }).catch((error: ITargetsProductResponse) => {
        console.log(error.message);
        toggleChangeState('showLoadingProductThisPage', false);
      })
  }, [])

  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'itemSelectList':
        setItemSelectList(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        setModalTargetsProduct([]);
        setFiltersProduct({ nomeFornec: '' });
        break;
      case 'filters':
        setFilters(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'filtersProduct':
        setFiltersProduct(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingProductThisPage':
        setShowLoadingProductThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  const [listHomeTargetsSupplier, setListHomeTargetsSupplier] = React.useState<[IHomeTargetsSupplier?]>([]);
  const [listHomeTargetsSupplierResume, setListHomeTargetsSupplierResume] = React.useState<[IHomeTargetsSupplier?]>([]);

  const toggleGetHomeTargetsSupplier = React.useCallback(async (filters: IHomeTargetsSupplierFilters) => {
    toggleChangeState('showLoadingThisPage', true);
    toggleChangeState('filters', filters)
    if (props.toggleGetTargetProductGoalAssociate) {
      const _filtersGoal: ITargetsProductFilters = {
        codigo: filters.codigo,
        nome: filters.nome,
        documento: filters.documento,
        codClientePrinc: filters.codClientePrinc,
        nomeFornec: filters.nomeFornec,
      }

      props.toggleGetTargetProductGoalAssociate(_filtersGoal);
    }

    await getTargetSupplierService(filters)
      .then((response: IHomeTargetsSupplierResponse) => {
        toggleChangeState('showLoadingThisPage', false);
        setListHomeTargetsSupplier(response.list || []);
        setListHomeTargetsSupplierResume(response.listResume || []);
      })
      .catch((error: IHomeTargetsSupplierResponse) => {
        let layoutAlert: ILayout = {
          context: props.layout.context,
          titleMessage: 'Pesquisar registros',
          typeMessage: 'error',
          message: error.message,
          loading: false,
        };

        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const _arrayPanel: [IPanelCustom?] = [];
    let _totFaturamento: number = 0;
    let _totMetaFaturamento: number = 0;
    let _percentFaturamento: number = 0;
    let _totMix: number = 0;
    let _totMetaMix: number = 0;
    let _percentMix: number = 0;

    if (listHomeTargetsSupplier && listHomeTargetsSupplier.length > 0) {
      for (let reg of listHomeTargetsSupplier) {
        _totFaturamento += convertToNumber(reg?.faturamento);
        _totMetaFaturamento += convertToNumber(reg?.metaFaturamento);
        // _totMix += convertToNumber(reg?.mix);
        // _totMetaMix += convertToNumber(reg?.metaMix);
      }
    }

    let _infoMix: ITargetsProductGoals = {
      list: [],
      totRegisters: 0,
      qtdeCxFat: 0,
    };
    if (props.toggleGetCache) {
      const _regCache: any = props.toggleGetCache(KEY_TARGETPRODUCT_GOAL_ASSOCIATES);
      if (_regCache) {
        _infoMix.totRegisters = _regCache.totRegisters ? _regCache.totRegisters : 0;
        _infoMix.qtdeCxFat = _regCache.qtdeCxFat ? _regCache.qtdeCxFat : 0;
      }
    }

    _totMetaMix = _infoMix.totRegisters || 0;
    _totMix = _infoMix.qtdeCxFat || 0;


    // Trata valores de Faturamento
    if (_totMetaFaturamento > 0) {
      _percentFaturamento = (_totFaturamento / _totMetaFaturamento) * 100;
    }

    _arrayPanel.push({
      title: 'Faturamento',
      value: isNaN(_totFaturamento) ? 'R$ 0,00' : `R$ ${_totFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `,
      type: PanelCustomConstant.TYPE_FAT,
      anotation: `Meta: R$ ${_totMetaFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br/> ( ${_percentFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}% )`,
      alert: _percentFaturamento >= 100 ? PanelCustomConstant.SUCCESS :
        _percentFaturamento >= 80 ? PanelCustomConstant.SUCCESS_LIGHT :
          _percentFaturamento >= 50 ? PanelCustomConstant.WARNING :
            _percentFaturamento >= 40 ? PanelCustomConstant.WARNING_LIGHT :
              _percentFaturamento >= 30 ? PanelCustomConstant.DANGER_LIGHT :
                PanelCustomConstant.DANGER,
    })

    // Trata valores de Mix
    if (_totMetaMix > 0) {
      _percentMix = (_totMix / _totMetaMix) * 100;
    }

    _arrayPanel.push({
      title: 'Mix',
      value: isNaN(_totMix) ? '0' : _totMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
      type: PanelCustomConstant.TYPE_MIX,
      anotation: `Meta: ${_totMetaMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 })} <br/> ( ${_percentMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 })}% )`,
      alert: _percentMix >= 100 ? PanelCustomConstant.SUCCESS :
        _percentMix >= 80 ? PanelCustomConstant.SUCCESS_LIGHT :
          _percentMix >= 50 ? PanelCustomConstant.WARNING :
            _percentMix >= 40 ? PanelCustomConstant.WARNING_LIGHT :
              _percentMix >= 30 ? PanelCustomConstant.DANGER_LIGHT :
                PanelCustomConstant.DANGER,
    })

    toggleResultPanelAssociates({ result: _arrayPanel });
  }, [listHomeTargetsSupplier, props.dateUpdateInfo, props.targetsProductGoalAssociates])//eslint-disable-line


  React.useEffect(() => {
    if (props.targetSupplierSelected && props.targetSupplierSelected.id) {
      toggleTitleModalTargetProduct(`${props.targetSupplierSelected.codigo}: ${props.targetSupplierSelected.nome} - ${props.targetSupplierSelected.codigoFornec}: ${props.targetSupplierSelected.nomeFornec} `);

      const filters: ITargetsProductFilters = {
        codigoFornec: props.targetSupplierSelected.codigoFornec,
        codigo: props.targetSupplierSelected.codigo,
      }
      toggleGetTargetsProduct(filters, 'Modal')
    }
  }, [props.targetSupplierSelected, props.dateUpdateInfo, toggleGetTargetsProduct])

  React.useEffect(() => {
    let _filters: IHomeTargetsSupplierFilters = {
      nomeFornec: filters.nomeFornec,
      codigo: '',
      codClientePrinc: '',
      documento: ''
    };

    if (itemSelectList) {
      _filters.codigo = itemSelectList
      // toggleGetHomeTargetsSupplier(_filters);
    }
    else {
      if (associadoLogado.codigo) {
        if (associadoLogado.codigo === associadoLogado.codigoPrincipal) {
          _filters.codClientePrinc = associadoLogado.codigo;
        }
        else {
          _filters.codigo = associadoLogado.codigo
        }

        // toggleGetHomeTargetsSupplier(_filters)
      }
      else {
        toggleChangeState('showLoadingThisPage', false);
      }
    }

    toggleChangeState('filters', _filters)

  }, [associadoLogado.codigo, itemSelectList])//eslint-disable-line

  React.useEffect(() => {
    if (filters.codigo || filters.codClientePrinc) {
      toggleGetHomeTargetsSupplier(filters);
    }

  }, [filters, listAssociates, toggleGetHomeTargetsSupplier])//eslint-disable-line

  React.useEffect(() => {
    let _filters: IHomeTargetsSupplierFilters = {
      documento: props.profile.documento
    };
    toggleAssociadoLogado(_filters)
  }, [props.profile.documento, toggleAssociadoLogado])

  React.useEffect(() => {
    if (associadoLogado && associadoLogado.codigo) {
      const _filter: IAssociatesFilters = {
        codigo: associadoLogado.codigo
      }
      toggleSelectListConst(_filter);

    }
  }, [associadoLogado, toggleSelectListConst])

  const handlersPage: IHomeTargetsSupplierPage = {
    ...props,
    dateUpdateInfo,
    showLoadingThisPage,
    optionsSelectList: OptSelectListConst,
    itemSelectList,
    listHomeTargetsSupplier,
    showModalAssociateProductDetail,
    listModalTargetsProduct,
    listTargetsProduct,
    titleModalTargetProduct,
    toggleGetHomeTargetsSupplier,
    toggleChangeState,
    toggleShowModalAssociateProductDetail,
    resultPanelAssociates,
    filters,
    listAssociates,
    showLoadingProductThisPage,
    filtersProduct,
    toggleGetTargetsProduct,
    showButtonResume,
    showResume,
    toggleShowResume,
    listHomeTargetsSupplierResume
  };

  return (
    <HomeUserPage {...handlersPage} />
  );
}

export default HomeUserController;
