import React from 'react';
import {
  Box, Typography,
} from '@mui/material';

interface IField {
  label: string;
  value: string;
}
function ProductDetailHeaderFieldPage(props: IField) {

  return (
    <Box className='ProductDetailHeaderFieldsBox'>
      <Typography component="div" align="left">
        <span className='ProductDetailHeaderFieldsLabel'>{props.label}</span>
      </Typography>
      <Typography component="div" align="left">
        <span className='ProductDetailHeaderFieldsText'>{props.value}</span>
      </Typography>
    </Box>
  )
}

export default React.memo(ProductDetailHeaderFieldPage);
