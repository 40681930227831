import { ExcelRenderer } from 'react-excel-renderer';

class RendererFileExcel {
  Execute(fileObj) {
    return new Promise((resolve, reject) => {
      try {
        const _struct = {
          listCols: [],
          columns: [],
          rows: []
        }
        ExcelRenderer(fileObj, (err, resp) => {
          if (err) {
            console.log(err);
            reject(err)
          }
          else {
            let rowsTable = [];
            let colsTable = [];
            let countRow = 0;

            let listCols = [];
            let listKeysCols = [];
            resp.rows.forEach(item => {
              if (countRow === 0) {
                listKeysCols = Object.keys(item);
                Object.keys(item).forEach(key => {
                  const reg = item[key];
                  let regCol = {};
                  regCol.title = reg;
                  regCol.field = reg;
                  regCol.type = 'string';
                  regCol.hidden = false;
                  regCol.editable = 'never';
                  regCol.defaultSort = 'desc';

                  colsTable.push(JSON.stringify(regCol))
                  listCols.push({ key: key, name: reg });
                })
              }
              else {
                let regRow = {};
                listKeysCols.forEach(key => {
                  const reg = item[key] ? item[key] : '';
                  const nameCol = listCols.find(el => el.key === key).name;
                  regRow[nameCol] = reg;
                })
                rowsTable.push(JSON.stringify(regRow));
              }
              countRow++;
            })

            _struct.columns = colsTable;
            _struct.listCols = listCols;
            _struct.rows = rowsTable;

            resolve(_struct)
          }
        });
      }
      catch (err) {
        reject(err)
      }
    })
  }
}

const RendererExcel = new RendererFileExcel();

export default RendererExcel;