
import {
  Box, Grid, Typography,
} from '@mui/material';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faTasksAlt
// } from '@fortawesome/free-solid-svg-icons'
import { IReportDetailPage } from '../../Interface';

import LogoReportTop from '../../../../Assets/list-check-solid.svg';
import FieldPage from './_field';
function ReportDetailHeaderPage(props: IReportDetailPage) {

  return (
    <Box id="ReportDetailHeaderPage" key="ReportDetailHeaderPage" className="ReportDetailHeaderRoot">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left" className='ReportDetailHeaderBox'>
            <Box className='ReportDetailHeaderImgLogoBox'>
              <img
                src={`${LogoReportTop}`}
                srcSet={`${LogoReportTop}`}
                alt="Logo Report"
                loading="lazy"
                className='ReportDetailHeaderImgLogo'
              />
            </Box>
            <Box className='ReportDetailHeaderTitleBox'>
              <Typography component="div" align="left">
                <span className='ReportDetailHeaderSubTitle'>Relatório</span>
              </Typography>
              <Typography component="div" align="left">
                <span className='ReportDetailHeaderTitle'>{props.item.criadoEm}:  NF {props.item.numeroNota} - {props.item.nomeProduto}</span>
              </Typography>
            </Box>
            <Box className='ReportDetailHeaderButtonBox'>
              <Typography component="div" align="right">
                {/* <Button
                  variant="contained"
                  color="success"
                  className="ButtonFinish"
                  type="button"
                >
                  <span>Finalizar Venda</span>
                </Button> */}
              </Typography>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="center" className='ReportDetailHeaderInfoBox'>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'Associado'} value={props.item.nomeCliente || ''} />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'Fornecedor'} value={props.item.nomeFornecedor || ''} />
              </Grid>
              <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'Ean'} value={props.item.ean || ''} />
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ReportDetailHeaderPage;
