
import {
  Box, Grid, Typography,
} from '@mui/material';

import { ISupplierDetailPage } from '../../Interface';

import LogoSupplierTop from '../../../../Assets/lead_120.png';
import FieldPage from './_field';
function SupplierDetailHeaderPage(props: ISupplierDetailPage) {

  return (
    <Box id="SupplierDetailHeaderPage" key="SupplierDetailHeaderPage" className="SupplierDetailHeaderRoot">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left" className='SupplierDetailHeaderBox'>
            <Box className='SupplierDetailHeaderImgLogoBox'>
              <img
                src={`${LogoSupplierTop}`}
                srcSet={`${LogoSupplierTop}`}
                alt="Logo Supplier"
                loading="lazy"
                className='SupplierDetailHeaderImgLogo'
              />
            </Box>
            <Box className='SupplierDetailHeaderTitleBox'>
              <Typography component="div" align="left">
                <span className='SupplierDetailHeaderSubTitle'>Fornecedor</span>
              </Typography>
              <Typography component="div" align="left">
                <span className='SupplierDetailHeaderTitle'>{props.item.nome}</span>
              </Typography>
            </Box>
            <Box className='SupplierDetailHeaderButtonBox'>
              <Typography component="div" align="right">
                {/* <Button
                  variant="contained"
                  color="success"
                  className="ButtonFinish"
                  type="button"
                >
                  <span>Finalizar Venda</span>
                </Button> */}
              </Typography>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="center" className='SupplierDetailHeaderInfoBox'>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'Código'} value={props.item.codigo || ''} />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'CPF/CNPJ'} value={props.item.documento || ''} />
              </Grid>
              <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'Ativo'} value={props.item.active ? 'Sim' : 'Não'} />
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SupplierDetailHeaderPage;
