import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import { Form } from '@unform/web';
import InputCustom from 'Shared/Components/Form/InputReactCustom';

// import * as Yup from 'yup';
import { IProductsFilters, IProductsPage } from '../../Interface';
import React from 'react';

function ProductsFilterFormPage(props: IProductsPage) {
  const formDefault = React.useRef<any>(null);

  const [initialData, setInitialData] = React.useState<IProductsFilters>({
    codigo: props.filters.codigo,
    nome: props.filters.nome,
    ean: props.filters.ean,
    active: 'Todos',
    nomeFornec: props.filters.nomeFornec,
  });

  // const schema = Yup.object().shape({
  //   codigo: Yup.string(),
  //   nome: Yup.string(),
  //   ean: Yup.string(),
  //   active: Yup.string(),
  //   nomeFornec: Yup.string(),
  // });

  const handleCleanFilters = () => {
    const _filters: IProductsFilters = { codigo: '', nome: '', ean: '', active: props.filters.active, nomeFornec: '' };
    props.toggleChangeState('filters', _filters);
  }

  const handleSubmit = (data: any) => {
    const _filters: IProductsFilters = {
      codigo: data.codigo,
      nome: data.nome.toUpperCase(),
      ean: data.ean,
      active: data.active,
      nomeFornec: data.nomeFornec.toUpperCase()
    };
    props.toggleGetProducts(_filters);
  };

  React.useEffect(() => {
    setInitialData(props.filters);
    if (formDefault.current) {
      formDefault.current.setFieldValue('codigo', props.filters.codigo);
      formDefault.current.setFieldValue('nome', props.filters.nome);
      formDefault.current.setFieldValue('ean', props.filters.ean);
      formDefault.current.setFieldValue('nomeFornec', props.filters.nomeFornec);
      formDefault.current.setFieldValue('active', props.filters.active);
    }
  }, [props.filters, props.dateUpdateInfo])

  return (
    <Box id="Products" key="Products" className="FormRoot" data-label={props.dateUpdateInfo}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="justify">
            <Box id="ProductsFilterForm" key="ProductsFilterForm">
              <Form
                ref={formDefault}
                id="myFormDefault"
                name="myFormDefault"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.codigo}
                        id="codigo"
                        name="codigo"
                        label="Código"
                        type="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.nome}
                        id="nome"
                        name="nome"
                        label="Nome"
                        type="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.ean}
                        id="ean"
                        name="ean"
                        label="EAN"
                        type="text" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.nomeFornec}
                        id="nomeFornec"
                        name="nomeFornec"
                        label="Fornecedor"
                        type="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={true}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.active}
                        id="active"
                        name="active"
                        label="Ativo"
                        type="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="right">
                      <Button
                        variant="contained"
                        color="success"
                        className="ButtonPrimary"
                        type="submit"
                      >
                        <span>Pesquisar</span>
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="right">
                      <Button
                        variant="contained"
                        color="success"
                        className="ButtonDefault"
                        type="button"
                        onClick={handleCleanFilters}
                      >
                        <span>Limpar filtros</span>
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
                <span>&nbsp;</span>
              </Form>
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProductsFilterFormPage;
