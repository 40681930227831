import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILayout } from '../Interface/index';

interface LayoutState {
  layout: ILayout,
}

const initialState: LayoutState = {
  layout: {
    loading: false,
    typeMessage: '',
    titleMessage: '',
    message: '',
    context: { result: {} },
  },
};

export const LayoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    saveLayout: (state, action: PayloadAction<ILayout>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.layout = payload;
    },
  },
});

export default LayoutSlice.reducer;
export const { saveLayout } = LayoutSlice.actions;
