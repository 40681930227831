import {
  Box, Grid,
} from '@mui/material';

import { ISuppliersReportPage } from '../Interface';
import FiltersPage from './Filters/index';
import ResultPage from './Result/index';

function SuppliersReportPage(props: ISuppliersReportPage) {
  return (
    <Box id="SuppliersReport" key="SuppliersReport" className="SuppliersReportRoot">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FiltersPage {...props} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ResultPage {...props} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default SuppliersReportPage;
