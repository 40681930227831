import ConfigConstant from 'Shared/Constant/Config/index';

const getUrlApi = (environment?: string) => {
  let _system = '';
  switch (environment) {
    case ConfigConstant.ENVIRONMENT_LOCAL:
      _system = ConfigConstant.SYSTEMAUTH_LOCAL
      break;
    case ConfigConstant.ENVIRONMENT_DEV:
      _system = ConfigConstant.SYSTEMAUTH_LOCAL
      break;
    case ConfigConstant.ENVIRONMENT_HML:
      _system = ConfigConstant.SYSTEMAUTH_LOCAL
      break;
    default:
      _system = ConfigConstant.SYSTEMAUTH_LOCAL
      break;
  }

  return _system;
};

export default getUrlApi;