import './Style/index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { store } from './Store/index';
import { themeDefault } from './Style/mui/themeDefault';
import { IAdConfig } from 'Shared/Interfaces/AdConfig/iAdConfig';

const contgAD: IAdConfig = {
  hostAuthority: process.env.REACT_APP_HOST_AD,
  tenantId: process.env.REACT_APP_TENANT_ID_AD,
  clientId: process.env.REACT_APP_API_CLIENT_ID_AD,
  policyName: process.env.REACT_APP_API_POLICY_NAME,
  scretId: process.env.REACT_APP_API_SECRET_ID_AD,
  hostGraph: process.env.REACT_APP_API_HOST_GRAPH_AD,
  scope: process.env.REACT_APP_API_SCOPE_AD ? process.env.REACT_APP_API_SCOPE_AD.split(',') : [],
  cacheLocation: process.env.REACT_APP_API_CACHE_LOCATION_AD,
  return_1: process.env.REACT_APP_WEB_RETURN_1,
  return_2: process.env.REACT_APP_WEB_RETURN_2,
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={themeDefault}>
        <App {...contgAD} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
