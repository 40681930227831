import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import { Form } from '@unform/web';
import InputCustom from 'Shared/Components/Form/InputReactCustom';

// import * as Yup from 'yup';
import { IAssociatesFilters, IAssociatesPage } from '../../Interface';
import React from 'react';

function AssociatesFilterFormPage(props: IAssociatesPage) {
  const formDefault = React.useRef<any>(null);

  const [initialData, setInitialData] = React.useState<IAssociatesFilters>({
    codigo: props.filters.codigo,
    nome: props.filters.nome,
    documento: props.filters.documento,
    active: 'Todos' //props.filters.active
  });

  // const schema = Yup.object().shape({
  //   nome: Yup.string(),
  //   codigo: Yup.string(),
  //   documento: Yup.string(),
  //   email: Yup.string().email(),
  //   active: Yup.string(),
  // });

  const handleCleanFilters = () => {
    const _filters: IAssociatesFilters = { codigo: '', nome: '', documento: '', active: props.filters.active };
    props.toggleChangeState('filters', _filters);
  }

  const handleSubmit = (data: any) => {
    const _filters: IAssociatesFilters = {
      codigo: data.codigo,
      nome: data.nome.toUpperCase(),
      documento: data.documento,
      active: data.active,
    };
    props.toggleGetAssociates(_filters);
  };

  React.useEffect(() => {
    setInitialData(props.filters);
    if (formDefault.current) {
      formDefault.current.setFieldValue('codigo', props.filters.codigo);
      formDefault.current.setFieldValue('nome', props.filters.nome);
      formDefault.current.setFieldValue('documento', props.filters.documento);
      formDefault.current.setFieldValue('active', props.filters.active);
    }
  }, [props.filters, props.dateUpdateInfo])

  return (
    <Box id="Associates" key="Associates" className="FormRoot" data-label={props.dateUpdateInfo}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="justify">
            <Box id="AcountFilterForm" key="AcountFilterForm">
              <Form
                ref={formDefault}
                id="myFormDefault"
                name="myFormDefault"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={3} md={3} lg={2} xl={2}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.codigo}
                        id="codigo"
                        name="codigo"
                        label="Código"
                        type="text" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.nome}
                        id="nome"
                        name="nome"
                        label="Nome"
                        type="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} md={4} lg={4} xl={4}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.documento}
                        id="documento"
                        name="documento"
                        label="Documento"
                        type="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={2} md={2} lg={2} xl={2}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={true}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.active}
                        id="active"
                        name="active"
                        label="Ativo"
                        type="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="right">
                      <Button
                        variant="contained"
                        color="success"
                        className="ButtonPrimary"
                        type="submit"
                      >
                        <span>Pesquisar</span>
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="right">
                      <Button
                        variant="contained"
                        color="success"
                        className="ButtonDefault"
                        type="button"
                        onClick={handleCleanFilters}
                      >
                        <span>Limpar filtros</span>
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
                <span>&nbsp;</span>
              </Form>
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AssociatesFilterFormPage;
