import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import ProductNewPage from '../Page/index';
import { IProductNewPage } from '../Interface';
import { IProducts, IProductsContext, IProductsResponse } from 'Module/Products/Interface';
import RecordStructureConstant from 'Shared/Constant/ProductNew/RecordStructure';
import { setProductsService } from '../Service/SetProduto/index';
import { getSuppliersService } from 'Module/Suppliers/Service/Get/index';
import { ISuppliers, ISuppliersFilters, ISuppliersResponse } from 'Module/Suppliers/Interface';

import { useNavigate } from 'react-router-dom';
import { PRODUCTS } from 'Shared/Constant/Routes';

function ProductNewController(props: ILayoutPage) {
  const navigate = useNavigate();

  const [contextProducts] = React.useState<IProductsContext>({ result: [] });
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [item, setItem] = React.useState<IProducts>({});
  const toggleSetItem = (itemSelected: IProducts) => {
    setItem(itemSelected);
  }

  const [listSuppliers, setListSuppliers] = React.useState<[ISuppliers?]>([]);
  const toggleGetSuppliers = React.useCallback(async (filters: ISuppliersFilters) => {
    toggleChangeState('showLoadingThisPage', true);
    toggleChangeState('filters', filters)

    await getSuppliersService(filters)
      .then((response: ISuppliersResponse) => {
        setListSuppliers(response.list || []);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
      .catch((error: ISuppliersResponse) => {
        let layoutAlert: ILayout = {
          context: props.layout.context,
          titleMessage: 'Pesquisar registros',
          typeMessage: 'error',
          message: error.message,
          loading: false,
        };

        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showModalQuestionYesNo, setShowModalQuestionYesNo] = React.useState<boolean>(false);
  const toggleShowModalQuestionYesNo = (value: boolean) => {
    setShowModalQuestionYesNo(value);
  }

  const toggleSetProducts = React.useCallback(async (product: IProducts, id: string) => {
    console.log(product)

    toggleChangeState('showLoadingThisPage', true);
    let isFilter = true;
    let msgError = '';
    if (!product.nome) {
      isFilter = false;
      msgError += ' Nome não informado!';
    }
    if (!product.codigo) {
      isFilter = false;
      msgError += ' Código não informado!';
    }
    if (!product.ean) {
      isFilter = false;
      msgError += ' EAN não informado!';
    }

    // if (props.listProducts && props.listProducts.length > 0) {

    // }

    if (isFilter) {
      await setProductsService(product, id)
        .then((response: IProductsResponse) => {
          let layoutAlert: ILayout = {
            loading: false,
            typeMessage: 'success',
            titleMessage: 'Salvo',
            message: 'Registro salvo com sucesso',
          }
          props.toggleSetLayout(layoutAlert);
          toggleChangeState('showLoadingThisPage', false);
          navigate(PRODUCTS);
          navigate(0);
        })
        .catch((error: IProductsResponse) => {
          console.log(error.message);
          let layoutAlert: ILayout = {
            loading: false,
            typeMessage: 'error',
            titleMessage: 'Erro ao salvar',
            message: error.message,
          }
          props.toggleSetLayout(layoutAlert);
          toggleChangeState('showLoadingThisPage', false);
        })
    }
    else {
      console.log(msgError);
      let layoutAlert: ILayout = {
        loading: false,
        typeMessage: 'error',
        titleMessage: 'Erro ao salvar',
        message: msgError,
      }
      props.toggleSetLayout(layoutAlert);
      toggleChangeState('showLoadingThisPage', false);
    }
  }, []);//eslint-disable-line


  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'item':
        toggleSetItem(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  React.useEffect(() => {
    let _filters: ISuppliersFilters = {}
    toggleGetSuppliers(_filters)
  }, [toggleGetSuppliers])

  const handlerPage: IProductNewPage = {
    ...props,
    context: contextProducts,
    showLoadingThisPage,
    dateUpdateInfo,
    item,
    structure: RecordStructureConstant,
    toggleChangeState,
    toggleSetProducts,
    showModalQuestionYesNo,
    toggleShowModalQuestionYesNo,
    listSuppliers
  }
  return (
    <React.Fragment>
      <ProductNewPage {...handlerPage} />
    </React.Fragment>
  )
}


export default ProductNewController;
