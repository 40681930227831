import GetVariable from './_getVariable';
import SetVariable from './_setVariable';
import RemoveVariable from './_removelVariable';
import ClearAllVariables from './_clearAll';

const get = (key: string) => GetVariable(key);
const set = (key: string, value: any) => SetVariable(key, value);
const remove = (key: string) => RemoveVariable(key);
const clearAll = () => ClearAllVariables();

export {
  get, set, remove, clearAll,
};
