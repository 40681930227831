import * as React from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Box, Grid, Typography } from '@mui/material';
import InputCustom from 'Shared/Components/Form/InputReactCustom';
import { IChangePassword, IChangePasswordPage } from '../Interface';
import { ILayout } from 'Module/Layout/Interface';

function PaperComponent(props: PaperProps) {
  const nodeRef = React.useRef(null);
  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

export default function ModalChangePasswordPage(props: IChangePasswordPage) {
  const [open] = React.useState(true);
  const formDefault = React.useRef<any>(null);

  const handleSubmit = async (data: any) => {
    let msgError = '';

    try {
      const schema = Yup.object().shape({
        passwordcurrent: Yup.string().required('Senha atual Senha não informado!'),
        password: Yup.string().required('Nova senha não informado!').min(5, 'Nova Senha com mínimo de 5 dígitos!'),
        confirmpassword: Yup.string().required('Confirmar senha não informado!'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      formDefault.current.setErrors({});

      if (data.password !== data.confirmpassword) {
        msgError += 'Nova Senha e Confirmar Senha devem ser iguais.'
      }
      if (!msgError) {
        let _field: IChangePassword = {
          id: props.profile.id,
          confirmpassword: data.confirmpassword,
          passwordcurrent: data.passwordcurrent,
          password: data.password
        };
        props.toggleSetChangePassword(_field);
      }
      else {
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'error',
          titleMessage: 'Erro',
          message: msgError,
        }
        props.toggleSetLayout(layoutAlert);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        // Validation failed
        console.log('NewAttachments-handleSubmit.err');
        console.log(err);
        const errorMessage = Object.create(null);
        err.inner.map((error) => {
          return (errorMessage[error.path ? error.path : '1'] = error.message);
        })
        formDefault.current.setErrors(errorMessage);
      }
    }
  }

  const handleClose = () => {
    if (props.toggleShowModalChangePassword) {
      props.toggleShowModalChangePassword(false)
    }
  };

  return (
    <Box>
      <Dialog
        maxWidth='sm'
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className='ModalRoot'
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography component={'div'} align='left'>
                <span className='AssociatesReportModalTitle'>Alterar Senha</span>
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <Form
              ref={formDefault}
              id="myFormDefault"
              name="myFormDefault"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
                    <InputCustom
                      autoComplete="off"
                      handleChangeCustom={() => { }}
                      disabled={false}
                      id={'passwordcurrent'}
                      name={'passwordcurrent'}
                      label={'Senha atual'}
                      type='password'
                      classCustom={'inputCustomDefault'}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
                    <InputCustom
                      autoComplete="off"
                      handleChangeCustom={() => { }}
                      disabled={false}
                      id={'password'}
                      name={'password'}
                      label={'Nova senha'}
                      type='password'
                      classCustom={'inputCustomDefault'}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
                    <InputCustom
                      autoComplete="off"
                      handleChangeCustom={() => { }}
                      disabled={false}
                      id={'confirmpassword'}
                      name={'confirmpassword'}
                      label={'Confirmar nova senha'}
                      type='password'
                      classCustom={'inputCustomDefault'}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography component={'div'} align='left'>
                <Button
                  variant="contained"
                  color="success"
                  className="ButtonDanger"
                  type="button"
                  onClick={handleClose}
                >
                  <span>Cancelar</span>
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography component={'div'} align='right'>
                <Button
                  variant="contained"
                  color="success"
                  className="ButtonOK"
                  type="submit"
                  onClick={(e) => { formDefault.current.submitForm() }}
                >
                  <span>Alterar senha</span>
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
