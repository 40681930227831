import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import { Form } from '@unform/web';
import InputCustom from 'Shared/Components/Form/InputReactCustom';

// import * as Yup from 'yup';
import { ITargetsProductFilters, ITargetsProductPage } from '../../Interface';
import React from 'react';

function TargetsProductFilterFormPage(props: ITargetsProductPage) {
  const formDefault = React.useRef<any>(null);

  const [initialData, setInitialData] = React.useState<ITargetsProductFilters>({
    codProduto: props.filters.codProduto,
    nomeProduto: props.filters.nomeProduto,
    ean: props.filters.ean,

    codigo: props.filters.codigo,
    nome: props.filters.nome,
    documento: props.filters.documento,

    codigoFornec: props.filters.codigoFornec,
    documentFornec: props.filters.documentFornec,
    nomeFornec: props.filters.nomeFornec,
  });

  // const schema = Yup.object().shape({
  //   nomeProduto: Yup.string(),
  //   nome: Yup.string(),
  //   nomeFornec: Yup.string(),
  // });

  const handleCleanFilters = () => {
    const _filters: ITargetsProductFilters = {
      codProduto: '',
      nomeProduto: '',
      ean: '',

      codigo: '',
      nome: '',
      documento: '',

      codigoFornec: '',
      documentFornec: '',
      nomeFornec: '',
    };

    props.toggleChangeState('filters', _filters);
  }

  const handleSubmit = (data: any) => {
    const _filters: ITargetsProductFilters = {
      nomeProduto: data.nomeProduto,
      nome: data.nome.toUpperCase(),
      nomeFornec: data.nomeFornec.toUpperCase(),
    };
    props.toggleGetTargetsProduct(_filters);
  };

  React.useEffect(() => {
    setInitialData(props.filters);
    if (formDefault.current) {
      formDefault.current.setFieldValue('nomeProduto', props.filters.nomeProduto);
      formDefault.current.setFieldValue('nome', props.filters.nome);
      formDefault.current.setFieldValue('nomeFornec', props.filters.nomeFornec);
    }
  }, [props.filters, props.dateUpdateInfo])

  return (
    <Box id="TargetsProduct" key="TargetsProduct" className="FormRoot" data-label={props.dateUpdateInfo}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="justify">
            <Box id="TargetsProductFilterForm" key="TargetsProductFilterForm">
              <Form
                ref={formDefault}
                id="myFormDefault"
                name="myFormDefault"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.nomeProduto}
                        id="nomeProduto"
                        name="nomeProduto"
                        label="Nome produto"
                        type="text" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.nomeFornec}
                        id="nomeFornec"
                        name="nomeFornec"
                        label="Nome fornecedor"
                        type="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.nome}
                        id="nome"
                        name="nome"
                        label="Nome associado"
                        type="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="right">
                      <Button
                        variant="contained"
                        color="success"
                        className="ButtonPrimary"
                        type="submit"
                      >
                        <span>Pesquisar</span>
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="right">
                      <Button
                        variant="contained"
                        color="success"
                        className="ButtonDefault"
                        type="button"
                        onClick={handleCleanFilters}
                      >
                        <span>Limpar filtros</span>
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
                <span>&nbsp;</span>
              </Form>
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TargetsProductFilterFormPage;
