import React from 'react';
import { useAppSelector } from 'Store';
import { IProfile } from '../Interface';
import ProfilePage from '../Page/index';

function ProfileController(props: any) {
  const profileStore = useAppSelector((state) => state.profile.profile);

  const [profile, setProfile] = React.useState<null | IProfile>(null);
  const toggleProfile = (data: IProfile) => {
    setProfile(data);
  };

  React.useEffect(() => {
    toggleProfile(profileStore);
  }, [profileStore]);

  const handlersPage = {
    ...profile,
  };

  return (
    <ProfilePage {...handlersPage} />
  );
}

export default ProfileController;
