export const KEY_DATE_LOGIN = 'PORTAL.DateLogin';

export const KEY_TOKEN_API = 'PORTAL.TokenApi';

export const KEY_PROFILE = 'PORTAL.Profile';
export const KEY_LOGIN = 'PORTAL.Login';

export const KEY_ASSOCIATES_LIST = 'PORTAL.AssociatesList';
export const KEY_ASSOCIATES_SELECTED = 'PORTAL.AssociatesSelected';

export const KEY_USERS_LIST = 'PORTAL.UsersList';
export const KEY_USERS_SELECTED = 'PORTAL.UsersSelected';

export const KEY_SUPPLIERS_LIST = 'PORTAL.SuppliersList';
export const KEY_SUPPLIERS_SELECTED = 'PORTAL.SuppliersSelected';

export const KEY_PRODUCTS_LIST = 'PORTAL.ProductsList';
export const KEY_PRODUCTS_SELECTED = 'PORTAL.ProductsSelected';

export const KEY_REPORTS_LIST = 'PORTAL.ReportsList';
export const KEY_REPORTS_SELECTED = 'PORTAL.ReportsSelected';

export const KEY_REPORTSDEFAULT_LIST = 'PORTAL.ReportsDefaultList';
export const KEY_REPORTSDEFAULT_SELECTED = 'PORTAL.ReportsDefaultSelected';

export const KEY_ASSOCIATESREPORT_LIST = 'PORTAL.AssociatesReportList';
export const KEY_ASSOCIATESREPORT_SELECTED = 'PORTAL.AssociatesReportSelected';

export const KEY_SUPPLIERSREPORT_LIST = 'PORTAL.SuppliersReportList';
export const KEY_SUPPLIERSREPORT_SELECTED = 'PORTAL.SuppliersReportSelected';

export const KEY_HOMETARGETSSUPPLIER_LIST = 'PORTAL.HomeTargetsSupplierList';
export const KEY_HOMETARGETSSUPPLIER_SELECTED = 'PORTAL.HomeTargetsSupplierSelected';

export const KEY_TARGETPRODUCT_LIST = 'PORTAL.TargetsProductList';
export const KEY_TARGETPRODUCT_SELECTED = 'PORTAL.TargetsProductSelected';
export const KEY_TARGETPRODUCT_GOAL_ASSOCIATES = 'PORTAL.TargetsProductGoalAssociates';
export const KEY_TARGETPRODUCT_GOAL_SUPPLIERS = 'PORTAL.TargetsProductGoalSuppliers';

export const KEY_HOMETARGETSSUPPLIERASSOCIATE_LIST = 'PORTAL.HomeTargetsSupplierAssociateList';
export const KEY_HOMETARGETSSUPPLIERASSOCIATE_SELECTED = 'PORTAL.HomeTargetsSupplierAssociateSelected';
