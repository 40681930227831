import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { blue, orange } from '@mui/material/colors';

let theme = createTheme({
  palette: {
    primary: {
      main: blue[900],
    },
    secondary: {
      main: orange.A700,
    },
  },
});
theme = responsiveFontSizes(theme);

export const themeDefault = theme;
