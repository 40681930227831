import {
  Box, Grid, Typography,
} from '@mui/material';

import { IProductDetailPage } from '../../Interface';

import LogoProductTop from '../../../../Assets/custom45_120.png';
import FieldPage from './_field';
function ProductDetailHeaderPage(props: IProductDetailPage) {

  return (
    <Box id="ProductDetailHeaderPage" key="ProductDetailHeaderPage" className="ProductDetailHeaderRoot">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left" className='ProductDetailHeaderBox'>
            <Box className='ProductDetailHeaderImgLogoBox'>
              <img
                src={`${LogoProductTop}`}
                srcSet={`${LogoProductTop}`}
                alt="Logo Product"
                loading="lazy"
                className='ProductDetailHeaderImgLogo'
              />
            </Box>
            <Box className='ProductDetailHeaderTitleBox'>
              <Typography component="div" align="left">
                <span className='ProductDetailHeaderSubTitle'>Produto</span>
              </Typography>
              <Typography component="div" align="left">
                <span className='ProductDetailHeaderTitle'>{props.item.nome}</span>
              </Typography>
            </Box>
            <Box className='ProductDetailHeaderButtonBox'>
              <Typography component="div" align="right">
                {/* <Button
                  variant="contained"
                  color="success"
                  className="ButtonFinish"
                  type="button"
                >
                  <span>Finalizar Venda</span>
                </Button> */}
              </Typography>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="center" className='ProductDetailHeaderInfoBox'>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'Código'} value={props.item.codigo || ''} />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'EAN'} value={props.item.ean || ''} />
              </Grid>
              <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'Ativo'} value={props.item.active ? 'Sim' : 'Não'} />
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProductDetailHeaderPage;
