import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  Checkbox
} from '@mui/material';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';

import React from 'react';
import sortBy from 'sort-by';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Column } from '@material-table/core';
import { IHomeTargetSupplierAssociatePage, IHomeTargetSupplierAssociate } from '../../Interface/HomeTargetSupplierAssociate';
import { Table } from 'Shared/Components/Mui/Table/index';
import RegisterStructure from 'Shared/Constant/HomeTargetsSupplierAssociates/RecordStructure';
import { IFieldStructure } from 'Shared/Interfaces/ObjectSalesforce';
import { KEY_HOMETARGETSSUPPLIER_SELECTED, KEY_ASSOCIATESREPORT_SELECTED } from 'Shared/Constant/Cache';
import ModalProductDetail from '../HomeUserProductModal/index';
import ExportFilePage from './_exportfile';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function HomeTargetsAssociatesPage(props: IHomeTargetSupplierAssociatePage) {

  const [showResume, setShowResume] = React.useState<boolean>(false);
  const [showButtonResume, setShowButtonResume] = React.useState<boolean>(false);

  const [columns, setColumns] = React.useState<Column<object>[]>([]);
  const toggleSetColumns = (values: Column<object>[]) => {
    setColumns(values);
  }

  const [data, setData] = React.useState<object[]>([]);
  const toggleSetData = (values: [IHomeTargetSupplierAssociate?]) => {
    const _array: object[] = values.map(reg => reg || {});
    setData(_array);
  }

  const [expanded, setExpanded] = React.useState(true);
  const toggleExpanded = (value: boolean) => {
    setExpanded(value);
  };

  const toggleSetItemSelected = (item: any) => {
    console.log(item);
    const _itemHomeTargetSupplierAssociate: IHomeTargetSupplierAssociate = item;

    if (props.toggleSetAssociatesReportSelected) {
      props.toggleSetAssociatesReportSelected(_itemHomeTargetSupplierAssociate);
    }
    if (props.toggleSetCache) {
      props.toggleSetCache(KEY_ASSOCIATESREPORT_SELECTED, _itemHomeTargetSupplierAssociate);
    }
    if (props.toggleSetCache) {
      props.toggleSetCache(KEY_HOMETARGETSSUPPLIER_SELECTED, _itemHomeTargetSupplierAssociate);
    }

    if (props.toggleShowModalAssociateProductDetail) {
      props.toggleShowModalAssociateProductDetail(true);
    }
  }

  const getFieldsStructure = React.useCallback((list: IFieldStructure[]) => {
    let _array: Column<object>[] = [];
    if (list && list.length > 0) {
      for (let regField of list.sort(sortBy('orderFormMain'))) {
        let _column: Column<object> = {}
        if (regField.viewFormMain) {
          switch (regField.type) {
            case 'checkbox':
              _column = {
                title: regField.label,
                field: regField.nameField,
                editable: regField.edit ? 'always' : 'never',
                hidden: regField.hiddenFormMain,
                editComponent: (props) => {
                  console.log(props);
                  return (
                    <input
                      type="checkbox"
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  );
                },
                render: (rowdata: any) => {
                  if (regField.nameField === 'active') {
                    return (<input type="checkbox" checked={rowdata.active} readOnly />)
                  }
                  else if (regField.nameField === 'isMeta') {
                    return (<input type="checkbox" checked={rowdata.isMeta} readOnly />)
                  }
                }
              }
              break;
            case 'currency':
              _column = {
                title: regField.label,
                field: regField.nameField,
                type: 'string',
                align: 'right',
                editable: regField.edit ? 'always' : 'never',
                hidden: regField.hiddenFormMain
              }
              break;
            default:
              _column = {
                title: regField.label,
                field: regField.nameField,
                type: 'string',
                editable: regField.edit ? 'always' : 'never',
                hidden: regField.hiddenFormMain
              }
              break;
          }
          if (regField.defaultSort) {
            _column.defaultSort = regField.defaultSort === 'asc' ? 'asc' : 'desc';
          }
          if (regField.width) {
            _column.width = regField.width;
          }
          if (regField.cellStyle) {
            _column.cellStyle = regField.cellStyle;
          }
          _array.push(_column)
        }
      }
    }
    toggleSetColumns(_array);

  }, [])

  React.useEffect(() => {
    getFieldsStructure(RegisterStructure);

    if (showResume) {
      toggleSetData(props.listHomeTargetSupplierAssociateResume);
    }
    else {
      toggleSetData(props.listHomeTargetSupplierAssociate);
    }
  }, [showResume, props.listHomeTargetSupplierAssociate, props.listHomeTargetSupplierAssociateResume, props.dateUpdateInfo, getFieldsStructure])

  React.useEffect(() => {
    if (props.showButtonResume) {
      setShowButtonResume(true);
    }
    else {
      setShowButtonResume(false);
    }
  }, [props.showButtonResume])

  React.useEffect(() => {
    if (props.showResume) {
      setShowResume(true);
    }
    else {
      setShowResume(false);
    }
  }, [props.showResume])



  // toggleShowResume
  const handleExportFile = {
    ...props,
    showResume
  }

  return (
    <Box id="HomeTargetSupplierAssociate" key="HomeTargetSupplierAssociate">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Accordion
            expanded={expanded}
            onChange={() => { toggleExpanded(!expanded); }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component="div" align="left">
                <span className="FormTitle">Metas por associados</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography id="HomeTargetSupplierAssociateResultTableExport" component="div" align="right">
                <ExportFilePage {...handleExportFile} />
              </Typography>
              <Typography id="HomeTargetSupplierAssociateResultTable" component="div" align="left">
                {props.showLoadingThisPage ? <CircularProgress size={50} /> :
                  <React.Fragment>
                    {showButtonResume &&
                      <Typography component="div" align="right">
                        <Checkbox
                          checked={showResume}
                          onChange={() => {
                            if (props.toggleShowResume) {
                              props.toggleShowResume(!showResume)
                            }
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <span>Agrupar Matriz/Filial</span>
                      </Typography>
                    }
                    <Table
                      data={data}
                      columns={columns}
                      title='Metas'
                      href={'#'}
                      toggleSelectItem={toggleSetItemSelected}
                      actionsColumnIndex={-1}
                    />
                  </React.Fragment>
                }
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      {props.showModalAssociateProductDetail &&
        <ModalProductDetail {...props} />
      }
    </Box>
  );
}

export default HomeTargetsAssociatesPage;
