import {
  faUserGroup,
  faHomeAlt,
  faAddressCard,
  faProjectDiagram,
  faTasks,
  faUserAlt,
  faClipboardList
} from '@fortawesome/free-solid-svg-icons';

import * as Routers from "./Routes";
import { IMenuList } from 'Module/SideMenu/Interface';
let count = 0;

const List: IMenuList[] = [
  { id: 'home', label: 'Início', link: Routers.HOME, icon: faHomeAlt, order: ++count },
  { id: 'suppliers', label: 'Fornecedores', link: Routers.SUPPLIERS, icon: faUserGroup, order: ++count },
  { id: 'products', label: 'Produtos', link: Routers.PRODUCTS, icon: faProjectDiagram, order: ++count },
  { id: 'associates', label: 'Associados', link: Routers.ASSOCIATES, icon: faAddressCard, order: ++count },
  { id: 'reports', label: 'Relatórios', link: Routers.REPORTS, icon: faTasks, order: ++count },
  { id: 'users', label: 'Usuários', link: Routers.USERS, icon: faUserAlt, order: ++count },
  { id: 'reportsdefault', label: 'Relatório Base', link: Routers.REPORTSDEFAULT, icon: faClipboardList, order: ++count },
];

export default List;
