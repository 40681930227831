import { Typography } from '@mui/material';
import SelectCustom from 'Shared/Components/Form/SelectReactCustom/index';
import { IHomeTargetsOptionList } from '../../Interface/HomeTargetsSupplier';
import React from 'react';

interface iProps {
  itemSelected: string;
  options: IHomeTargetsOptionList[];
  handleChange(e: any): unknown;
}

function ItemSelect(props: iProps) {
  return (
    <Typography component="div" align="left">
      <SelectCustom
        isDisabled={false}
        id="itemselectedlist"
        name="itemselectedlist"
        label="Selecione a loja"
        options={props.options}
        defaultValue={props.itemSelected ? props.options.find((option: IHomeTargetsOptionList) => option.value === props.itemSelected) : props.options[0]}
        onChange={props.handleChange}
      />
    </Typography>
  )
}

export default React.memo(ItemSelect);