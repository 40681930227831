import React from 'react';

import {
  Box, CircularProgress, Grid, Typography
} from '@mui/material';

import PanelCustomPage from 'Shared/Components/PanelCustom/index';
import HomeUserSupplierTargetsAssociate from '../HomeTargetsAssociates/index';
import HomeUserBanner from '../HomeBanner/index';
import { IHomeResultPanel } from 'Module/Home/Interface';
import { IHomeTargetSupplierAssociatePage } from 'Module/HomeUserSupplier/Interface/HomeTargetSupplierAssociate';
import HomeListPage from '../HomeUserSupplierLists/index';
import HomeUserDocuments from 'Module/HomeUser/Page/HomeUserDocuments/index';
import FiltersPage from '../HomeTargetsAssociates/Filters/index';
import { ITargetsProduct } from 'Module/TargetsProduct/Interface';

function HomeSupplierPage(props: IHomeTargetSupplierAssociatePage) {
  const [listModalTargetsProductSupplier, setListModalTargetsProductSupplier] = React.useState<[ITargetsProduct?]>([])
  const [resultPanelSuppliers, setResultPanelSuppliers] = React.useState<IHomeResultPanel>({ result: [] })
  const toggleResultPanelSuppliers = (values: IHomeResultPanel) => {
    setResultPanelSuppliers(values)
  }

  React.useEffect(() => {
    if (props.resultPanelSuppliers) {
      toggleResultPanelSuppliers(props.resultPanelSuppliers);
    }
  }, [props.resultPanelSuppliers, props.dateUpdateInfo])

  React.useEffect(() => {

    if (props.listModalTargetsProductSupplier) {
      setListModalTargetsProductSupplier(props.listModalTargetsProductSupplier);
    }
    else {
      setListModalTargetsProductSupplier([]);
    }

  }, [props.listModalTargetsProductSupplier])

  return (
    <Box id="HomeSupplierPage" key="HomeSupplierPage">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <HomeUserBanner />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component={'div'} align='center'>
            <HomeListPage {...props} />
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            {props.showLoadingMixThisPage ?
              <Typography component={'div'} align='center'>
                <CircularProgress size={50} />
              </Typography>
              :
              <React.Fragment>
                {resultPanelSuppliers && resultPanelSuppliers.result.length > 0 &&
                  resultPanelSuppliers.result.map((el, index) => {
                    return (
                      <Grid item key={index} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Box className='PanelCustomPageBox'>
                          <PanelCustomPage
                            key={index}
                            title={el ? el.title : ''}
                            value={el ? el.value : ''}
                            type={el ? el.type : ''}
                            alert={el ? el.alert : ''}
                            anotation={el ? el.anotation : ''}
                          />
                        </Box>
                      </Grid>
                    )
                  })
                }
              </React.Fragment>
            }
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FiltersPage {...props} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <HomeUserSupplierTargetsAssociate {...props} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <HomeUserDocuments />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomeSupplierPage;
