import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import FiltersProductPage from '../HomeTargetProduct/Filters/index';
import HomeUserProduct from '../HomeTargetProduct/index';

import FiltersPage from '../HomeTargetsSupplier/Filters/index';
import HomeUserTargetsSupplier from '../HomeTargetsSupplier/index';
import HomeUserDocuments from '../HomeUserDocuments/index';

import { IHomeTargetsSupplierPage } from 'Module/HomeUser/Interface/HomeTargetsSupplier';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function HomeUserTabs(props: IHomeTargetsSupplierPage) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          indicatorColor="secondary"
          textColor="secondary"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Fornecedores" {...a11yProps(0)} />
          <Tab label="Produtos" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FiltersPage {...props} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <HomeUserTargetsSupplier {...props} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <HomeUserDocuments />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FiltersProductPage {...props} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <HomeUserProduct {...props} />
          </Grid>
        </Grid>
      </CustomTabPanel>
    </Box>
  );
}