import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { HOME } from 'Shared/Constant/Routes';
import { ILogoutPage } from '../interface/ILogout';

function LogoutPage(props: ILogoutPage) {
  const toggleLogout = (el: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log('toggleLogout');
    console.log(el);

    // eslint-disable-next-line react/destructuring-assignment
    props.setLogout();
  };

  return (
    <Box id="Logout" className="LogoutRoot">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="center">
            <h1>Deseja Realmente sair do portal?</h1>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography component="div" align="center">
                <Button
                  variant="contained"
                  color="inherit"
                  className="ButtonOK"
                  onClick={(e) => {
                    toggleLogout(e);
                  }}
                >
                  <span>SIM</span>
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography component="div" align="center">
                <Button
                  variant="contained"
                  color="error"
                  className="ButtonCancel"
                  href={HOME}
                >
                  <span>NÃO</span>
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LogoutPage;
