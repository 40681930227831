import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import { ISuppliers, ISuppliersContext, ISuppliersFilters, ISuppliersPage, ISuppliersResponse } from '../Interface';
import SuppliersPage from '../Page/index';
import { getSuppliersService } from '../Service/Get/index';
import { KEY_SUPPLIERS_SELECTED } from 'Shared/Constant/Cache';

function SuppliersController(props: ILayoutPage) {
  const [contextSuppliers] = React.useState<ISuppliersContext>({ result: [] });
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [filters, setFilters] = React.useState<ISuppliersFilters>({ codigo: '', nome: '', documento: '', active: 'Todos' });

  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'filters':
        setFilters(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  const [listSuppliers, setListSuppliers] = React.useState<[ISuppliers?]>([]);
  const toggleGetSuppliers = React.useCallback(async (filters: ISuppliersFilters) => {
    toggleChangeState('showLoadingThisPage', true);
    toggleChangeState('filters', filters)

    await getSuppliersService(filters)
      .then((response: ISuppliersResponse) => {
        setListSuppliers(response.list || []);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
      .catch((error: ISuppliersResponse) => {
        let layoutAlert: ILayout = {
          context: props.layout.context,
          titleMessage: 'Pesquisar registros',
          typeMessage: 'error',
          message: error.message,
          loading: false,
        };

        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSetSuppliers = (Suppliers: ISuppliers, action: string) => {

  };


  React.useEffect(() => {
    toggleGetSuppliers(filters);
  }, [filters, toggleGetSuppliers]);

  React.useEffect(() => {
    let _showModal = window.location.hash.includes('#Create') ? true : false;
    toggleChangeState('showNewLeadModal', _showModal);
    if (props.toggleDelCache) {
      props.toggleDelCache(KEY_SUPPLIERS_SELECTED)
    }
  }, [props])

  const handlersPage: ISuppliersPage = {
    ...props,
    context: contextSuppliers,
    listSuppliers,
    showLoadingThisPage,
    dateUpdateInfo,
    filters,
    itemSelected: {},
    toggleChangeState,
    toggleGetSuppliers,
    toggleSetSuppliers,
  };

  return (
    <SuppliersPage {...handlersPage} />
  );
}

export default SuppliersController;
