import {
  createBrowserRouter,
  RouterProvider,
}
  from 'react-router-dom';

import { ILayoutPage } from 'Module/Layout/Interface';
import {
  HOME,
  PROFILE,
  LOGOUT,
  SUPPLIERS,
  SUPPLIERDETAIL,
  ASSOCIATES,
  ASSOCIATEDETAIL,
  PRODUCTS,
  PRODUCTDETAIL,
  PRODUCTNEW,
  REPORTS,
  REPORTSDEFAULT,
  REPORTDETAIL,
  USERS,
  USERSDETAIL,
  USERSNEW
} from 'Shared/Constant/Routes';

import Layout from '../Module/Layout/Controller/index';
import Home from '../Module/Home/Controller/index';
import Profile from '../Module/Profile/Controller/index';
import Logout from '../Module/Logout/Controller/index';
import Supplier from '../Module/Suppliers/Controller/index';
import SupplierDetail from 'Module/SupplierDetail/Controller/index';
import Associates from 'Module/Associates/Controller/index';
import AssociateDetail from 'Module/AssociateDetail/Controller/index';
import Products from 'Module/Products/Controller/index';
import ProductDetail from 'Module/ProductDetail/Controller/index';
import ProductNew from 'Module/ProductNew/Controller/index';
import Reports from 'Module/Reports/Controller/index';
import ReportDetail from 'Module/ReportDetail/Controller/index';
import ReportsDefault from 'Module/ReportsDefault/Controller/index';
// import ReportDefaultDetail from 'Module//ReportDefaultDetail/Controller/index';
import Users from 'Module/Users/Controller/index';
import UserDetail from 'Module/UserDetail/Controller/index';
import UserNew from 'Module/UserNew/Controller/index';

import ErrorRoute from './Error';

function Router(props: ILayoutPage) {
  const router = createBrowserRouter(
    [
      {
        path: `${HOME}`,
        element: <Layout {...props} />,
        errorElement: <ErrorRoute />,
        children: [
          {
            path: `${HOME}`,
            element: <Home {...props} />,
          },
          {
            path: `${PROFILE}`,
            element: <Profile {...props} />,
          },
          {
            path: `${SUPPLIERS}`,
            element: <Supplier {...props} />,
          },
          {
            path: `${SUPPLIERDETAIL}`,
            element: <SupplierDetail {...props} />,
          },
          {
            path: `${ASSOCIATES}`,
            element: <Associates {...props} />,
          },
          {
            path: `${ASSOCIATEDETAIL}`,
            element: <AssociateDetail {...props} />,
          },
          {
            path: `${PRODUCTS}`,
            element: <Products {...props} />,
          },
          {
            path: `${PRODUCTDETAIL}`,
            element: <ProductDetail {...props} />,
          },
          {
            path: `${PRODUCTNEW}`,
            element: <ProductNew {...props} />,
          },
          {
            path: `${REPORTS}`,
            element: <Reports {...props} />,
          },
          {
            path: `${REPORTDETAIL}`,
            element: <ReportDetail {...props} />,
          },
          {
            path: `${REPORTSDEFAULT}`,
            element: <ReportsDefault {...props} />,
          },
          {
            path: `${USERS}`,
            element: <Users {...props} />,
          },
          {
            path: `${USERSDETAIL}`,
            element: <UserDetail {...props} />,
          },
          {
            path: `${USERSNEW}`,
            element: <UserNew {...props} />,
          },
          {
            path: `${LOGOUT}`,
            element: <Logout {...props} />,
          },
        ],
      },
    ],
  );

  return (
    <RouterProvider router={router} {...props} />
  );
}

export default Router;
