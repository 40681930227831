import * as React from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { ILayoutPage } from 'Module/Layout/Interface';

export default function TransitionAlerts(props: ILayoutPage) {
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState<AlertColor>('warning');

  const toggleClose = (value: boolean) => {
    setOpen(value);
    const layout = {
      loading: false,
      typeMessage: props?.layout.typeMessage,
      titleMessagz: '',
      message: '',
      context: props?.layout.context,
    };

    // eslint-disable-next-line react/destructuring-assignment
    props.toggleSetLayout(layout);
  };
  React.useEffect(() => {
    if (props?.layout.message) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    switch (props?.layout.typeMessage?.toUpperCase()) {
      case 'WARNING':
        setSeverity('warning');
        break;
      case 'SUCCESS':
        setSeverity('success');
        break;
      case 'ERROR':
        setSeverity('error');
        break;
      case 'INFO':
        setSeverity('info');
        break;
      default:
        break;
    }
  }, [props]);

  return (
    <Collapse id="AlertComponent" key="AlertComponent" in={open} className="AlertBox">
      <Alert
        severity={severity}
        action={(
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              toggleClose(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
        sx={{ mb: 0 }}
      >
        <span>
          {props?.layout.titleMessage}
          :
          {' '}
          {props?.layout.message}
        </span>
      </Alert>
    </Collapse>
  );
}
