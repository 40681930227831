import React from 'react';
import momenttz from 'moment-timezone';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'Store';

import { IProfile } from 'Module/Profile/Interface';
import { saveProfile } from 'Module/Profile/Redux';
import { saveLayout } from 'Module/Layout/Redux';
import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import { savePath } from 'Module/Home/Redux';
import Router from './Router/index';
import theme from './theme';
import Login from './Module/Login/Controller/index';
import * as LocalVariableUtil from 'Shared/Utils/LocalVariable/index';
import { IAdConfig } from 'Shared/Interfaces/AdConfig/iAdConfig';
import { KEY_ASSOCIATES_SELECTED, KEY_ASSOCIATESREPORT_LIST, KEY_ASSOCIATESREPORT_SELECTED, KEY_DATE_LOGIN, KEY_HOMETARGETSSUPPLIER_SELECTED, KEY_PRODUCTS_SELECTED, KEY_PROFILE, KEY_REPORTS_SELECTED, KEY_SUPPLIERS_SELECTED, KEY_SUPPLIERSREPORT_LIST, KEY_SUPPLIERSREPORT_SELECTED, KEY_TARGETPRODUCT_GOAL_ASSOCIATES, KEY_TARGETPRODUCT_GOAL_SUPPLIERS, KEY_TARGETPRODUCT_LIST, KEY_TARGETPRODUCT_SELECTED, KEY_TOKEN_API, KEY_USERS_LIST, KEY_USERS_SELECTED, KEY_REPORTSDEFAULT_SELECTED } from 'Shared/Constant/Cache';
import { IAssociates } from 'Module/Associates/Interface';
import { saveAssociates } from 'Module/Associates/Redux';
import { saveSuppliers } from 'Module/Suppliers/Redux';
import { ISuppliers } from 'Module/Suppliers/Interface';
import { IProducts } from 'Module/Products/Interface';
import { saveProducts } from 'Module/Products/Redux';
import { IReports } from 'Module/Reports/Interface';
import { saveReports } from 'Module/Reports/Redux';
import { IReportsDefault } from 'Module/ReportsDefault/Interface';
import { saveReportsDefault } from 'Module/ReportsDefault/Redux';
import { IAssociatesReport } from 'Module/AssociatesReport/Interface';
import { saveAssociatesReport, saveAssociatesReportList, saveTargetsSupplierSelected } from 'Module/AssociatesReport/Redux';
import { ISuppliersReport } from 'Module/SuppliersReport/Interface';
import { saveSuppliersReport, saveSuppliersReportList } from 'Module/SuppliersReport/Redux';
import { saveTargetProduct, saveTargetsProductGoalAssociates, saveTargetsProductGoalSuppliers, saveTargetsProductList } from 'Module/TargetsProduct/Redux';
import { ITargetsProduct, ITargetsProductFilters, ITargetsProductGoals, ITargetsProductResponse } from 'Module/TargetsProduct/Interface';
import { IHomeTargetsSupplier } from 'Module/HomeUser/Interface/HomeTargetsSupplier';
import { getTargetsIsMeta } from 'Module/TargetsProduct/Service/GetCountIsMeta/index';

import TypeGroupConstant from 'Shared/Constant/TypeGroup/index';
import TypePublicIP from 'Shared/Constant/TypePublicIP/index';
import GetIPUtil from 'Shared/Utils/GetIP/index';
import { IUsers } from 'Module/Users/Interface';
import { saveUsers } from 'Module/Users/Redux';

function App(props: IAdConfig) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const layoutStore = useAppSelector((state) => state.layout.layout);
  const profileStore = useAppSelector((state) => state.profile.profile);
  const associateStore = useAppSelector((state) => state.associates.associateSelected);
  const supplierStore = useAppSelector((state) => state.suppliers.supplierSelected);
  const productStore = useAppSelector((state) => state.products.productSelected);
  const reportStore = useAppSelector((state) => state.reports.reportSelected);
  const reportsDefaultStore = useAppSelector((state) => state.reportsdefault.reportsDefaultelected);
  const associatesReportStore = useAppSelector((state) => state.associatesreport.associatesReportSelected);
  const associatesReportListStore = useAppSelector((state) => state.associatesreport.associatesReportList);
  const suppliersReportStore = useAppSelector((state) => state.suppliersreport.suppliersReportSelected);
  const suppliersReportListStore = useAppSelector((state) => state.suppliersreport.suppliersReportList);
  const targetProductStore = useAppSelector((state) => state.targetsproduct.targetProductSelected);
  const targetsProductListStore = useAppSelector((state) => state.targetsproduct.targetsProductList);
  const targetsProductGoalAssociatesStore = useAppSelector((state) => state.targetsproduct.targetProductGoalAssociates);
  const targetsProductGoalSuppliersStore = useAppSelector((state) => state.targetsproduct.targetProductGoalSuppliers);
  const targetSupplierStore = useAppSelector((state) => state.associatesreport.targetsSupplierSelected);
  const userStore = useAppSelector((state) => state.user.userselected);

  const homeStore = useAppSelector((state) => state.home);
  const [pathName, setPathName] = React.useState('/');

  const dispatch = useAppDispatch();

  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const toggleDateUpdateInfo = () => {
    setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
  }

  const [dateUpdateInfoMix, setDateUpdateInfoMix] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const toggleDateUpdateInfoMix = () => {
    setDateUpdateInfoMix(momenttz().tz('America/Sao_Paulo').format());
  }

  const [dateUpdateInfoMixSupplier, setDateUpdateInfoMixSupplier] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const toggleDateUpdateInfoMixSupplier = () => {
    setDateUpdateInfoMixSupplier(momenttz().tz('America/Sao_Paulo').format());
  }

  const [publicIPInfo, setPublicIPInfo] = React.useState<string>();
  const togglePublicIPInfo = async (type: string) => {
    const _ip = await GetIPUtil(type)
    setPublicIPInfo(_ip);
  }
  const [typeGroup, setTypeGroup] = React.useState<string>('');
  const toggleTypeGroup = (value: string) => {
    setTypeGroup(value);
  }

  const [isTypeGroupEdit, setIsTypeGroupEdit] = React.useState<boolean>(false);
  const toggleIsTypeGroupEdit = (value: boolean) => {
    setIsTypeGroupEdit(value);
  }

  const toggleGetCache = React.useCallback((key: string) => {
    return LocalVariableUtil.get(key);
  }, []);

  const toggleSetCache = React.useCallback((key: string, value: any) => {
    return LocalVariableUtil.set(key, value);
  }, []);

  const toggleDelCache = React.useCallback((key: string) => {
    return LocalVariableUtil.remove(key);
  }, []);

  const toggleGetTargetProductGoalAssociate = async (filters: ITargetsProductFilters) => {
    toggleDelCache(KEY_TARGETPRODUCT_GOAL_ASSOCIATES);
    toggleShowLoadingGoalMix(true);
    await getTargetsIsMeta(filters)
      .then((response: ITargetsProductResponse) => {
        let _reg: ITargetsProductGoals = {
          list: [],
          totRegisters: response.totRegisters,
          qtdeCxFat: response.qtdeCxFat
        }

        let _arrayList: [string?] = []
        let _arrayListFat: [string?] = []
        if (response.list && response.list.length > 0) {
          for (let regL of response.list) {
            const _aux: ITargetsProduct | undefined = regL;
            const _validExist = _arrayList.find(elAL => elAL === _aux?.ean);
            if (!_validExist) {
              _arrayList.push(_aux?.ean);
            }
            const _validFatExist = _arrayListFat.find(elAL => elAL === _aux?.ean);
            const _qtdeFat = _aux && _aux.qtCxFaturada && Number(_aux.qtCxFaturada) > 0 ? 1 : 0;
            if (!_validFatExist && _qtdeFat > 0) {
              _arrayListFat.push(_aux?.ean);
            }

          }
        }
        _reg.totRegisters = _arrayList.length;
        _reg.qtdeCxFat = _arrayListFat.length;

        toggleSetCache(KEY_TARGETPRODUCT_GOAL_ASSOCIATES, _reg);
        dispatch(saveTargetsProductGoalAssociates(_reg));

        toggleDateUpdateInfo();
        toggleDateUpdateInfoMix();
        toggleShowLoadingGoalMix(false);
      })
      .catch((error: ITargetsProductResponse) => {
        console.log(error.message);
        let _reg: ITargetsProductGoals = {
          list: [],
          totRegisters: 0,
          qtdeCxFat: 0
        }

        toggleSetCache(KEY_TARGETPRODUCT_GOAL_ASSOCIATES, _reg);
        dispatch(saveTargetsProductGoalAssociates(_reg));

        toggleDateUpdateInfo();
        toggleDateUpdateInfoMix();
        toggleShowLoadingGoalMix(false);
      })
  }

  const toggleGetTargetProductGoalSupplier = async (filters: ITargetsProductFilters) => {
    toggleDelCache(KEY_TARGETPRODUCT_GOAL_SUPPLIERS);
    toggleShowLoadingGoalMix(true);
    await getTargetsIsMeta(filters)
      .then((response: ITargetsProductResponse) => {
        let _reg: ITargetsProductGoals = {
          list: [],
          totRegisters: response.totRegisters,
          qtdeCxFat: response.qtdeCxFat
        }
        toggleSetCache(KEY_TARGETPRODUCT_GOAL_SUPPLIERS, _reg);
        dispatch(saveTargetsProductGoalSuppliers(_reg));

        toggleDateUpdateInfo();
        toggleDateUpdateInfoMixSupplier();
        toggleShowLoadingGoalMix(false);
      })
      .catch((error: ITargetsProductResponse) => {
        console.log(error.message);
        let _reg: ITargetsProductGoals = {
          list: [],
          totRegisters: 0,
          qtdeCxFat: 0
        }
        toggleSetCache(KEY_TARGETPRODUCT_GOAL_SUPPLIERS, _reg);
        dispatch(saveTargetsProductGoalSuppliers(_reg));

        toggleDateUpdateInfo();
        toggleDateUpdateInfoMixSupplier();
        toggleShowLoadingGoalMix(false);
      })
  }

  const toggleresetAllSelected = React.useCallback((key: string) => {
    switch (key) {
      case 'All':
        toggleDelCache(KEY_HOMETARGETSSUPPLIER_SELECTED);
        toggleDelCache(KEY_ASSOCIATESREPORT_SELECTED);
        toggleDelCache(KEY_SUPPLIERSREPORT_SELECTED);
        toggleDelCache(KEY_TARGETPRODUCT_SELECTED);
        toggleDelCache(KEY_ASSOCIATES_SELECTED);
        toggleDelCache(KEY_USERS_SELECTED);
        toggleDelCache(KEY_SUPPLIERS_SELECTED);
        toggleDelCache(KEY_PRODUCTS_SELECTED);
        toggleDelCache(KEY_REPORTS_SELECTED);
        toggleDelCache(KEY_REPORTSDEFAULT_SELECTED);
        toggleDelCache(KEY_TARGETPRODUCT_GOAL_ASSOCIATES);
        toggleDelCache(KEY_TARGETPRODUCT_GOAL_SUPPLIERS);
        // toggleDelCache(KEY_DATE_LOGIN);
        break;
      default:
        break;
    }
  }, [toggleDelCache]);

  const toggleSetProfile = React.useCallback((profileParam: IProfile) => {
    toggleSetCache(KEY_PROFILE, profileParam)
    dispatch(saveProfile(profileParam));
    toggleDateUpdateInfo();
  }, [dispatch, toggleSetCache]);

  const toggleDelProfile = (profileParam: IProfile) => {
    toggleDelCache(KEY_PROFILE)
    toggleDelCache(KEY_TOKEN_API);
    dispatch(saveProfile(profileParam));
    toggleDateUpdateInfo();
  };

  const toggleSetLayout = (layoutParam: ILayout) => {
    dispatch(saveLayout(layoutParam));
    toggleDateUpdateInfo();
  };

  const toggleSetAssociateSelected = (associateParam: IAssociates) => {
    dispatch(saveAssociates(associateParam));
    toggleDateUpdateInfo();
  };

  const toggleSetUserselected = (userParam: IUsers) => {
    dispatch(saveUsers(userParam));
    toggleDateUpdateInfo();
  };


  const toggleSetSupplierSelected = (supplierParam: ISuppliers) => {
    dispatch(saveSuppliers(supplierParam));
    toggleDateUpdateInfo();
  };

  const toggleSetProductSelected = (productParam: IProducts) => {
    dispatch(saveProducts(productParam));
    toggleDateUpdateInfo();
  };

  const toggleSetReportSelected = (reportParam: IReports) => {
    dispatch(saveReports(reportParam));
    toggleDateUpdateInfo();
  };

  const toggleSetReportsDefaultSelected = (reportParam: IReportsDefault) => {
    dispatch(saveReportsDefault(reportParam));
    toggleDateUpdateInfo();
  };

  const toggleSetAssociatesReportSelected = (reportParam: IAssociatesReport) => {
    dispatch(saveAssociatesReport(reportParam));
    toggleDateUpdateInfo();
  };

  const toggleSetTargetSupplierSelected = (reportParam: IHomeTargetsSupplier) => {
    dispatch(saveTargetsSupplierSelected(reportParam));
    toggleDateUpdateInfo();
  };

  const toggleSetTargetProductSelected = (reportParam: ITargetsProduct) => {
    dispatch(saveTargetProduct(reportParam));
    toggleDateUpdateInfo();
  };

  const toggleSetAssociatesReportList = (reportParam: [IAssociatesReport?]) => {
    dispatch(saveAssociatesReportList(reportParam));
    toggleDateUpdateInfo();
  };

  const toggleSetTargetsProductList = (reportParam: [ITargetsProduct?]) => {
    dispatch(saveTargetsProductList(reportParam));
    toggleDateUpdateInfo();
  };

  const toggleSetSuppliersReportSelected = (reportParam: ISuppliersReport) => {
    dispatch(saveSuppliersReport(reportParam));
    toggleDateUpdateInfo();
  };

  const toggleSetSuppliersReportList = (reportParam: [ISuppliersReport?]) => {
    dispatch(saveSuppliersReportList(reportParam));
    toggleDateUpdateInfo();
  };

  const toggleSetHomePath = React.useCallback((pathname: string, hash: string) => {
    dispatch(savePath({ pathname, hash }));
    toggleDateUpdateInfo();
  }, [dispatch]);

  const [showModalImportFile, setShowModalImportFile] = React.useState<boolean>(false);
  const toggleShowModalImportFile = (value: boolean) => {
    setShowModalImportFile(value);
  }

  const [showLoadingGoalMix, setShowLoadingGoalMix] = React.useState<boolean>(false);
  const toggleShowLoadingGoalMix = (value: boolean) => {
    setShowLoadingGoalMix(value);
  }

  const [showModalChangePassword, setShowModalChangePassword] = React.useState<boolean>(false);
  const toggleShowModalChangePassword = (value: boolean) => {
    setShowModalChangePassword(value);
  }

  const [showModalResetPasswordUser, setShowModalResetPasswordUser] = React.useState<boolean>(false);
  const toggleShowModalResetPasswordUser = (value: boolean) => {
    setShowModalResetPasswordUser(value);
  }

  React.useEffect(() => {
    const profileLocal = LocalVariableUtil.get(KEY_PROFILE) ? LocalVariableUtil.get(KEY_PROFILE) : {};
    if (profileStore?.documento || profileLocal?.documento) {
      const _cargo = profileStore?.cargo ? profileStore?.cargo : profileLocal?.cargo ? profileLocal?.cargo : '';
      toggleTypeGroup(_cargo);
      toggleIsTypeGroupEdit(_cargo === TypeGroupConstant.ADMIN ? true : false);

      const _cacheDateLogin = toggleGetCache(KEY_DATE_LOGIN);
      const _datetoday = momenttz().tz('America/Sao_Paulo').format('YYYY-MM-DD');
      if (_cacheDateLogin && _cacheDateLogin === _datetoday) {
        setIsAuthenticated(true);
      }
      else {
        toggleresetAllSelected('All');
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [profileStore?.documento, profileStore?.cargo, toggleGetCache, toggleresetAllSelected]);

  React.useEffect(() => {
    toggleSetHomePath(window.location.pathname, window.location.hash);
    const profileLocal = LocalVariableUtil.get(KEY_PROFILE) ? LocalVariableUtil.get(KEY_PROFILE) : {};
    const profileParam: IProfile = {
      id: profileLocal?.id,
      nome: profileLocal?.nome,
      cargo: profileLocal?.cargo,
      email: profileLocal?.email,
      documento: profileLocal?.documento,
      userid: profileLocal?.userid
    };
    dispatch(saveProfile(profileParam));
  }, [dispatch, toggleSetHomePath]);

  React.useEffect(() => {
    togglePublicIPInfo(TypePublicIP.PUBLIC_IP_V4);
    setPathName(homeStore.pathname ? homeStore.pathname : '/');
  }, [homeStore.pathname]);

  const handlersPage: ILayoutPage = {
    profile: profileStore,
    layout: layoutStore,
    dateUpdateInfo,
    dateUpdateInfoMix,
    dateUpdateInfoMixSupplier,
    associateSelected: toggleGetCache(KEY_ASSOCIATES_SELECTED) ? toggleGetCache(KEY_ASSOCIATES_SELECTED) : associateStore,
    supplierSelected: toggleGetCache(KEY_SUPPLIERS_SELECTED) ? toggleGetCache(KEY_SUPPLIERS_SELECTED) : supplierStore,
    productSelected: toggleGetCache(KEY_PRODUCTS_SELECTED) ? toggleGetCache(KEY_PRODUCTS_SELECTED) : productStore,
    reportSelected: toggleGetCache(KEY_REPORTS_SELECTED) ? toggleGetCache(KEY_REPORTS_SELECTED) : reportStore,
    reportsDefaultSelected: toggleGetCache(KEY_REPORTSDEFAULT_SELECTED) ? toggleGetCache(KEY_REPORTSDEFAULT_SELECTED) : reportsDefaultStore,
    associatesReportSelected: toggleGetCache(KEY_ASSOCIATESREPORT_SELECTED) ? toggleGetCache(KEY_ASSOCIATESREPORT_SELECTED) : associatesReportStore,
    associatesReportListStore: toggleGetCache(KEY_ASSOCIATESREPORT_LIST) ? toggleGetCache(KEY_ASSOCIATESREPORT_LIST) : associatesReportListStore,
    suppliersReportSelected: toggleGetCache(KEY_SUPPLIERSREPORT_SELECTED) ? toggleGetCache(KEY_SUPPLIERSREPORT_SELECTED) : suppliersReportStore,
    suppliersReportListStore: toggleGetCache(KEY_SUPPLIERSREPORT_LIST) ? toggleGetCache(KEY_SUPPLIERSREPORT_LIST) : suppliersReportListStore,
    targetProductSelected: toggleGetCache(KEY_TARGETPRODUCT_SELECTED) ? toggleGetCache(KEY_TARGETPRODUCT_SELECTED) : targetProductStore,
    targetsProductListStore: toggleGetCache(KEY_TARGETPRODUCT_LIST) ? toggleGetCache(KEY_TARGETPRODUCT_LIST) : targetsProductListStore,
    usersListStore: toggleGetCache(KEY_USERS_LIST) ? toggleGetCache(KEY_USERS_LIST) : [],
    userSelected: toggleGetCache(KEY_USERS_SELECTED) ? toggleGetCache(KEY_USERS_SELECTED) : userStore,
    // targetsProductGoalAssociates: toggleGetCache(KEY_TARGETPRODUCT_GOAL_ASSOCIATES) ? toggleGetCache(KEY_TARGETPRODUCT_GOAL_ASSOCIATES) : targetsProductGoalAssociatesStore,
    // targetsProductGoalSuppliers: toggleGetCache(KEY_TARGETPRODUCT_GOAL_SUPPLIERS) ? toggleGetCache(KEY_TARGETPRODUCT_GOAL_SUPPLIERS) : targetsProductGoalSuppliersStore,
    targetsProductGoalAssociates: targetsProductGoalAssociatesStore,
    targetsProductGoalSuppliers: targetsProductGoalSuppliersStore,
    targetSupplierSelected: toggleGetCache(KEY_HOMETARGETSSUPPLIER_SELECTED) ? toggleGetCache(KEY_HOMETARGETSSUPPLIER_SELECTED) : targetSupplierStore,
    pathname: pathName,
    config: props,
    typeGroup,
    isTypeGroupEdit,
    publicIPInfo,
    showModalImportFile,
    showModalChangePassword,
    showModalResetPasswordUser,
    toggleSetLayout,
    toggleSetProfile,
    toggleDelProfile,
    toggleSetHomePath,
    toggleSetAssociateSelected,
    toggleSetSupplierSelected,
    toggleSetProductSelected,
    toggleSetReportSelected,
    toggleSetReportsDefaultSelected,
    toggleSetAssociatesReportSelected,
    toggleSetTargetSupplierSelected,
    toggleSetTargetProductSelected,
    toggleSetAssociatesReportList,
    toggleSetTargetsProductList,
    toggleSetSuppliersReportSelected,
    toggleSetSuppliersReportList,
    toggleShowModalImportFile,
    toggleShowModalChangePassword,
    toggleShowModalResetPasswordUser,
    toggleGetTargetProductGoalAssociate,
    toggleGetTargetProductGoalSupplier,
    toggleGetCache,
    toggleSetCache,
    toggleDelCache,
    toggleresetAllSelected,
    showLoadingGoalMix,
    toggleShowLoadingGoalMix,
    toggleSetUserselected,
  };

  return (
    <div className="AppMain" id="App" key="App" data-testid="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isAuthenticated
          ? <Router {...handlersPage} />
          : <Login {...handlersPage} />}
      </ThemeProvider>
    </div>
  );
}

export default App;
