
import {
  Box, Grid, Typography,
} from '@mui/material';

import { IUserNewPage } from '../../Interface';

import LogoUserTop from '../../../../Assets/lead_120.png';
import FieldPage from './_field';
function UserNewHeaderPage(props: IUserNewPage) {

  return (
    <Box id="UserNewHeaderPage" key="UserNewHeaderPage" className="UserNewHeaderRoot">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left" className='UserNewHeaderBox'>
            <Box className='UserNewHeaderImgLogoBox'>
              <img
                src={`${LogoUserTop}`}
                srcSet={`${LogoUserTop}`}
                alt="Logo User"
                loading="lazy"
                className='UserNewHeaderImgLogo'
              />
            </Box>
            <Box className='UserNewHeaderTitleBox'>
              <Typography component="div" align="left">
                <span className='UserNewHeaderSubTitle'>* Novo Usuário</span>
              </Typography>
              <Typography component="div" align="left">
                <span className='UserNewHeaderTitle'>{props.item.name}</span>
              </Typography>
            </Box>
            <Box className='UserNewHeaderButtonBox'>
              <Typography component="div" align="right">
              </Typography>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="center" className='UserNewHeaderInfoBox'>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <FieldPage label={'CPF/CNPJ'} value={props.item.documentnumber || ''} />
              </Grid>
              <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'Ativo'} value={props.item.active ? 'Sim' : 'Não'} />
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default UserNewHeaderPage;
