import { createTheme } from '@mui/material/styles';
import Colors from 'Shared/Constant/Colors';
import StringConstants from 'Shared/Constant/String';

const theme = createTheme({
  typography: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(StringConstants.COMMA),
  },
  palette: {
    primary: {
      main: Colors.PRIMARY,
      contrastText: Colors.WHITE100,
    },
    secondary: {
      main: Colors.SECONDARY,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
  },
});

export default theme;
