import {
  Box, Grid,
} from '@mui/material';

import HomeSupplierPage from './HomeSupplier/index';
import { IHomeTargetSupplierAssociatePage } from '../Interface/HomeTargetSupplierAssociate';

function HomePage(props: IHomeTargetSupplierAssociatePage) {
  return (
    <Box id="Home" key="Home" className="HomeRoot">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <HomeSupplierPage {...props} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomePage;
