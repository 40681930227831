import React from 'react';
import {
  Box, Typography,
} from '@mui/material';

interface IField {
  label: string;
  value: string;
}
function SupplierDetailHeaderFieldPage(props: IField) {

  return (
    <Box className='SupplierDetailHeaderFieldsBox'>
      <Typography component="div" align="left">
        <span className='SupplierDetailHeaderFieldsLabel'>{props.label}</span>
      </Typography>
      <Typography component="div" align="left">
        <span className='SupplierDetailHeaderFieldsText'>{props.value}</span>
      </Typography>
    </Box>
  )
}

export default React.memo(SupplierDetailHeaderFieldPage);
