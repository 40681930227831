import ConfigConstant from 'Shared/Constant/Config/index';

const getUrlApi = (environment?: string) => {
  let _url = '';
  switch (environment) {
    case ConfigConstant.ENVIRONMENT_LOCAL:
      _url = ConfigConstant.URL_API_LOCAL
      break;
    case ConfigConstant.ENVIRONMENT_DEV:
      _url = ConfigConstant.URL_API_DEV
      break;
    case ConfigConstant.ENVIRONMENT_HML:
      _url = ConfigConstant.URL_API_HML
      break;
    default:
      _url = ConfigConstant.URL_API_PRD
      break;
  }

  return _url;
};

export default getUrlApi;