import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import TypePublicIP from 'Shared/Constant/TypePublicIP/index';

async function getPublicIP(type: string): Promise<string> {
  try {
    switch (type) {
      case TypePublicIP.PUBLIC_IP_V4:
        return await publicIpv4();
      case TypePublicIP.PUBLIC_IP_V6:
        return await publicIpv6();
      default:
        return await publicIp();
    }
  } catch (error) {
    console.error('Failed to retrieve public IP:', error);
    return '';
  }
}


export default getPublicIP;

// console.log(await publicIp()); // Falls back to IPv4
// //=> 'fe80::200:f8ff:fe21:67cf'

// console.log(await publicIpv6());
// //=> 'fe80::200:f8ff:fe21:67cf'

// console.log(await publicIpv4());
// //=> '46.5.21.123'