import { makeStyles } from 'tss-react/mui-compat';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '99%',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'flex-start'
  },
  rootInput: {
    color: '#4d4d4d',
    padding: '1px',
    margin: '3px',
    borderRadius: '15px',
    fontSize: '0.8rem',
  },
  textField: {
    borderRadius: '15px',
    maxHeight: '38px',
    width: '98%',
    margin: '3px 5px 3px 5px',
    fontSize: '0.7rem',
    color: '#4d4d4d',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  labelField: {
    marginTop: 5,
    fontSize: '0.7rem',
    fontWeight: 'bold',
    color: '#4d4d4d',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  iconField: {
    color: '#4d4d4d',
    fontSize: '0.7rem',
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  labelBox: {
    marginLeft: 5,
  },
  labelBoxError: {
    marginLeft: 5,
    marginBottom: 5
  },
  labelFieldError: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: '#A50050',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  iconFieldError: {
    color: '#A50050',
    fontSize: '0.8rem',
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
}));

export { useStyles };