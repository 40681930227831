import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '99%',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'flex-start'
  },
  textField: {
    border: '1px solid rgb(0, 56, 101)',
    borderRadius: '15px',
    // background: '#B4E0E4',
    background: '#F4F4F4',
    width: '99%',
    padding: 5,
    margin: 5,
    fontSize: '0.8rem',
    color: '#4d4d4d',
    maxHeight: 38,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  labelField: {
    marginTop: 5,
    fontSize: '0.7rem',
    fontWeight: 'bold',
    color: '#4d4d4d',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  iconField: {
    color: '#4d4d4d',
    fontSize: '0.7rem',
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  labelBox: {
    marginLeft: 5,
  },
  labelBoxError: {
    marginLeft: 5,
    marginBottom: 5
  },
  labelFieldError: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: '#A50050',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  iconFieldError: {
    color: '#A50050',
    fontSize: '0.8rem',
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
}));


export { useStyles };