import { IFieldStructure } from "Shared/Interfaces/ObjectSalesforce";

const List: IFieldStructure[] = [
  { nameField: 'id', label: 'Id', group: 'Informações do Produto', viewFormMain: true, orderFormMain: 0, viewFormDetail: true, orderFormDetail: 12, edit: false, type: 'string', options: [], hiddenFormMain: true },
  { nameField: 'codigo', label: 'Código', group: 'Informações do Produto', viewFormMain: true, orderFormMain: 1, viewFormDetail: true, orderFormDetail: 2, edit: true, type: 'string', options: [], hiddenFormMain: false, width: "45px" },
  { nameField: 'nome', label: 'Nome Completo', group: 'Informações do Produto', viewFormMain: true, orderFormMain: 1, viewFormDetail: true, orderFormDetail: 2, edit: true, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'ean', label: 'EAN', group: 'Informações do Produto', viewFormMain: true, orderFormMain: 3, viewFormDetail: true, orderFormDetail: 3, edit: true, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'qtUnitCx', label: 'Unit. por caixa', group: 'Informações do Produto', viewFormMain: false, orderFormMain: 4, viewFormDetail: false, orderFormDetail: 3, edit: true, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'codFornecPrinc', label: 'Cod. Forn. Principal', group: 'Informações do Produto', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 5, edit: true, type: 'string', options: [], hiddenFormMain: false, width: "45px" },
  { nameField: 'codigoFornec', label: 'Código Fornecedor', group: 'Informações do Produto', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 6, edit: true, type: 'string', options: [], hiddenFormMain: false, width: "45px" },
  { nameField: 'documentFornec', label: 'CNPJ Fornecedor', group: 'Informações do Produto', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 7, edit: false, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'nomeFornec', label: 'Nome Fornecedor', group: 'Informações do Produto', viewFormMain: true, orderFormMain: 5, viewFormDetail: true, orderFormDetail: 8, edit: false, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'codProdutoPrinc', label: 'Cod. Prod. Principal', group: 'Informações do Produto', viewFormMain: true, orderFormMain: 6, viewFormDetail: true, orderFormDetail: 9, edit: true, type: 'string', options: [], hiddenFormMain: false, width: "45px" },
  { nameField: 'active', label: 'Ativo', group: 'Informações do Produto', viewFormMain: true, orderFormMain: 7, viewFormDetail: true, orderFormDetail: 1, edit: true, type: 'checkbox', options: [{ value: true, label: 'Sim' }, { value: false, label: 'Não' }], hiddenFormMain: false },
  { nameField: 'criadoEm', label: 'Criado em', group: 'Informações do Produto', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 10, edit: false, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'alteradoEm', label: 'Alterado em', group: 'Informações do Produto', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 11, edit: false, type: 'string', options: [], hiddenFormMain: false },
];

export default List;