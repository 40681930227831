import {
  Box, Grid,
} from '@mui/material';

import HomeAssociatePage from './HomeAssociate/index';
import { IHomeTargetsSupplierPage } from '../Interface/HomeTargetsSupplier';

function HomePage(props: IHomeTargetsSupplierPage) {
  return (
    <Box id="Home" key="Home" className="HomeRoot">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <HomeAssociatePage {...props} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomePage;
