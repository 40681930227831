import { useState, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import {
  Grid
  , Typography
  , Box
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimesCircle,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons'

export default function Input({ name, label, classCustom, handleChangeCustom, disabled, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [classInput, setClassInput] = useState('inputCustomDefault');

  useEffect(() => {
    if (classCustom) {
      setClassInput(classCustom);
    }
  }, [classCustom]);

  useEffect(() => {
    if (inputRef.current) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
      });
    }

  }, [fieldName, registerField]);

  return (
    <Box className='inpuRoot'>
      <Grid container>
        {label &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="div" align="left" className='inputLabelBox'>
              {!disabled &&
                <FontAwesomeIcon icon={faPencilAlt} className='inputIconField' />
              }
              <span className='inputLabelDefault'>&nbsp;{label}:</span>
            </Typography>
          </Grid>
        }
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <input
            ref={inputRef}
            defaultValue={defaultValue}
            disabled={disabled} {...rest}
            className={classInput}
            onChange={handleChangeCustom}
          />
          {error &&
            <Typography component="div" align="left" className='inputLabelBoxError'>
              <FontAwesomeIcon icon={faTimesCircle} className='inputIconFieldError' />
              <span className='inputLabelFieldError'>{error}</span>
            </Typography>
          }
        </Grid>
      </Grid>
    </Box>
  );
}
