import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import { IProducts, IProductsContext, IProductsFilters, IProductsPage, IProductsResponse } from '../Interface';
import ProductsPage from '../Page/index';
import { getProductsService } from '../Service/Get/index';
import { KEY_PRODUCTS_SELECTED } from 'Shared/Constant/Cache';
import { setProductTargetService } from '../Service/setProductTarget';

function ProductsController(props: ILayoutPage) {
  const [contextProducts] = React.useState<IProductsContext>({ result: [] });
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [filters, setFilters] = React.useState<IProductsFilters>({ codigo: '', nome: '', ean: '', active: 'Todos', nomeFornec: '' });

  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'filters':
        setFilters(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  const [listProducts, setListProducts] = React.useState<[IProducts?]>([]);
  const toggleGetProducts = React.useCallback(async (filters: IProductsFilters) => {
    toggleChangeState('showLoadingThisPage', true);
    toggleChangeState('filters', filters)

    await getProductsService(filters)
      .then((response: IProductsResponse) => {
        setListProducts(response.list || []);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
      .catch((error: IProductsResponse) => {
        let layoutAlert: ILayout = {
          context: props.layout.context,
          titleMessage: 'Pesquisar registros',
          typeMessage: 'error',
          message: error.message,
          loading: false,
        };

        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSetProducts = (products: IProducts, action: string) => {

  };

  const toggleProductCreateTarget = async () => {
    toggleChangeState('showLoadingThisPage', true);
    try {

      await setProductTargetService()
        .then((response: IProductsResponse) => {
          let layoutAlert: ILayout = {
            loading: false,
            typeMessage: 'success',
            titleMessage: 'Salvo',
            message: 'Registros alterados com sucesso',
          }
          props.toggleSetLayout(layoutAlert);
          toggleChangeState('showLoadingThisPage', false);
        })
        .catch((error: IProductsResponse) => {
          debugger //catch err

          let layoutAlert: ILayout = {
            loading: false,
            typeMessage: 'error',
            titleMessage: 'Erro importar',
            message: error.msgErro,
          }
          props.toggleSetLayout(layoutAlert);
          toggleChangeState('showLoadingThisPage', false);
        })
    }
    catch (err) {
      console.log(err);
      debugger //catch err

      let layoutAlert: ILayout = {
        loading: false,
        typeMessage: 'error',
        titleMessage: 'Erro importar',
        message: 'Ocorreu um erro!',
      }
      props.toggleSetLayout(layoutAlert);
      toggleChangeState('showLoadingThisPage', false);
    }
  }

  React.useEffect(() => {
    toggleGetProducts(filters);
  }, [filters, toggleGetProducts]);

  React.useEffect(() => {
    let _showModal = window.location.hash.includes('#Create') ? true : false;
    toggleChangeState('showNewLeadModal', _showModal);
    if (props.toggleDelCache) {
      props.toggleDelCache(KEY_PRODUCTS_SELECTED)
    }
  }, [props])

  const handlersPage: IProductsPage = {
    ...props,
    context: contextProducts,
    listProducts,
    showLoadingThisPage,
    dateUpdateInfo,
    filters,
    itemSelected: {},
    toggleChangeState,
    toggleGetProducts,
    toggleSetProducts,
    toggleProductCreateTarget,
  };

  return (
    <ProductsPage {...handlersPage} />
  );
}

export default ProductsController;
