import { ILayoutPage } from 'Module/Layout/Interface';
import React from 'react';
import HomePage from '../Page/index';
import { IHomePage, IHomeResultPanel } from '../Interface';
import { IPanelCustom } from 'Shared/Components/PanelCustom/Interface';
import PanelCustomConstant from 'Shared/Constant/PanelCustom/index';
import convertToNumber from 'Shared/Utils/ConvertToNumber';
import { KEY_TARGETPRODUCT_GOAL_ASSOCIATES, KEY_TARGETPRODUCT_GOAL_SUPPLIERS } from 'Shared/Constant/Cache';
import { ITargetsProductGoals } from 'Module/TargetsProduct/Interface';

function HomeController(props: ILayoutPage) {

  // Metas por Produto
  const [resultPanelAssociates, setResultPanelAssociates] = React.useState<IHomeResultPanel>({ result: [] })
  const toggleResultPanelAssociates = (values: IHomeResultPanel) => {
    setResultPanelAssociates(values)
  }

  const [resultPanelSuppliers, setResultPanelSuppliers] = React.useState<IHomeResultPanel>({ result: [] })
  const toggleResultPanelSuppliers = (values: IHomeResultPanel) => {
    setResultPanelSuppliers(values)
  }

  const [resultPanelProducts, setResultPanelProducts] = React.useState<IHomeResultPanel>({ result: [] })
  const toggleResultPanelProducts = (values: IHomeResultPanel) => {
    setResultPanelProducts(values)
  }

  React.useEffect(() => {
    const _arrayPanel: [IPanelCustom?] = [];
    let _totFaturamento: number = 0;
    let _totMetaFaturamento: number = 0;
    let _percentFaturamento: number = 0;
    let _totMix: number = 0;
    let _totMetaMix: number = 0;
    let _percentMix: number = 0;

    if (props.associatesReportListStore && props.associatesReportListStore.length > 0) {
      for (let reg of props.associatesReportListStore) {
        _totFaturamento += convertToNumber(reg?.faturamento);
        _totMetaFaturamento += convertToNumber(reg?.metaFaturamento);
        // _totMix += convertToNumber(reg?.mix);
        // _totMetaMix += convertToNumber(reg?.metaMix);
      }
    }

    let _infoMix: ITargetsProductGoals = {
      list: [],
      totRegisters: 0,
      qtdeCxFat: 0,
    };

    if (props.targetsProductGoalAssociates) {
      _infoMix.totRegisters = props.targetsProductGoalAssociates.totRegisters ? props.targetsProductGoalAssociates.totRegisters : 0;
      _infoMix.qtdeCxFat = props.targetsProductGoalAssociates.qtdeCxFat ? props.targetsProductGoalAssociates.qtdeCxFat : 0;

    }
    else {
      if (props.toggleGetCache) {
        const _regCache: any = props.toggleGetCache(KEY_TARGETPRODUCT_GOAL_ASSOCIATES);
        if (_regCache) {
          _infoMix.totRegisters = _regCache.totRegisters ? _regCache.totRegisters : 0;
          _infoMix.qtdeCxFat = _regCache.qtdeCxFat ? _regCache.qtdeCxFat : 0;
        }
      }
    }

    _totMetaMix = _infoMix.totRegisters || 0;
    _totMix = _infoMix.qtdeCxFat || 0;

    // Trata valores de Faturamento
    if (_totMetaFaturamento > 0) {
      _percentFaturamento = (_totFaturamento / _totMetaFaturamento) * 100;
    }

    _arrayPanel.push({
      title: 'Faturamento',
      value: isNaN(_totFaturamento) ? 'R$ 0,00' : `R$ ${_totFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `,
      type: PanelCustomConstant.TYPE_FAT,
      anotation: `Meta: R$ ${_totMetaFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br/> ( ${_percentFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}% )`,
      alert: _percentFaturamento >= 100 ? PanelCustomConstant.SUCCESS :
        _percentFaturamento >= 80 ? PanelCustomConstant.SUCCESS_LIGHT :
          _percentFaturamento >= 50 ? PanelCustomConstant.WARNING :
            _percentFaturamento >= 40 ? PanelCustomConstant.WARNING_LIGHT :
              _percentFaturamento >= 30 ? PanelCustomConstant.DANGER_LIGHT :
                PanelCustomConstant.DANGER,
    })

    // Trata valores de Mix
    if (_totMetaMix > 0) {
      _percentMix = (_totMix / _totMetaMix) * 100;
    }

    _arrayPanel.push({
      title: 'Mix',
      value: isNaN(_totMix) ? '0' : _totMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
      type: PanelCustomConstant.TYPE_MIX,
      anotation: `Meta: ${_totMetaMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 })} <br/> ( ${_percentMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 })}% )`,
      alert: _percentMix >= 100 ? PanelCustomConstant.SUCCESS :
        _percentMix >= 80 ? PanelCustomConstant.SUCCESS_LIGHT :
          _percentMix >= 50 ? PanelCustomConstant.WARNING :
            _percentMix >= 40 ? PanelCustomConstant.WARNING_LIGHT :
              _percentMix >= 30 ? PanelCustomConstant.DANGER_LIGHT :
                PanelCustomConstant.DANGER,
    })

    toggleResultPanelAssociates({ result: _arrayPanel });

    if (props.toggleresetAllSelected) {
      props.toggleresetAllSelected('All');
    }

  }, [props.associatesReportListStore, props.dateUpdateInfo, props.dateUpdateInfoMix, props.targetsProductGoalAssociates])//eslint-disable-line

  React.useEffect(() => {
    const _arrayPanel: [IPanelCustom?] = [];
    let _totFaturamento: number = 0;
    let _totMetaFaturamento: number = 0;
    let _percentFaturamento: number = 0;
    let _totMix: number = 0;
    let _totMetaMix: number = 0;
    let _percentMix: number = 0;

    if (props.suppliersReportListStore && props.suppliersReportListStore.length > 0) {
      for (let reg of props.suppliersReportListStore) {
        _totFaturamento += convertToNumber(reg?.faturamento);
        _totMetaFaturamento += convertToNumber(reg?.metaFaturamento);
        // _totMix += convertToNumber(reg?.mix);
        // _totMetaMix += convertToNumber(reg?.metaMix);
      }
    }

    let _infoMix: ITargetsProductGoals = {
      list: [],
      totRegisters: 0,
      qtdeCxFat: 0,
    };
    if (props.targetsProductGoalSuppliers) {
      _infoMix.totRegisters = props.targetsProductGoalSuppliers.totRegisters ? props.targetsProductGoalSuppliers.totRegisters : 0;
      _infoMix.qtdeCxFat = props.targetsProductGoalSuppliers.qtdeCxFat ? props.targetsProductGoalSuppliers.qtdeCxFat : 0;

    }
    else {
      if (props.toggleGetCache) {
        const _regCache: any = props.toggleGetCache(KEY_TARGETPRODUCT_GOAL_SUPPLIERS);
        if (_regCache) {
          _infoMix.totRegisters = _regCache.totRegisters ? _regCache.totRegisters : 0;
          _infoMix.qtdeCxFat = _regCache.qtdeCxFat ? _regCache.qtdeCxFat : 0;
        }
      }
    }

    _totMetaMix = _infoMix.totRegisters || 0;
    _totMix = _infoMix.qtdeCxFat || 0;

    // Trata valores de Faturamento
    if (_totMetaFaturamento > 0) {
      _percentFaturamento = (_totFaturamento / _totMetaFaturamento) * 100;
    }

    _arrayPanel.push({
      title: 'Faturamento',
      value: isNaN(_totFaturamento) ? 'R$ 0,00' : `R$ ${_totFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `,
      type: PanelCustomConstant.TYPE_FAT,
      anotation: `Meta: R$ ${_totMetaFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br/> ( ${_percentFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}% )`,
      alert: _percentFaturamento >= 100 ? PanelCustomConstant.SUCCESS :
        _percentFaturamento >= 80 ? PanelCustomConstant.SUCCESS_LIGHT :
          _percentFaturamento >= 50 ? PanelCustomConstant.WARNING :
            _percentFaturamento >= 40 ? PanelCustomConstant.WARNING_LIGHT :
              _percentFaturamento >= 30 ? PanelCustomConstant.DANGER_LIGHT :
                PanelCustomConstant.DANGER,
    })

    // Trata valores de Mix
    if (_totMetaMix > 0) {
      _percentMix = (_totMix / _totMetaMix) * 100;
    }

    _arrayPanel.push({
      title: 'Mix',
      value: isNaN(_totMix) ? '0' : _totMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
      type: PanelCustomConstant.TYPE_MIX,
      anotation: `Meta: ${_totMetaMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 })} <br/> ( ${_percentMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 })}% )`,
      alert: _percentMix >= 100 ? PanelCustomConstant.SUCCESS :
        _percentMix >= 80 ? PanelCustomConstant.SUCCESS_LIGHT :
          _percentMix >= 50 ? PanelCustomConstant.WARNING :
            _percentMix >= 40 ? PanelCustomConstant.WARNING_LIGHT :
              _percentMix >= 30 ? PanelCustomConstant.DANGER_LIGHT :
                PanelCustomConstant.DANGER,
    })

    toggleResultPanelSuppliers({ result: _arrayPanel });
  }, [props.suppliersReportListStore, props.dateUpdateInfo, props.dateUpdateInfoMixSupplier, props.targetsProductGoalSuppliers])//eslint-disable-line

  React.useEffect(() => {
    const _arrayPanel: [IPanelCustom?] = [];
    let _totFaturamento: number = 0;
    let _totMetaFaturamento: number = 0;
    let _percentFaturamento: number = 0;
    let _totMix: number = 0;
    let _totMetaMix: number = 0;
    let _percentMix: number = 0;

    if (props.targetsProductListStore && props.targetsProductListStore.length > 0) {
      for (let reg of props.targetsProductListStore) {
        _totFaturamento += convertToNumber(reg?.faturamento);
        _totMetaFaturamento += convertToNumber(reg?.metaFaturamento);
        _totMix += convertToNumber(reg?.mix);
        _totMetaMix += convertToNumber(reg?.metaMix);
      }
    }

    // Trata valores de Faturamento
    if (_totMetaFaturamento > 0) {
      _percentFaturamento = (_totFaturamento / _totMetaFaturamento) * 100;
    }

    _arrayPanel.push({
      title: 'Faturamento',
      value: isNaN(_totFaturamento) ? 'R$ 0,00' : `R$ ${_totFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `,
      type: PanelCustomConstant.TYPE_FAT,
      anotation: `Meta: R$ ${_totMetaFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br/> ( ${_percentFaturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}% )`,
      alert: _percentFaturamento >= 100 ? PanelCustomConstant.SUCCESS :
        _percentFaturamento >= 80 ? PanelCustomConstant.SUCCESS_LIGHT :
          _percentFaturamento >= 50 ? PanelCustomConstant.WARNING :
            _percentFaturamento >= 40 ? PanelCustomConstant.WARNING_LIGHT :
              _percentFaturamento >= 30 ? PanelCustomConstant.DANGER_LIGHT :
                PanelCustomConstant.DANGER,
    })

    // Trata valores de Mix
    if (_totMetaMix > 0) {
      _percentMix = (_totMix / _totMetaMix) * 100;
    }

    _arrayPanel.push({
      title: 'Mix',
      value: isNaN(_totMix) ? '0' : _totMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
      type: PanelCustomConstant.TYPE_MIX,
      anotation: `Meta: ${_totMetaMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 })} <br/> ( ${_percentMix.toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 2 })}% )`,
      alert: _percentMix >= 100 ? PanelCustomConstant.SUCCESS :
        _percentMix >= 80 ? PanelCustomConstant.SUCCESS_LIGHT :
          _percentMix >= 50 ? PanelCustomConstant.WARNING :
            _percentMix >= 40 ? PanelCustomConstant.WARNING_LIGHT :
              _percentMix >= 30 ? PanelCustomConstant.DANGER_LIGHT :
                PanelCustomConstant.DANGER,
    })

    toggleResultPanelProducts({ result: _arrayPanel });
  }, [props.targetsProductListStore, props.dateUpdateInfo])


  const handlersPage: IHomePage = {
    ...props,
    resultPanelAssociates,
    resultPanelSuppliers,
    resultPanelProducts
  };

  return (
    <HomePage {...handlersPage} />
  );
}

export default HomeController;
