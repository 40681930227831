import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IReportsDefault } from '../Interface/index';

interface ReportsDefaultState {
  reportsDefaultelected: IReportsDefault,
}

const initialState: ReportsDefaultState = {
  reportsDefaultelected: {
    id: '',
    nomeProduto: '',
  },
};

export const ReportsDefaultSlice = createSlice({
  name: 'ReportsDefault',
  initialState,
  reducers: {
    saveReportsDefault: (state, action: PayloadAction<IReportsDefault>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.reportsDefaultelected = payload;
    },
  },
});

export default ReportsDefaultSlice.reducer;
export const { saveReportsDefault } = ReportsDefaultSlice.actions;
