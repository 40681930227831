import React from 'react';
import { ILayoutPage } from '../Interface';
import LayoutPage from '../Page/index';

function LayoutController(props: ILayoutPage) {

  return (
    <LayoutPage {...props} />
  );
}
export default LayoutController;
