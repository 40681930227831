import { ILayoutPage } from 'Module/Layout/Interface';
import React from 'react';
import { HOME } from 'Shared/Constant/Routes';
import { ILogin, ILoginResponse } from '../Interface/index';
import LoginPage from '../Page/index';
import packageJson from '../../../../package.json';
import TypeGroupConstant from 'Shared/Constant/TypeGroup/index';
import * as SigninTokenService from '../Service/SigninToken/index';
import * as LocalVariableUtil from 'Shared/Utils/LocalVariable/index';
import { KEY_DATE_LOGIN, KEY_LOGIN } from 'Shared/Constant/Cache';
import momenttz from 'moment-timezone';

function LoginController(props: ILayoutPage) {
  const [version, setVersion] = React.useState<string>('');

  const toggleLogin = (params: ILogin) => {
    // eslint-disable-next-line react/destructuring-assignment
    props.toggleSetLayout({
      loading: true,
      message: props?.layout.message,
      typeMessage: props?.layout.typeMessage,
      titleMessage: props?.layout.titleMessage,
    });

    let isFilter = true;
    let msgReturn = '';

    if (!params.login) {
      isFilter = false;
      msgReturn += 'Login não informado! ';
    }
    if (!params.password) {
      isFilter = false;
      msgReturn += 'Senha não informada! ';
    }

    if (!isFilter) {
      setTimeout(() => {
        // eslint-disable-next-line react/destructuring-assignment
        props.toggleSetLayout({
          loading: false,
          message: msgReturn,
          typeMessage: 'error',
          titleMessage: 'Login',
        });
      }, 1000);
    }
    else {
      SigninTokenService.auth(params)
        .then((response: ILoginResponse) => {
          msgReturn = 'Logado com sucesso!';

          const namecomplete: string = response?.user?.name || '';
          let cargo: string = '';

          switch (response?.user?.department) {
            case TypeGroupConstant.SUPPLIER:
              cargo = TypeGroupConstant.SUPPLIER;
              break;
            case TypeGroupConstant.ADMIN:
              cargo = TypeGroupConstant.ADMIN;
              break;
            case TypeGroupConstant.CONSULT:
              cargo = TypeGroupConstant.CONSULT;
              break;
            default:
              cargo = TypeGroupConstant.USER;
              break;
          }

          // eslint-disable-next-line react/destructuring-assignment
          props.toggleSetProfile({
            id: response?.user?.guidcontrol,
            nome: namecomplete,
            cargo: cargo,
            documento: response?.user?.documentnumber,
            email: response?.user?.email,
            userid: response?.user?.userid
          });

          // eslint-disable-next-line react/destructuring-assignment
          props.toggleSetLayout({
            loading: false,
            message: msgReturn,
            typeMessage: 'success',
            titleMessage: 'Login',
          });

          LocalVariableUtil.set(KEY_LOGIN, response.user);
          LocalVariableUtil.set(KEY_DATE_LOGIN, momenttz().tz('America/Sao_Paulo').format('YYYY-MM-DD'));
          window.location.href = HOME;
        })
        .catch((reject: ILoginResponse) => {
          setTimeout(() => {
            // eslint-disable-next-line react/destructuring-assignment
            props.toggleSetLayout({
              loading: false,
              message: reject.message,
              typeMessage: 'error',
              titleMessage: 'Login',
            });
          }, 1000);
        })
    }
  };

  React.useEffect(() => {
    setVersion(packageJson.version ? packageJson.version : '1.0.0');
  }, []);

  const handlersPage = {
    ...props,
    context: { version },
    toggleLogin,
  };

  return (
    <LoginPage {...handlersPage} />
  );
}

export default LoginController;
