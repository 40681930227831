import React from 'react';
import momenttz from 'moment-timezone';

import {
  Box, Button, Grid, Typography,
  IconButton,
  InputLabel,
} from '@mui/material';

import RemoveRedEye from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Input, Form } from '@rocketseat/unform';

import * as Yup from 'yup';

import { ILayoutPage } from 'Module/Layout/Interface';
import { ILogin, ILoginPage } from '../Interface';
import AlertCustom from 'Shared/Components/Alert/index';
import LoadingCustom from 'Shared/Components/Loading/index';
import Logo from 'Assets/logologin.png';

function LoginPage(props: ILoginPage) {
  const [typePass, setTypepass] = React.useState('password');
  const toggleTypePass = (value: string) => {
    const newValue = value === 'password' ? 'text' : 'password';

    setTypepass(newValue);
  };
  const toggleLogin = (loginParam: ILogin) => {
    // eslint-disable-next-line react/destructuring-assignment
    props.toggleLogin(loginParam);
  };

  const schema = Yup.object().shape({
    login: Yup.string().required('Login não informado!'),
    password: Yup.string().required('Senha não informada!').min(5, 'Senha com mínimo de 5 dígitos!'),
  });

  const handleSubmit = (data: any) => {
    const param: ILogin = {
      login: data?.login,
      password: data?.password,
    };
    toggleLogin(param);
  };

  const handlerAlert: ILayoutPage = {
    layout: props?.layout,
    profile: props?.profile,
    dateUpdateInfo: momenttz().tz('America/Sao_Paulo').format(),
    toggleSetLayout: props?.toggleSetLayout,
    toggleSetProfile: props?.toggleSetProfile,
    toggleSetHomePath: props?.toggleSetHomePath,
    toggleDelProfile: props?.toggleDelProfile,
    toggleSetAssociateSelected: props?.toggleSetAssociateSelected,
    toggleSetSupplierSelected: props?.toggleSetSupplierSelected,
    toggleSetProductSelected: props?.toggleSetProductSelected,
    toggleSetReportSelected: props?.toggleSetReportSelected,
    toggleSetReportsDefaultSelected: props?.toggleSetReportsDefaultSelected,
    toggleSetAssociatesReportSelected: props?.toggleSetAssociatesReportSelected,
    toggleSetTargetSupplierSelected: props?.toggleSetTargetSupplierSelected,
    toggleSetAssociatesReportList: props?.toggleSetAssociatesReportList,
    toggleSetTargetProductSelected: props?.toggleSetTargetProductSelected,
    toggleSetTargetsProductList: props?.toggleSetTargetsProductList,
    toggleSetSuppliersReportSelected: props?.toggleSetSuppliersReportSelected,
    toggleSetSuppliersReportList: props?.toggleSetSuppliersReportList,
  };

  return (
    <Box id="BGLogin" key="LoginPage" className="LoginRoot">
      <AlertCustom {...handlerAlert} />
      <LoadingCustom {...props?.layout} />
      <Box id="LoginForm" key="LoginForm" className="LoginForm">
        <Form
          key="formLogin"
          name="formLogin"
          schema={schema}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <img
                src={`${Logo}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${Logo}?w=164&h=164&fit=crop&auto=format&dpr=2 1x`}
                alt="Logo"
                loading="lazy"
                className='LoginLogo'
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography component="div" align="center">
                <span className="LoginTitle">Login</span>
              </Typography>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography component="div" align="left">
                    <Box className="LoginFormRow">
                      <Input name="login" label="Login:&nbsp;" type="text" className="LoginFormInput" />
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography component="div" align="left">
                    <Box className="LoginFormRow">
                      <Input name="password" label="Senha:&nbsp;" type={`${typePass}`} className="LoginFormInputPassword" />
                      <IconButton
                        aria-label="removeRedeye"
                        color="secondary"
                        onClick={() => {
                          toggleTypePass(typePass);
                        }}
                      >
                        {typePass === 'password'
                          ? <RemoveRedEye />
                          : <VisibilityOffIcon />}
                      </IconButton>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography component="div" align="center">
                    <Button
                      variant="contained"
                      color="success"
                      className="LoginButtonOK"
                      type="submit"
                    >
                      <span>ENTRAR</span>
                    </Button>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box className="LoginFormRow">
                    <Typography component="div" align="center">
                      <InputLabel>
                        <span className='LoginTextVersion'>versão: &nbsp;</span>
                        <span className='LoginTextVersion'>{props?.context.version}</span>
                      </InputLabel>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Box>
    </Box >
  );
}

export default LoginPage;
