import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import { Form } from '@unform/web';
import { IHomeTargetsSupplierFilters, IHomeTargetsSupplierPage } from 'Module/HomeUser/Interface/HomeTargetsSupplier';
import { ITargetsProductFilters } from 'Module/TargetsProduct/Interface';
import React from 'react';
import InputCustom from 'Shared/Components/Form/InputReactCustom';

function HomeTargetsSupplierFormPage(props: IHomeTargetsSupplierPage) {
  const formDefault = React.useRef<any>(null);

  const [initialData, setInitialData] = React.useState<IHomeTargetsSupplierFilters>({
    codigo: props.filtersProduct.codigo,
    nome: props.filtersProduct.nome,
    documento: props.filtersProduct.documento,
    nomeFornec: props.filtersProduct.nomeFornec,
    codClientePrinc: props.filtersProduct.codClientePrinc,
  });

  const handleCleanFilters = () => {
    const _filters: IHomeTargetsSupplierFilters = {
      codigo: props.filtersProduct.codigo,
      nome: props.filtersProduct.nome,
      documento: props.filtersProduct.documento,
      codClientePrinc: props.filtersProduct.codClientePrinc,
      nomeFornec: ''
    };
    props.toggleChangeState('filtersProduct', _filters);
    handleSubmit({})
  }

  const handleSubmit = (data: any) => {
    if (props.toggleGetTargetsProduct) {
      let _codigo = props.filters.codigo ? props.filters.codigo : props.filters.codClientePrinc;

      let _filtersTarget: ITargetsProductFilters = {};
      if (data.nomeFornec) {
        _filtersTarget.nomeFornec = data.nomeFornec.toUpperCase();
      }
      if (props.filters.codClientePrinc) {
        _filtersTarget.codClientePrinc = props.filters.codClientePrinc;
      }
      else {
        const _findName = props.listAssociates.find(elFind => elFind?.codigo === _codigo);
        if (_findName) {
          if (props.filters.codClientePrinc) {
            _filtersTarget.codClientePrinc = props.filters.codClientePrinc;
          }
          else {
            _filtersTarget.nome = _findName.nome;
          }
        }
      }

      if (_filtersTarget.codClientePrinc || _filtersTarget.nome) {
        props.toggleGetTargetsProduct(_filtersTarget, 'Geral');
      }
    }
  };

  React.useEffect(() => {
    setInitialData(props.filtersProduct);
    if (formDefault.current) {
      formDefault.current.setFieldValue('codigo', props.filtersProduct.codigo || '');
      formDefault.current.setFieldValue('documento', props.filtersProduct.documento || '');
      formDefault.current.setFieldValue('nomeFornec', props.filtersProduct.nomeFornec || '');
    }
  }, [props.filtersProduct, props.dateUpdateInfo])

  return (
    <Box id="AssociatesReport" key="AssociatesReport" className="FormRoot" data-label={props.dateUpdateInfo}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="justify">
            <Box id="AssociatesReportFilterForm" key="AssociatesReportFilterForm">
              <Form
                ref={formDefault}
                id="myFormDefault"
                name="myFormDefault"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.nomeFornec}
                        id="nomeFornec"
                        name="nomeFornec"
                        label="Nome Fornecedor"
                        type="text"
                      />
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                    <Typography component="div" align="right">
                      <Button
                        variant="contained"
                        color="success"
                        className="ButtonPrimary"
                        type="submit"
                      >
                        <span>Pesquisar</span>
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                    <Typography component="div" align="right">
                      <Button
                        variant="contained"
                        color="success"
                        className="ButtonDefault"
                        type="button"
                        onClick={handleCleanFilters}
                      >
                        <span>Limpar filtros</span>
                      </Button>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        hidden={true}
                        handleChangeCustom={() => { }}
                        disabled={true}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.documento}
                        id="documento"
                        name="documento"
                        label=""
                        type="text"
                      />

                      <InputCustom
                        autoComplete="off"
                        hidden={true}
                        handleChangeCustom={() => { }}
                        disabled={true}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.codigo}
                        id="codigo"
                        name="codigo"
                        label=""
                        type="text" />

                      <InputCustom
                        autoComplete="off"
                        hidden={true}
                        handleChangeCustom={() => { }}
                        disabled={true}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.codClientePrinc}
                        id="codClientePrinc"
                        name="codClientePrinc"
                        label=""
                        type="text" />
                      <InputCustom
                        autoComplete="off"
                        hidden={true}
                        handleChangeCustom={() => { }}
                        disabled={true}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.nome}
                        id="nome"
                        name="nome"
                        label=""
                        type="text" />
                    </Typography>
                  </Grid>

                </Grid>
                <span>&nbsp;</span>
              </Form>
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomeTargetsSupplierFormPage;
