import axios from 'axios';
import GetTokenAPIUtil from 'Shared/Utils/ConfigEnvironment/GetTokenAPI';
import GetUrlApiUtil from 'Shared/Utils/ConfigEnvironment/GetUrlApi/index';

const api = axios.create({
  baseURL: GetUrlApiUtil(process.env.REACT_APP_ENVIRONMENT)
});


api.interceptors.request.use(async config => {
  const token = GetTokenAPIUtil();
  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }
  return config;
})

export default api;