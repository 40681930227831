import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILocation } from '../Interface';

interface HomeState {
  pathname?: string,
  hash?: string,
}

const initialState: HomeState = {
  pathname: '/',
  hash: '',
};

export const HomeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    savePath: (state, action: PayloadAction<ILocation>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.pathname = payload.pathname;
      state.hash = payload.hash;
    },
  },
});

export default HomeSlice.reducer;
export const { savePath } = HomeSlice.actions;
