const convertToNumber = (stringInput?: string) => {
  const cleanedString = stringInput ? `${stringInput}`.replace(/\./g, '').replace(/,/g, '.') : '';
  const number = Number(cleanedString);
  if (!isNaN(number)) {
    return number;
  }
  else {
    debugger // convertToNumber 01
    return 0;
  }
};

export default convertToNumber;