import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import { Form } from '@unform/web';
import InputCustom from 'Shared/Components/Form/InputReactCustom';

// import * as Yup from 'yup';
import { IUsersFilters, IUsersPage } from '../../Interface';
import React from 'react';

function UsersFilterFormPage(props: IUsersPage) {
  const formDefault = React.useRef<any>(null);

  const [initialData, setInitialData] = React.useState<IUsersFilters>({
    rcacode: props.filters.rcacode,
    name: props.filters.name,
    documentnumber: props.filters.documentnumber,
    active: 'Todos'
  });

  const handleCleanFilters = () => {
    const _filters: IUsersFilters = { rcacode: '', name: '', documentnumber: '', active: props.filters.active };
    props.toggleChangeState('filters', _filters);
  }

  const handleSubmit = (data: any) => {
    const _filters: IUsersFilters = {
      rcacode: data.rcacode,
      name: `${data.name}`.toUpperCase().trim(),
      documentnumber: `${data.documentnumber}`.toUpperCase().trim(),
      active: data.active,
    };
    props.toggleGetUsers(_filters);
  };

  React.useEffect(() => {
    setInitialData(props.filters);
    if (formDefault.current) {
      formDefault.current.setFieldValue('rcacode', props.filters.rcacode);
      formDefault.current.setFieldValue('name', props.filters.name);
      formDefault.current.setFieldValue('documentnumber', props.filters.documentnumber);
      formDefault.current.setFieldValue('active', props.filters.active);
    }
  }, [props.filters, props.dateUpdateInfo])

  return (
    <Box id="Users" key="Users" className="FormRoot" data-label={props.dateUpdateInfo}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="justify">
            <Box id="AcountFilterForm" key="AcountFilterForm">
              <Form
                ref={formDefault}
                id="myFormDefault"
                name="myFormDefault"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={3} md={3} lg={2} xl={2}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.rcacode}
                        id="rcacode"
                        name="rcacode"
                        label="Código"
                        type="text" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.name}
                        id="name"
                        name="name"
                        label="Nome"
                        type="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} md={4} lg={4} xl={4}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.documentnumber}
                        id="documentnumber"
                        name="documentnumber"
                        label="Documento"
                        type="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={2} md={2} lg={2} xl={2}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={true}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.active}
                        id="active"
                        name="active"
                        label="Ativo"
                        type="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="right">
                      <Button
                        variant="contained"
                        color="success"
                        className="ButtonPrimary"
                        type="submit"
                      >
                        <span>Pesquisar</span>
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="right">
                      <Button
                        variant="contained"
                        color="success"
                        className="ButtonDefault"
                        type="button"
                        onClick={handleCleanFilters}
                      >
                        <span>Limpar filtros</span>
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
                <span>&nbsp;</span>
              </Form>
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default UsersFilterFormPage;
