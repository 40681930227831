import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITargetsProduct, ITargetsProductGoals } from '../Interface/index';

interface TargetsProductState {
  targetProductSelected: ITargetsProduct,
  targetsProductList: [ITargetsProduct?],
  targetProductGoalAssociates: ITargetsProductGoals,
  targetProductGoalSuppliers: ITargetsProductGoals,
}

const initialState: TargetsProductState = {
  targetProductSelected: {
    id: '',
    nome: '',
  },
  targetsProductList: [],
  targetProductGoalAssociates: {
    list: [],
    totRegisters: 0,
    qtdeCxFat: 0
  },
  targetProductGoalSuppliers: {
    list: [],
    totRegisters: 0,
    qtdeCxFat: 0
  }
};

export const TargetsProductSlice = createSlice({
  name: 'TargetsProduct',
  initialState,
  reducers: {
    saveTargetProduct: (state, action: PayloadAction<ITargetsProduct>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.targetProductSelected = payload;
    },
    saveTargetsProductList: (state, action: PayloadAction<[ITargetsProduct?]>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.targetsProductList = payload;
    },
    saveTargetsProductGoalAssociates: (state, action: PayloadAction<ITargetsProductGoals>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.targetProductGoalAssociates = payload;
    },
    saveTargetsProductGoalSuppliers: (state, action: PayloadAction<ITargetsProductGoals>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.targetProductGoalSuppliers = payload;
    },
  },
});

export default TargetsProductSlice.reducer;
export const { saveTargetProduct,
  saveTargetsProductList,
  saveTargetsProductGoalAssociates,
  saveTargetsProductGoalSuppliers
} = TargetsProductSlice.actions;
