import React from 'react';
import {
  Box, Typography,
} from '@mui/material';

interface IField {
  label: string;
  value: string;
}
function ReportDetailHeaderFieldPage(props: IField) {

  return (
    <Box className='ReportDetailHeaderFieldsBox'>
      <Typography component="div" align="left">
        <span className='ReportDetailHeaderFieldsLabel'>{props.label}</span>
      </Typography>
      <Typography component="div" align="left">
        <span className='ReportDetailHeaderFieldsText'>{props.value}</span>
      </Typography>
    </Box>
  )
}

export default React.memo(ReportDetailHeaderFieldPage);
