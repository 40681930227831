import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISuppliersReport } from '../Interface/index';

interface SuppliersReportState {
  suppliersReportSelected: ISuppliersReport,
  suppliersReportList: [ISuppliersReport?]
}

const initialState: SuppliersReportState = {
  suppliersReportSelected: {
    id: '',
    nome: '',
  },
  suppliersReportList: []
};

export const SuppliersReportSlice = createSlice({
  name: 'SuppliersReport',
  initialState,
  reducers: {
    saveSuppliersReport: (state, action: PayloadAction<ISuppliersReport>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.suppliersReportSelected = payload;
    },
    saveSuppliersReportList: (state, action: PayloadAction<[ISuppliersReport?]>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.suppliersReportList = payload;
    },
  },
});

export default SuppliersReportSlice.reducer;
export const { saveSuppliersReport, saveSuppliersReportList } = SuppliersReportSlice.actions;
