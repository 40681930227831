const Colors = {
  PRIMARY: '#dc3545',
  // SECONDARY: '#003560',
  SECONDARY: '#28a745',
  WHITE100: '#ffffff',
  GRAY: '#F9FAFE',
  GRAY90: '#B9B9B9',
  DARK_BLUE: '#212D3F',
  GREEN90: '#BBCD33',
};

export default Colors;
