import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';

import { IUsers, IUsersFilters, IUsersResponse } from '../../Interface/index';

const validFilters = (element: IUsers, filters: IUsersFilters) => {
  let isFilter = true
  if (filters.active && filters.active !== 'Todos' && element?.active?.toString() !== filters.active) {
    isFilter = false
  }
  if (filters.rcacode && element.rcacode !== filters.rcacode) {
    isFilter = false
  }
  if (filters.documentnumber && element.documentnumber !== filters.documentnumber) {
    isFilter = false
  }
  if (filters.name && !element.name?.includes(filters.name)) {
    isFilter = false
  }
  return isFilter;
}


export const getUsersService = async (params: IUsersFilters) => {
  return new Promise<IUsersResponse>(async (resolve, reject) => {
    let _arrayReg: [IUsers?] = [];
    let regReturn: IUsersResponse = {
      status: '200',
      message: '',
    };

    try {
      let _config = {
        "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT)
      };
      await Api.get('/users', { params: _config })
        .then(resp => {
          if (resp.data.data && resp.data.data.length > 0) {
            for (let reg of resp.data.data) {
              const _item: IUsers = {
                guidcontrol: reg.guidcontrol ? reg.guidcontrol.value : '',
                rcacode: reg.rcacode ? reg.rcacode.value : '',
                name: reg.name ? reg.name.value : '',
                documentnumber: reg.documentnumber ? reg.documentnumber.value : '',
                department: reg.department ? reg.department.value : '',
                active: reg.active.value === 1 ? true : false,
              }
              if (validFilters(_item, params) && reg.rcacode.value !== 'ADM0001') {
                _arrayReg.push(_item)
              }
            }
          }
          regReturn.list = _arrayReg;
          resolve(regReturn);
        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = msgerror;
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('Users.Service.getAll.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}

export default getUsersService