import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { HomeSlice } from 'Module/Home/Redux';
import { ProfileSlice } from '../Module/Profile/Redux/index';
import { LayoutSlice } from '../Module/Layout/Redux/index';
import { AssociatesSlice } from 'Module/Associates/Redux/index';
import { SuppliersSlice } from 'Module/Suppliers/Redux/index';
import { ProductsSlice } from 'Module/Products/Redux/index';
import { ReportsSlice } from 'Module/Reports/Redux/index';
import { ReportsDefaultSlice } from 'Module/ReportsDefault/Redux';
import { AssociatesReportSlice } from 'Module/AssociatesReport/Redux/index';
import { SuppliersReportSlice } from 'Module/SuppliersReport/Redux/index';
import { TargetsProductSlice } from 'Module/TargetsProduct/Redux/index';
import { UsersSlice } from 'Module/Users/Redux/index';

export const store = configureStore({
  reducer: {
    profile: ProfileSlice.reducer,
    layout: LayoutSlice.reducer,
    associates: AssociatesSlice.reducer,
    suppliers: SuppliersSlice.reducer,
    products: ProductsSlice.reducer,
    reports: ReportsSlice.reducer,
    reportsdefault: ReportsDefaultSlice.reducer,
    associatesreport: AssociatesReportSlice.reducer,
    suppliersreport: SuppliersReportSlice.reducer,
    targetsproduct: TargetsProductSlice.reducer,
    home: HomeSlice.reducer,
    user: UsersSlice.reducer,
  },
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
