import React from 'react';
import momenttz from 'moment-timezone';
import sortBy from 'sort-by';

import RegisterStructure from 'Shared/Constant/HomeTargetsSupplier/RecordStructure';
import DownloadExcelFile from 'Shared/Components/DownloadExcel/index';
import { Box, Grid } from '@mui/material';

function ReportsExportFilePage(props) {
  const [listHeader, setListHeader] = React.useState([]);
  const [listRows, setListRows] = React.useState([]);

  const getFieldsStructure = React.useCallback((list) => {
    let _array = [];
    if (list && list.length > 0) {
      for (let regField of list.sort(sortBy('orderFormMain'))) {
        if (regField.viewFormMain && !regField.hiddenFormMain) {
          _array.push(regField.label)
        }
      }
      setListHeader(_array);
    }
    else {
      setListHeader(_array);
    }
  }, [])

  const toggleSetData = (values, header) => {
    const _array = [];
    if (values && values.length > 0) {
      // const _columnSort = header.find(elH => elH.defaultSort);
      // const _fieldSort = _columnSort ? _columnSort.nameField : 'id';
      // const _signalSort = _columnSort && _columnSort.defaultSort === 'desc' ? '-' : '';
      // const _listsort = values.sort(sortBy(`${_signalSort}${_fieldSort}`))
      let _listsort = values

      for (let reg of _listsort) {
        let newObjMain = {};
        for (let regHeader of header.sort(sortBy('orderFormMain'))) {
          if (regHeader.viewFormMain && !regHeader.hiddenFormMain) {
            let _value = reg[regHeader.nameField];
            if (regHeader.nameField.includes('document')) {
              if (_value.length <= 11) {
                _value = `CPF: ${reg[regHeader.nameField]}`
              }
              else {
                _value = `CNPJ: ${reg[regHeader.nameField]}`
              }
            }
            if (regHeader.nameField === 'ean') {
              _value = `EAN: ${reg[regHeader.nameField]}`
            }

            if (regHeader.label) {
              switch (regHeader.type) {
                case 'checkbox':
                  if (_value || _value === '1') {
                    newObjMain[regHeader.label] = 'Sim'
                  }
                  else {
                    newObjMain[regHeader.label] = 'Não'
                  }
                  break;
                default:
                  newObjMain[regHeader.label] = _value || ' '
                  break;
              }
            }
          }
        }
        _array.push(newObjMain);
      }
    }
    setListRows(_array);
  }

  React.useEffect(() => {
    getFieldsStructure(RegisterStructure);
    toggleSetData(props.showResume ? props.listHomeTargetSupplierAssociateResume : props.listHomeTargetSupplierAssociate, RegisterStructure);
  }, [props.showResume, props.listHomeTargetSupplierAssociate, props.listHomeTargetSupplierAssociateResume, props.dateUpdateInfo, getFieldsStructure])


  const handlerExcel = {
    sheet: 'Resultado',
    table: 'table-to-xls',
    headerList: listHeader,
    bodyList: listRows,
    fileName: `${momenttz().tz('America/Sao_Paulo').format('YYYY-MM-DD')}-RelatorioPorFornecedor`
  }

  const RenderContent = () => {
    return (
      <Grid container >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DownloadExcelFile {...handlerExcel} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Box key="HomeExportCases" className="homeExportCases">
      <Grid container >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <RenderContent />
        </Grid>
      </Grid>
    </Box>
  );
}


export default ReportsExportFilePage;
