import {
  Box, Breadcrumbs, Grid, Link, Typography,
} from '@mui/material';
import { HOME } from 'Shared/Constant/Routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHomeAlt,
} from '@fortawesome/free-solid-svg-icons';

import TabPanelPage from './HomeTabPanel/index';
import { IHomePage } from '../Interface';

function HomePage(props: IHomePage) {
  return (
    <Box id="Home" key="Home" className="ChildrenRoot">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left">
            <Box id="BreadcrumbsBox" className="Breadcrumbs">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href={HOME}>
                  <FontAwesomeIcon icon={faHomeAlt} />
                </Link>
              </Breadcrumbs>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TabPanelPage {...props} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomePage;
