
import {
  Box, Grid, Typography,
} from '@mui/material';

import { IAssociateDetailPage } from '../../Interface';

import LogoAssociateTop from '../../../../Assets/lead_120.png';
import FieldPage from './_field';
function AssociateDetailHeaderPage(props: IAssociateDetailPage) {

  return (
    <Box id="AssociateDetailHeaderPage" key="AssociateDetailHeaderPage" className="AssociateDetailHeaderRoot">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left" className='AssociateDetailHeaderBox'>
            <Box className='AssociateDetailHeaderImgLogoBox'>
              <img
                src={`${LogoAssociateTop}`}
                srcSet={`${LogoAssociateTop}`}
                alt="Logo Associate"
                loading="lazy"
                className='AssociateDetailHeaderImgLogo'
              />
            </Box>
            <Box className='AssociateDetailHeaderTitleBox'>
              <Typography component="div" align="left">
                <span className='AssociateDetailHeaderSubTitle'>Associado</span>
              </Typography>
              <Typography component="div" align="left">
                <span className='AssociateDetailHeaderTitle'>{props.item.nome}</span>
              </Typography>
            </Box>
            <Box className='AssociateDetailHeaderButtonBox'>
              <Typography component="div" align="right">
                {/* <Button
                  variant="contained"
                  color="success"
                  className="ButtonFinish"
                  type="button"
                >
                  <span>Finalizar Venda</span>
                </Button> */}
              </Typography>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="center" className='AssociateDetailHeaderInfoBox'>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'CPF/CNPJ'} value={props.item.documento || ''} />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'E-Mail'} value={props.item.email || ''} />
              </Grid>
              <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'Ativo'} value={props.item.active ? 'Sim' : 'Não'} />
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AssociateDetailHeaderPage;
