import React from 'react';

import {
  Box, CircularProgress, Grid, Typography
} from '@mui/material';

import PanelCustomPage from 'Shared/Components/PanelCustom/index';
import AssociatesReportPage from '../../../AssociatesReport/Controller/index';
import { IHomePage, IHomeResultPanel } from 'Module/Home/Interface';

function HomeAssociatePage(props: IHomePage) {
  const [resultPanelAssociates, setResultPanelAssociates] = React.useState<IHomeResultPanel>({ result: [] })
  const toggleResultPanelAssociates = (values: IHomeResultPanel) => {
    setResultPanelAssociates(values)
  }

  React.useEffect(() => {
    if (props.resultPanelAssociates) {
      toggleResultPanelAssociates(props.resultPanelAssociates);
    }

  }, [props.resultPanelAssociates, props.dateUpdateInfo])

  return (
    <Box id="HomeAssociatePage" key="HomeAssociatePage">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            {props.showLoadingGoalMix ?
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component={'div'} align='center'>
                  <CircularProgress size={50} />
                </Typography>
              </Grid>
              :
              <React.Fragment>
                {resultPanelAssociates && resultPanelAssociates.result.length > 0 &&
                  resultPanelAssociates.result.map((el, index) => {
                    return (
                      <Grid item key={index} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Box className='PanelCustomPageBox'>
                          <PanelCustomPage
                            key={index}
                            title={el ? el.title : ''}
                            value={el ? el.value : ''}
                            type={el ? el.type : ''}
                            alert={el ? el.alert : ''}
                            anotation={el ? el.anotation : ''}
                          />
                        </Box>
                      </Grid>
                    )
                  })
                }
              </React.Fragment>
            }
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <AssociatesReportPage {...props} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomeAssociatePage;
