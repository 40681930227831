import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IProfile } from '../Interface/index';

interface ProfileState {
  profile: IProfile
}

const initialState: ProfileState = {
  profile: {
    id: '', nome: '', cargo: '', email: '', documento: '', userid: ''
  },
};

export const ProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    saveProfile: (state, action: PayloadAction<IProfile>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.profile = payload;
    },
  },
});

export default ProfileSlice.reducer;
export const { saveProfile } = ProfileSlice.actions;
