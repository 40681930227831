const StringConstants = {
  SLASH: '/',
  BREAK_LINE_CHAR: '\n',
  BLANK: '',
  SPACE: ' ',
  DOT: '.',
  COMMA: ',',
  ZERO_STRING: '0',
  TIME_DIVISOR: ':',
  PERCENTAGE_SYMBOL: '%',
  UNDERLINE: '_',
  HYPHEN: '-',
  STAR: '*',
  AT: '@',
};

export default StringConstants;
