
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHomeAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Box, Grid, Typography, Breadcrumbs, Button,
} from '@mui/material';

import { HOME, PRODUCTNEW, PRODUCTS } from 'Shared/Constant/Routes';
import { IProductsPage } from '../Interface';
import FiltersPage from './Filters/index';
import ResultPage from './Result/index';
import ModalImportFile from 'Shared/Components/ModalImportFile/index';

function ProductsPage(props: IProductsPage) {

  const handleOpenModal = () => {
    if (props.toggleShowModalImportFile) {
      props.toggleShowModalImportFile(true)
    }
  }

  const handleProductCreateTarget = () => {
    if (props.toggleProductCreateTarget) {
      props.toggleProductCreateTarget();
    }
  }

  return (
    <Box id="Products" key="Products" className="ChildrenRoot">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left">
            <Box id="BreadcrumbsBox" className="Breadcrumbs">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={HOME}>
                  <FontAwesomeIcon icon={faHomeAlt} />
                </Link>
                <Link color="inherit" to={PRODUCTS}>
                  Produtos
                </Link>
              </Breadcrumbs>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          {props.isTypeGroupEdit &&
            <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
              <Link color="inherit"
                to={{
                  pathname: PRODUCTNEW
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  className="ButtonPrimary"
                  type="button"
                  onClick={() => { }}
                >
                  <span>* Novo Produto</span>
                </Button>
              </Link>
            </Typography>
          }
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          {props.isTypeGroupEdit &&
            <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
              <Button
                variant="contained"
                color="success"
                className="ButtonPrimary"
                type="button"
                onClick={handleProductCreateTarget}
              >
                <span>Cadastrar Associado/Produtos</span>
              </Button>
            </Typography>
          }
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          {props.isTypeGroupEdit &&
            <Typography component="div" align="right" sx={{ padding: '0px 5px 5px 0px' }}>
              <Button
                variant="contained"
                color="success"
                className="ButtonDanger"
                type="button"
                onClick={handleOpenModal}
              >
                <span>Importar Arquivo Excel</span>
              </Button>
            </Typography>
          }
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FiltersPage {...props} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ResultPage {...props} />
        </Grid>
      </Grid>
      {
        props.showModalImportFile &&
        <ModalImportFile {...props} />
      }
    </Box >
  );
}

export default ProductsPage;
