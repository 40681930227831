import React from 'react';

function ErrorRoute() {
  return (
    <div id="ErrorPage" title="Error">
      <small>Error 404!</small>
    </div>
  );
}

export default ErrorRoute;
