import { ILayoutPage } from 'Module/Layout/Interface';
import React from 'react';
import momenttz from 'moment-timezone';
import MenuListConst from 'Shared/Constant/MenuList';
import { ISideMenuPage, IMenuList } from '../Interface/index';
import SideMenuPage from '../Page/index';

function SideMenuController(props: ILayoutPage) {
  const [listMenu, setListMenu] = React.useState<IMenuList[]>([]);
  const [pathName, setPathName] = React.useState<string>('/');
  const [dataUpdate, setDataUpdate] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format('yyyymmdd hhmmss'));

  const toggleListMenu = (values: IMenuList[]) => {
    setListMenu(values);
  }

  const setRoute = (link: string) => new Promise<boolean>((resolve, reject) => {
    const isResult = true;

    setDataUpdate(momenttz().tz('America/Sao_Paulo').format('yyyyMMdd hhmmss'));
    try {
      props.toggleSetHomePath(link, '');
      resolve(isResult);
    } catch (err) {
      console.log('LogoutController.setLogout.err');
      console.log(err);
      reject(err);
    }
  });

  React.useEffect(() => {
    toggleListMenu(MenuListConst)
  }, [props]);

  React.useEffect(() => {
    if (props?.pathname) {
      setPathName(props?.pathname);
    } else {
      setPathName(window.location.pathname);
    }
  }, [window.location.pathname, props?.pathname]);//eslint-disable-line


  const handlersPage: ISideMenuPage = {
    ...props,
    menu: { open: false, list: listMenu, pathName: pathName },
    dataUpdate: dataUpdate,
    direction: 'left',
    setRoute,
  };

  return (
    <SideMenuPage {...handlersPage} />
  );
}

export default SideMenuController;
