import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHomeAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Box, Grid, Typography, Breadcrumbs, Button,
} from '@mui/material';

import { Link } from 'react-router-dom';

import { HOME, USERS, USERSNEW } from 'Shared/Constant/Routes';
import { IUsersPage } from '../Interface';
import FiltersPage from './Filters/index';
import ResultPage from './Result/index';
import ModalImportFile from 'Shared/Components/ModalImportFile/index';

function UsersPage(props: IUsersPage) {

  return (
    <Box id="Users" key="Users" className="ChildrenRoot">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left">
            <Box id="BreadcrumbsBox" className="Breadcrumbs">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={HOME}>
                  <FontAwesomeIcon icon={faHomeAlt} />
                </Link>
                <Link color="inherit" to={USERS}>
                  Usuários
                </Link>
              </Breadcrumbs>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          {props.isTypeGroupEdit &&
            <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
              <Link color="inherit"
                to={{
                  pathname: USERSNEW
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  className="ButtonPrimary"
                  type="button"
                  onClick={() => { }}
                >
                  <span>* Novo Usuário</span>
                </Button>
              </Link>
            </Typography>
          }
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FiltersPage {...props} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ResultPage {...props} />
        </Grid>
      </Grid>
      {props.showModalImportFile &&
        <ModalImportFile {...props} />
      }
    </Box>
  );
}

export default UsersPage;
