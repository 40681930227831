import { IProfile } from 'Module/Profile/Interface';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import {
  Grid,
  Box,
  Typography,
  IconButton
}
  from '@mui/material';

import LinkMUI from '@mui/material/Link';

import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { HOME } from '../../Constant/Routes';
import packageJson from '../../../../package.json';
import { IHeaderPage } from './interface';

function HeaderProfilePage(props: IHeaderPage) {
  const navigate = useNavigate();

  const [version, setVersion] = React.useState<string>('');
  React.useEffect(() => {
    setVersion(packageJson.version ? packageJson.version : '1.0.0');
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [profile, setProfile] = React.useState<undefined | IProfile>(undefined);
  const toggleProfile = (data: IProfile | undefined) => {
    setProfile(data);
  };

  const toggleModalChangePassword = () => {
    handleClose();
    // eslint-disable-next-line react/destructuring-assignment
    if (props.toggleShowModalChangePassword) {
      props.toggleShowModalChangePassword(true);
    }
  };
  const setLogout = React.useCallback(() => new Promise<boolean>((resolve, reject) => {
    const isResult = true;
    try {
      // eslint-disable-next-line react/destructuring-assignment
      props.toggleSetProfile({
        id: '',
        nome: '',
        cargo: '',
        email: '',
        documento: '',
        userid: ''
      });
      navigate(HOME);
      navigate(0);
      resolve(isResult);
    } catch (err) {
      console.log('LogoutController.setLogout.err');
      console.log(err);
      reject(err);
    }
  }), [navigate, props]);

  const toggleLogout = () => {
    handleClose();
    // eslint-disable-next-line react/destructuring-assignment
    setLogout();
  };


  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleCloseProfile = () => {
  //   setAnchorEl(null);
  //   // eslint-disable-next-line react/destructuring-assignment
  //   props.toggleSetHomePath(PROFILE, '');
  // };

  React.useEffect(() => {
    toggleProfile(props?.profile);
  }, [props]);

  return (
    <Box id="HeaderPerfilBox" data-testid="HeaderProfile">
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className='HeaderMenuIcon'>
            <Typography component="div" align="right" id="HeaderPerfil">
              <LinkMUI
                id='HeaderPerfilGrid'
                onClick={handleMenu}
              >
                <IconButton
                  id='HeaderPerfilIcon'
                  size="large"
                  aria-label="perfil do usuário atual"
                  aria-controls="HeaderProfileMenu"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <span className="HeaderTextHello">Olá,</span>
                <span className="HeaderTextHello">{profile && profile.primeironome ? profile.primeironome : profile && profile.nome ? profile.nome : ''}</span>
                <span className="HeaderTextHello">!&nbsp;</span>
                <i className="fas fa-sort-down HeaderTextHello">&nbsp;</i>
              </LinkMUI>
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Menu
        id="HeaderProfileMenu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem
          component={Link}
          to={PROFILE}
          onClick={handleCloseProfile}
        >
          <span className="HeaderMenuText">Perfil</span>
        </MenuItem> */}
        <MenuItem
          component={Link}
          to={HOME}
          onClick={toggleModalChangePassword}
        >
          <span className="HeaderMenuText">Alterar Senha</span>
        </MenuItem>
        <MenuItem
          component={Link}
          to={HOME}
          onClick={toggleLogout}
        >
          <span className="HeaderMenuText">Sair</span>
        </MenuItem>
        <MenuItem
          onClick={handleClose}
        >
          <span className="HeaderVersion">
            Versão: &nbsp;
            {version}
          </span>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default HeaderProfilePage;
