import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISuppliers } from '../Interface/index';

interface SuppliersState {
  supplierSelected: ISuppliers,
}

const initialState: SuppliersState = {
  supplierSelected: {
    id: '',
    nome: '',
  },
};

export const SuppliersSlice = createSlice({
  name: 'Suppliers',
  initialState,
  reducers: {
    saveSuppliers: (state, action: PayloadAction<ISuppliers>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.supplierSelected = payload;
    },
  },
});

export default SuppliersSlice.reducer;
export const { saveSuppliers } = SuppliersSlice.actions;
