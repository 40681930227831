import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAssociates } from '../Interface/index';

interface AssociatesState {
  associateSelected: IAssociates,
}

const initialState: AssociatesState = {
  associateSelected: {
    id: '',
    nome: '',
  },
};

export const AssociatesSlice = createSlice({
  name: 'associates',
  initialState,
  reducers: {
    saveAssociates: (state, action: PayloadAction<IAssociates>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.associateSelected = payload;
    },
  },
});

export default AssociatesSlice.reducer;
export const { saveAssociates } = AssociatesSlice.actions;
