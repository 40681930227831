import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import ReportDetailPage from '../Page/index';
import { IReportDetailPage } from '../Interface';
import { IReports, IReportsContext } from 'Module/Reports/Interface';
import { REPORTS } from 'Shared/Constant/Routes';
import RecordStructureConstant from 'Shared/Constant/Reports/RecordStructure';

function ReportDetailController(props: ILayoutPage) {
  const [contextReports] = React.useState<IReportsContext>({ result: [] });
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [item, setItem] = React.useState<IReports>({});
  const toggleSetItem = (itemSelected: IReports) => {
    setItem(itemSelected);
  }

  const toggleSetReports = (reports: IReports, action: string) => {

  };

  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'item':
        toggleSetItem(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  React.useEffect(() => {
    let _item: IReports = {};
    if (props.reportSelected) {
      _item = props.reportSelected
    }
    toggleChangeState('item', _item);
    if (!_item.nomeCliente) {
      let layoutAlert: ILayout = {
        loading: false,
        typeMessage: 'error',
        titleMessage: 'Não encontrado',
        message: 'Erro ao pesquisar os detalhes do relatório. Tente pesquisar novamente',
      }
      props.toggleSetLayout(layoutAlert);
      setTimeout(() => {
        window.location.href = `${REPORTS}#search`;
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reportSelected])


  const handlerPage: IReportDetailPage = {
    ...props,
    context: contextReports,
    showLoadingThisPage,
    dateUpdateInfo,
    item,
    structure: RecordStructureConstant,
    toggleChangeState,
    toggleSetReports,
  }
  return (
    <React.Fragment>
      <ReportDetailPage {...handlerPage} />

    </React.Fragment>
  )
}


export default ReportDetailController;
