import SecureLS from 'secure-ls';

const executeCommand = (key: string, value: string) => {
  const ls = new SecureLS({
    encodingType: 'aes',
    isCompression: false,
  });

  try {
    ls.set(key, value);
  } catch (err) {
    console.log('_setVariable.err');
    console.log(err);
    return false;
  }

  return true;
};

export default executeCommand;
