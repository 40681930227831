import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import React from 'react';
import momenttz from 'moment-timezone';

import { IResetPasswordUserContext, IResetPasswordUserPage, IResetPasswordUserResponse } from '../Interface';
import ResetPasswordUserPage from '../Page/index';
import SetResetPasswordUserService from '../Service/SetResetPasswordUser/index';
import GetUsersService from 'Module/Users/Service/Get';
import { IUsers, IUsersFilters, IUsersOptionList, IUsersResponse } from 'Module/Users/Interface';

function ResetPasswordUserController(props: ILayoutPage) {
  const [contextResetPasswordUser] = React.useState<IResetPasswordUserContext>({ result: [] });
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [filters, setFilters] = React.useState<IUsersFilters>({
    rcacode: '', name: '', documentnumber: '', active: 'Todos',
  });
  const [listUsers, setListUsers] = React.useState<[IUsers?]>([]);
  const [itemSelectList, setItemSelectList] = React.useState<string>('');
  const [OptSelectListConst, setOptSelectListConst] = React.useState<[IUsersOptionList?]>([
    { value: '', label: 'Todas' }
  ]);
  const toggleSetResetPasswordUser = (field: IUsers) => {
    setShowLoadingThisPage(true);
    let isFilter = true;
    let _findUser = listUsers.find(elU => elU?.guidcontrol === field.guidcontrol);
    if (_findUser) {
      _findUser.password = field.password;
      _findUser.confirmpassword = field.confirmpassword;
    }
    else {
      isFilter = false
    }

    if (isFilter) {
      const _listUsers: [IUsers] = [_findUser ? _findUser : {}]
      SetResetPasswordUserService(_listUsers)
        .then((response: IResetPasswordUserResponse) => {
          let layoutAlert: ILayout = {
            loading: false,
            typeMessage: 'success',
            titleMessage: 'Salvo',
            message: 'Registro alterado com sucesso',
          }
          props.toggleSetLayout(layoutAlert);
          setShowLoadingThisPage(false);
          if (props.toggleShowModalResetPasswordUser) {
            props.toggleShowModalResetPasswordUser(false)
          }
        })
        .catch((error: IResetPasswordUserResponse) => {
          console.log(error.message);
          let layoutAlert: ILayout = {
            loading: false,
            typeMessage: 'error',
            titleMessage: 'Erro ao alterar a senha',
            message: error.message,
          }
          props.toggleSetLayout(layoutAlert);
          setShowLoadingThisPage(false);
        })
    }
    else {
      let layoutAlert: ILayout = {
        loading: false,
        typeMessage: 'error',
        titleMessage: 'Erro ao alterar a senha',
        message: 'Ocorreu um erro para encontrar o usuário. Tente novamente!',
      }
      props.toggleSetLayout(layoutAlert);
      setShowLoadingThisPage(false);
    }
  };

  const handleChange = (event: any) => {
    setItemSelectList(event ? event.value : '');
  };

  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'filters':
        setFilters(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  const GetUsers = React.useCallback(async (filters: IUsersFilters) => {

    await GetUsersService(filters)
      .then((response: IUsersResponse) => {
        setListUsers(response.list || []);
        let _array: [IUsersOptionList] = [{ value: '', label: 'Todas' }];
        if (response.list && response.list.length > 0) {
          for (let item of response.list) {
            _array.push({
              value: `${item?.guidcontrol}`,
              label: `${item?.name} - ${item?.documentnumber}`
            })
          }
          setOptSelectListConst(_array);
        }
        else {
          setOptSelectListConst(_array);
        }

        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
      .catch((error: IUsersResponse) => {
        let layoutAlert: ILayout = {
          context: props.layout.context,
          titleMessage: 'Pesquisar registros',
          typeMessage: 'error',
          message: error.message,
          loading: false,
        };

        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })

  }, [props]);

  React.useEffect(() => {
    const _filters: IUsersFilters = {
      rcacode: '',
      name: '',
      documentnumber: '',
      active: '',
    }
    GetUsers(_filters);
  }, [GetUsers, props]);

  const handlersPage: IResetPasswordUserPage = {
    ...props,
    context: contextResetPasswordUser,
    dateUpdateInfo,
    filters,
    listUsers,
    itemSelectList,
    showLoadingThisPage,
    OptSelectListConst,
    toggleSetResetPasswordUser,
    handleChange
  };

  return (
    <React.Fragment>
      <ResetPasswordUserPage {...handlersPage} />
    </React.Fragment>
  );
}

export default ResetPasswordUserController;
