import {
  Box, Grid, Typography
} from '@mui/material';

// import PanelCustomPage from 'Shared/Components/PanelCustom/index';
import TargetProductPage from '../../../TargetsProduct/Controller/index';

import { IHomePage } from 'Module/Home/Interface';

function HomeProductPage(props: IHomePage) {

  // const [resultPanelProducts, setResultPanelProducts] = React.useState<IHomeResultPanel>({ result: [] })
  // const toggleResultPanelProducts = (values: IHomeResultPanel) => {
  //   setResultPanelProducts(values)
  // }

  // React.useEffect(() => {
  //   if (props.resultPanelProducts) {
  //     toggleResultPanelProducts(props.resultPanelProducts);
  //   }

  // }, [props.resultPanelProducts, props.dateUpdateInfo])


  return (
    <Box id="HomeProductPage" key="HomeProductPage">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component={'div'} align='center'>
            <span>&nbsp;</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TargetProductPage {...props} />
        </Grid>
      </Grid>
    </Box >
  );
}

export default HomeProductPage;
