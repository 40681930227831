import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout, ILayoutPage } from 'Module/Layout/Interface';
import { IReports, IReportsContext, IReportsFilters, IReportsPage, IReportsResponse } from '../Interface';
import ReportsPage from '../Page/index';
import { getReportsDatesService } from '../Service/GetByDate/index';
import { KEY_REPORTS_SELECTED } from 'Shared/Constant/Cache';
import { IRendererFileExcelStructure } from 'Shared/Interfaces/RendererFileExcel';
import convertToNumber from 'Shared/Utils/ConvertToNumber';
import { getSuppliersService } from 'Module/Suppliers/Service/Get/index';
import { getAssociatesService } from 'Module/Associates/Service/Get/index';
import { setReportBlockService } from '../Service/SaveBlock/index';
import { setTargetService } from '../Service/SetTargets/index';
import { setProductQtdeCx } from 'Module/TargetsProduct/Service/SetProductQtdeSales/index';
import { setProductAutoService } from 'Module/Products/Service/createProductAuto/index';

import { ISuppliers, ISuppliersFilters, ISuppliersResponse } from 'Module/Suppliers/Interface';
import { IAssociates, IAssociatesFilters, IAssociatesResponse } from 'Module/Associates/Interface';

function ReportsController(props: ILayoutPage) {
  const [contextReports] = React.useState<IReportsContext>({ result: [] });
  const [showLoadingThisPage, setShowLoadingThisPage] = React.useState<boolean>(true);
  const [dateUpdateInfo, setDateUpdateInfo] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const [filters, setFilters] = React.useState<IReportsFilters>({
    nomecliente: '',
    nomeproduto: '',
    nomefornecedor: '',
    datainicio: momenttz().tz('America/Sao_Paulo').add(-15, 'days').format('YYYY-MM-DD'),
    datafim: momenttz().tz('America/Sao_Paulo').format('YYYY-MM-DD')
  });

  const toggleChangeState = (field: string, value: any) => {
    switch (field) {
      case 'dateUpdateInfo':
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'showLoadingThisPage':
        setShowLoadingThisPage(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      case 'filters':
        setFilters(value);
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
      default:
        setDateUpdateInfo(momenttz().tz('America/Sao_Paulo').format());
        break;
    }
  }

  const [listReports, setListReports] = React.useState<[IReports?]>([]);
  const toggleGetReports = React.useCallback(async (filters: IReportsFilters) => {
    toggleChangeState('showLoadingThisPage', true);
    toggleChangeState('filters', filters)

    await getReportsDatesService(filters)
      .then((response: IReportsResponse) => {
        setListReports(response.list || []);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
      .catch((error: IReportsResponse) => {
        let layoutAlert: ILayout = {
          context: props.layout.context,
          titleMessage: 'Pesquisar registros',
          typeMessage: 'error',
          message: error.message,
          loading: false,
        };

        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSetReports = (reports: IReports, action: string) => {

  };

  const [listSuppliers, setListSuppliers] = React.useState<[ISuppliers?]>([]);
  const toggleGetSuppliers = React.useCallback(async (filters: ISuppliersFilters) => {
    toggleChangeState('showLoadingThisPage', true);
    toggleChangeState('filters', filters)

    await getSuppliersService(filters)
      .then((response: ISuppliersResponse) => {
        setListSuppliers(response.list || []);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
      .catch((error: ISuppliersResponse) => {
        let layoutAlert: ILayout = {
          context: props.layout.context,
          titleMessage: 'Pesquisar registros',
          typeMessage: 'error',
          message: error.message,
          loading: false,
        };

        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [listAssociates, setListAssociates] = React.useState<[IAssociates?]>([]);
  const toggleGetAssociates = React.useCallback(async (filters: IAssociatesFilters) => {
    toggleChangeState('showLoadingThisPage', true);
    toggleChangeState('filters', filters)

    await getAssociatesService(filters)
      .then((response: IAssociatesResponse) => {
        setListAssociates(response.list || []);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
      .catch((error: IAssociatesResponse) => {
        let layoutAlert: ILayout = {
          context: props.layout.context,
          titleMessage: 'Pesquisar registros',
          typeMessage: 'error',
          message: error.message,
          loading: false,
        };

        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
        toggleChangeState('dateUpdateInfo', '');
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleUpdateTargets = async () => {
    toggleChangeState('showLoadingThisPage', true);
    try {

      await setTargetService()
        .then((response: IReportsResponse) => {
          let layoutAlert: ILayout = {
            loading: false,
            typeMessage: 'success',
            titleMessage: 'Salvo',
            message: 'Registros alterados com sucesso',
          }
          props.toggleSetLayout(layoutAlert);
          toggleChangeState('showLoadingThisPage', false);
        })
        .catch((error: IReportsResponse) => {
          debugger //catch err

          let layoutAlert: ILayout = {
            loading: false,
            typeMessage: 'error',
            titleMessage: 'Erro importar',
            message: error.msgErro,
          }
          props.toggleSetLayout(layoutAlert);
          toggleChangeState('showLoadingThisPage', false);
        })
    }
    catch (err) {
      console.log(err);
      debugger //catch err

      let layoutAlert: ILayout = {
        loading: false,
        typeMessage: 'error',
        titleMessage: 'Erro importar',
        message: 'Ocorreu um erro!',
      }
      props.toggleSetLayout(layoutAlert);
      toggleChangeState('showLoadingThisPage', false);
    }
  }

  const toggleCreateProductAuto = async () => {
    toggleChangeState('showLoadingThisPage', true);
    try {

      await setProductAutoService()
        .then((response: IReportsResponse) => {
          let layoutAlert: ILayout = {
            loading: false,
            typeMessage: 'success',
            titleMessage: 'Salvo',
            message: 'Registros alterados com sucesso',
          }
          props.toggleSetLayout(layoutAlert);
          toggleChangeState('showLoadingThisPage', false);
        })
        .catch((error: IReportsResponse) => {
          debugger //catch err

          let layoutAlert: ILayout = {
            loading: false,
            typeMessage: 'error',
            titleMessage: 'Erro importar',
            message: error.msgErro,
          }
          props.toggleSetLayout(layoutAlert);
          toggleChangeState('showLoadingThisPage', false);
        })
    }
    catch (err) {
      console.log(err);
      debugger //catch err

      let layoutAlert: ILayout = {
        loading: false,
        typeMessage: 'error',
        titleMessage: 'Erro importar',
        message: 'Ocorreu um erro!',
      }
      props.toggleSetLayout(layoutAlert);
      toggleChangeState('showLoadingThisPage', false);
    }
  }


  const toggleProductsQtdeCx = async () => {
    toggleChangeState('showLoadingThisPage', true);
    try {

      await setProductQtdeCx()
        .then((response: IReportsResponse) => {
          let layoutAlert: ILayout = {
            loading: false,
            typeMessage: 'success',
            titleMessage: 'Salvo',
            message: 'Registros alterados com sucesso',
          }
          props.toggleSetLayout(layoutAlert);
          toggleChangeState('showLoadingThisPage', false);
        })
        .catch((error: IReportsResponse) => {
          debugger //catch err

          let layoutAlert: ILayout = {
            loading: false,
            typeMessage: 'error',
            titleMessage: 'Erro importar',
            message: error.msgErro,
          }
          props.toggleSetLayout(layoutAlert);
          toggleChangeState('showLoadingThisPage', false);
        })
    }
    catch (err) {
      console.log(err);
      debugger //catch err

      let layoutAlert: ILayout = {
        loading: false,
        typeMessage: 'error',
        titleMessage: 'Erro importar',
        message: 'Ocorreu um erro!',
      }
      props.toggleSetLayout(layoutAlert);
      toggleChangeState('showLoadingThisPage', false);
    }
  }


  const toggleImportFile = async (struct: IRendererFileExcelStructure) => {
    toggleChangeState('showLoadingThisPage', true);
    let filtro = true;
    let messageError = '';
    let _arrayUpdate: [IReports?] = [];
    try {

      if (!struct.rows || !struct.columns) {
        messageError = 'Nenhuma informação encontrada.'
        filtro = false;
      }
      if (struct.rows && struct.rows.length === 0) {
        messageError = 'Nenhuma informação encontrada.'
        filtro = false;
      }
      if (struct.columns && struct.columns.length === 0) {
        messageError = 'Nenhuma informação encontrada (erro colunas).'
        filtro = false;
      }

      if (filtro) {
        if (struct.rows && struct.rows.length > 0) {
          for (let item of struct.rows) {
            let filterPush = true;
            const reg: any = JSON.parse(item);
            const _columnsReg = Object.keys(reg);
            if (_columnsReg && _columnsReg.length > 0) {
              let regUpdate: IReports = {
                active: true,
                usercreate: props.profile.userid,
                ipcreate: props.publicIPInfo,
                devicecreate: 'Portal',
                userupdate: props.profile.userid,
                ipupdate: props.publicIPInfo,
                deviceupdate: 'Portal',
                qtUnitCx: '0',
              };
              for (let key of _columnsReg) {
                if (key) {
                  switch (key.trim().toUpperCase()) {
                    case 'NR. NOTA':
                    case 'NUMNOTA':
                      regUpdate.numeroNota = reg[key] || '';
                      break;
                    case 'DATA EMISSÃO':
                      let _date = '';
                      if (reg[key]) {
                        _date = momenttz(reg[key].trim(), 'DD/MM/YYYY').tz('America/Sao_Paulo').format('YYYY-MM-DD');
                      }
                      regUpdate.dataFaturamento = _date;
                      break;
                    case 'DTFAT':
                      let _dateFat = '';
                      if (reg[key]) {
                        _dateFat = momenttz(reg[key].trim()).tz('America/Sao_Paulo').format('YYYY-MM-DD');
                      }
                      regUpdate.dataFaturamento = _dateFat;
                      break;
                    case 'CODCLI':
                      regUpdate.codCliente = reg[key] || '';
                      break;
                    case 'ASSOCIADO (MATRIZ/FILIAL)':
                    case 'CLIENTE':
                      regUpdate.nomeCliente = reg[key] || '';
                      break;
                    case 'ASSOCIADO (MATRIZ/FILIAL) CNPJ':
                      regUpdate.isAreaCentral = true;
                      regUpdate.documentoCliente = reg[key] ? `${reg[key]}`.trim().replace(/\./g, '').replace(/-/g, '').replace(/\//g, '').replace(/,/g, '') : '';
                      if (listAssociates && listAssociates.length > 0) {
                        const _findAssociates = listAssociates.find(elAssociate => elAssociate?.documento === regUpdate.documentoCliente);
                        regUpdate.codCliente = _findAssociates && _findAssociates.codigo ? _findAssociates.codigo : '';
                        if (!_findAssociates) {
                          filterPush = false;
                        }
                      }
                      break;
                    case 'CGCENT':
                      regUpdate.documentoCliente = reg[key] ? `${reg[key]}`.trim().replace(/\./g, '').replace(/-/g, '').replace(/\//g, '').replace(/,/g, '') : '';
                      if (listAssociates && listAssociates.length > 0) {
                        const _findAssociates = listAssociates.find(elAssociate => elAssociate?.documento === regUpdate.documentoCliente);
                        if (!_findAssociates) {
                          filterPush = false;
                        }
                      }
                      break;
                    case 'CÓD.':
                    case 'CODPROD':
                      regUpdate.codProduto = reg[key] || '';
                      break;
                    case 'EAN':
                    case 'GTIN':
                    case 'CODAUXILIAR':
                      regUpdate.ean = reg[key] ? `${reg[key]}`.trim().replace(/\./g, '').replace(/-/g, '').replace(/\//g, '').replace(/,/g, '') : '';
                      break;
                    case 'PRODUTO':
                    case 'DESCRICAO':
                      regUpdate.nomeProduto = reg[key] || '';
                      break;
                    case 'CÓD. FORNEC':
                      regUpdate.codFornecedor = reg[key] || '';
                      if (listSuppliers && listSuppliers.length > 0) {
                        const _findSupplier = listSuppliers.find(elSupplier => elSupplier?.codigo === `${reg[key]}`.trim());
                        regUpdate.codFornPrincipal = _findSupplier && _findSupplier.codigoPrincipal ? _findSupplier.codigoPrincipal : '';
                        if (_findSupplier && _findSupplier.codigoPrincipal) {
                          const _findSupplierDefault = listSuppliers.find(elSupplier => elSupplier?.codigo === _findSupplier.codigoPrincipal);
                          regUpdate.nomeFornPrincipal = _findSupplierDefault && _findSupplierDefault.nome ? _findSupplierDefault.nome : '';
                        }
                        if (!_findSupplier) {
                          filterPush = false;
                        }
                      }
                      break;
                    case 'FORNECEDOR (MATRIZ/FILIAL) CNPJ':
                      const _documentSupplier = reg[key] ? `${reg[key]}`.trim().replace(/\./g, '').replace(/-/g, '').replace(/\//g, '').replace(/,/g, '') : '';
                      if (listSuppliers && listSuppliers.length > 0) {
                        const _findSupplier = listSuppliers.find(elSupplier => elSupplier?.documento === _documentSupplier);
                        regUpdate.codFornecedor = _findSupplier && _findSupplier.codigo ? _findSupplier.codigo : '';
                        regUpdate.codFornPrincipal = _findSupplier && _findSupplier.codigoPrincipal ? _findSupplier.codigoPrincipal : '';
                        if (_findSupplier && _findSupplier.codigoPrincipal) {
                          const _findSupplierDefault = listSuppliers.find(elSupplier => elSupplier?.codigo === _findSupplier.codigoPrincipal);
                          regUpdate.nomeFornPrincipal = _findSupplierDefault && _findSupplierDefault.nome ? _findSupplierDefault.nome : '';
                        }
                        if (!_findSupplier) {
                          filterPush = false;
                        }
                      }
                      break;
                    case 'CODFORNEC':
                      regUpdate.codFornecedor = reg[key] || '';
                      break;
                    case 'CODFORNECPRINC':
                      regUpdate.codFornPrincipal = reg[key] || '';
                      break;
                    case 'FORNECPRINC':
                      regUpdate.nomeFornPrincipal = reg[key] || '';
                      break;
                    case 'FORNECEDOR (MATRIZ/FILIAL)':
                    case 'FORNECEDOR':
                      regUpdate.nomeFornecedor = reg[key] || '';
                      break;
                    case 'QTUNITCX':
                      if (reg[key] && !isNaN(reg[key])) {
                        regUpdate.qtUnitCx = reg[key];
                      }
                      else {
                        regUpdate.qtUnitCx = reg[key] ? `${convertToNumber(reg[key])}` : '0';
                      }
                      break;
                    case 'QTDE':
                    case 'CAIXAS':
                      if (reg[key] && !isNaN(reg[key])) {
                        regUpdate.caixas = reg[key];
                      }
                      else {
                        regUpdate.caixas = reg[key] ? `${convertToNumber(reg[key])}` : '0';
                      }
                      break;
                    case 'VAL. FINAL':
                    case 'VLVENDA':
                      if (reg[key] && !isNaN(reg[key])) {
                        regUpdate.vlrVenda = reg[key];
                      }
                      else {
                        regUpdate.vlrVenda = reg[key] ? `${convertToNumber(reg[key])}` : '0.00';
                      }
                      break;
                    case 'VAL. UNIT.':
                    case 'VLUNITARIO':
                      if (reg[key] && !isNaN(reg[key])) {
                        regUpdate.vlrUnitario = reg[key];
                      }
                      else {
                        regUpdate.vlrUnitario = reg[key] ? `${convertToNumber(reg[key])}` : '0.00';
                      }
                      break;
                    default:
                      break;
                  }

                }
              }
              if (filterPush) {
                _arrayUpdate.push(regUpdate);
              }
            }
          }

          const _isImport = true;
          if (_isImport && _arrayUpdate && _arrayUpdate.length > 0) {
            await setReportBlockService(_arrayUpdate)
              .then((response: IReportsResponse) => {
                let layoutAlert: ILayout = {
                  loading: false,
                  typeMessage: 'success',
                  titleMessage: 'Salvo',
                  message: 'Registros salvos com sucesso',
                }
                props.toggleSetLayout(layoutAlert);
                toggleChangeState('showLoadingThisPage', false);

                if (props.toggleShowModalImportFile) {
                  props.toggleShowModalImportFile(false)
                }
                toggleGetReports(filters);
              })
              .catch((error: IReportsResponse) => {
                console.log(error.message);
                let layoutAlert: ILayout = {
                  loading: false,
                  typeMessage: 'error',
                  titleMessage: 'Erro ao salvar',
                  message: error.message,
                }
                props.toggleSetLayout(layoutAlert);
                toggleChangeState('showLoadingThisPage', false);
              })
          }
          else {
            if (props.toggleShowModalImportFile) {
              props.toggleShowModalImportFile(false)
            }
            toggleGetReports(filters);
            toggleChangeState('showLoadingThisPage', false);
          }
        }
        else {
          let layoutAlert: ILayout = {
            loading: false,
            typeMessage: 'success',
            titleMessage: 'Importar',
            message: 'Registros importados com sucesso.',
          }
          props.toggleSetLayout(layoutAlert);
          if (props.toggleShowModalImportFile) {
            props.toggleShowModalImportFile(false)
          }
          toggleGetReports(filters);
          toggleChangeState('showLoadingThisPage', false);
        }
      }
      else {
        let layoutAlert: ILayout = {
          loading: false,
          typeMessage: 'error',
          titleMessage: 'Erro importar',
          message: messageError,
        }
        props.toggleSetLayout(layoutAlert);
        toggleChangeState('showLoadingThisPage', false);
      }

    }
    catch (err) {
      console.log(err);
      debugger //catch err

      let layoutAlert: ILayout = {
        loading: false,
        typeMessage: 'error',
        titleMessage: 'Erro importar',
        message: 'Ocorreu um erro!',
      }
      props.toggleSetLayout(layoutAlert);
      toggleChangeState('showLoadingThisPage', false);
    }
  }

  React.useEffect(() => {
    let _filters: ISuppliersFilters = {}
    toggleGetSuppliers(_filters)

    let _filtersAsociate: IAssociatesFilters = {}
    toggleGetAssociates(_filtersAsociate);
  }, [toggleGetAssociates, toggleGetSuppliers])

  React.useEffect(() => {
    toggleGetReports(filters);
  }, [filters, toggleGetReports]);

  React.useEffect(() => {
    let _showModal = window.location.hash.includes('#Create') ? true : false;
    toggleChangeState('showNewLeadModal', _showModal);
    if (props.toggleDelCache) {
      props.toggleDelCache(KEY_REPORTS_SELECTED)
    }
  }, [props])

  const handlersPage: IReportsPage = {
    ...props,
    context: contextReports,
    listReports,
    showLoadingThisPage,
    dateUpdateInfo,
    filters,
    itemSelected: {},
    toggleChangeState,
    toggleGetReports,
    toggleSetReports,
    toggleImportFile,
    toggleUpdateTargets,
    toggleProductsQtdeCx,
    toggleCreateProductAuto
  };

  return (
    <ReportsPage {...handlersPage} />
  );
}

export default ReportsController;
