import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';

import { ITargetsProductResponse } from '../../Interface/index';

export const setProductQtdeCx = async () => {
  return new Promise<ITargetsProductResponse>(async (resolve, reject) => {
    let regReturn: ITargetsProductResponse = {
      status: '200',
      message: '',
    };

    try {
      let _url = `/produtometaupdatetarget`;
      let _config: any = {
        "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT)
      };

      await Api.get(_url, { params: _config })
        .then(resp => {
          resolve(regReturn);
        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = msgerror;
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('SetProductQtdeSales.Service.setProductQtdeCx.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}