import SecureLS from 'secure-ls';

const executeCommand = (key: string) => {
  const ls = new SecureLS({
    encodingType: 'aes',
    isCompression: false,
  });

  try {
    if (key) {
      ls.remove(key);
    } else {
      ls.removeAll();
    }
  } catch (err) {
    console.log('_removelAll.err');
    console.log(err);
    return false;
  }

  return true;
};

export default executeCommand;
