import {
  Box, Grid, Typography,
} from '@mui/material';

import { IProductNewPage } from '../../Interface';

import LogoProductTop from '../../../../Assets/custom45_120.png';
import FieldPage from './_field';
function ProductNewHeaderPage(props: IProductNewPage) {

  return (
    <Box id="ProductNewHeaderPage" key="ProductNewHeaderPage" className="ProductNewHeaderRoot">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left" className='ProductNewHeaderBox'>
            <Box className='ProductNewHeaderImgLogoBox'>
              <img
                src={`${LogoProductTop}`}
                srcSet={`${LogoProductTop}`}
                alt="Logo Product"
                loading="lazy"
                className='ProductNewHeaderImgLogo'
              />
            </Box>
            <Box className='ProductNewHeaderTitleBox'>
              <Typography component="div" align="left">
                <span className='ProductNewHeaderSubTitle'> * Novo Produtos</span>
              </Typography>
              <Typography component="div" align="left">
                <span className='ProductNewHeaderTitle'>{props.item.nome}</span>
              </Typography>
            </Box>
            <Box className='ProductNewHeaderButtonBox'>
              <Typography component="div" align="right">
              </Typography>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="center" className='ProductNewHeaderInfoBox'>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'Código'} value={props.item.codigo || ''} />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'EAN'} value={props.item.ean || ''} />
              </Grid>
              <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                <FieldPage label={'Ativo'} value={props.item.active ? 'Sim' : 'Não'} />
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProductNewHeaderPage;
