import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { ILayout } from 'Module/Layout/Interface';
import CircularProgress from '@mui/material/CircularProgress';

export default function TransitionAlerts(props: ILayout) {
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    if (props?.loading) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props]);

  return (
    <Box className="LoadingBox">
      <Collapse in={open}>
        <CircularProgress color="warning" size={100} />
      </Collapse>
    </Box>
  );
}
