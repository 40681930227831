import { IFieldStructure } from "Shared/Interfaces/ObjectSalesforce";

const List: IFieldStructure[] = [
  { nameField: 'id', label: 'Id', group: 'Informações do Fornecedor', viewFormMain: true, orderFormMain: 0, viewFormDetail: true, orderFormDetail: 8, edit: false, type: 'string', options: [], hiddenFormMain: true },
  { nameField: 'codigo', label: 'Código', group: 'Informações do Fornecedor', viewFormMain: true, orderFormMain: 1, viewFormDetail: true, orderFormDetail: 2, edit: true, type: 'string', options: [], hiddenFormMain: false, width: "45px" },
  { nameField: 'nome', label: 'Nome', group: 'Informações do Fornecedor', viewFormMain: true, orderFormMain: 1, viewFormDetail: true, orderFormDetail: 2, edit: true, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'documento', label: 'CPF/CNPJ', group: 'Informações do Fornecedor', viewFormMain: true, orderFormMain: 2, viewFormDetail: true, orderFormDetail: 4, edit: true, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'codigoPrincipal', label: 'Código Principal', group: 'Informações do Fornecedor', viewFormMain: true, orderFormMain: 4, viewFormDetail: true, orderFormDetail: 5, edit: true, type: 'string', options: [], hiddenFormMain: false, width: "45px" },
  { nameField: 'active', label: 'Ativo', group: 'Informações do Fornecedor', viewFormMain: true, orderFormMain: 5, viewFormDetail: true, orderFormDetail: 1, edit: true, type: 'checkbox', options: [{ value: true, label: 'Sim' }, { value: false, label: 'Não' }], hiddenFormMain: false },
  { nameField: 'criadoEm', label: 'Criado em', group: 'Informações do Fornecedor', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 6, edit: false, type: 'string', options: [], hiddenFormMain: false },
  { nameField: 'alteradoEm', label: 'Alterado em', group: 'Informações do Fornecedor', viewFormMain: false, orderFormMain: 99, viewFormDetail: true, orderFormDetail: 7, edit: false, type: 'string', options: [], hiddenFormMain: false },
];

export default List;