import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IReports } from '../Interface/index';

interface ReportsState {
  reportSelected: IReports,
}

const initialState: ReportsState = {
  reportSelected: {
    id: '',
    nomeProduto: '',
  },
};

export const ReportsSlice = createSlice({
  name: 'Reports',
  initialState,
  reducers: {
    saveReports: (state, action: PayloadAction<IReports>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.reportSelected = payload;
    },
  },
});

export default ReportsSlice.reducer;
export const { saveReports } = ReportsSlice.actions;
