const PanelCustom = {
  SUCCESS: 'Success',
  SUCCESS_LIGHT: 'SuccessLight',
  WARNING: 'Warning',
  WARNING_LIGHT: 'WarningLight',
  DANGER: 'Danger',
  DANGER_LIGHT: 'DangerLight',
  TYPE_FAT: 'Faturamento',
  TYPE_MIX: 'Mix',
};

export default PanelCustom;
