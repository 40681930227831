export const HOME = '/';
export const PROFILE = '/profile';
export const LOGIN = '/login';
export const LOGOUT = '/logout';

export const ASSOCIATES = '/associates';
export const ASSOCIATEDETAIL = '/associatedetail';
export const USERS = '/users';
export const USERSDETAIL = '/userdetail';
export const USERSNEW = '/usernew';
export const SUPPLIERS = '/suppliers';
export const SUPPLIERDETAIL = '/supplierdetail';
export const PRODUCTS = '/products';
export const PRODUCTDETAIL = '/productdetail';
export const PRODUCTNEW = '/productnew';
export const REPORTS = '/reports';
export const REPORTDETAIL = '/reportdetail';
export const REPORTSDEFAULT = '/reportsdefault';
export const REPORTDEFAULTDETAIL = '/reportdefaultdetail';