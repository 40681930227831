import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IProducts } from '../Interface/index';

interface ProductsState {
  productSelected: IProducts,
}

const initialState: ProductsState = {
  productSelected: {
    id: '',
    nome: '',
  },
};

export const ProductsSlice = createSlice({
  name: 'Products',
  initialState,
  reducers: {
    saveProducts: (state, action: PayloadAction<IProducts>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.productSelected = payload;
    },
  },
});

export default ProductsSlice.reducer;
export const { saveProducts } = ProductsSlice.actions;
