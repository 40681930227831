import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Form } from '@unform/web';
import SelectItemPage from './_itemList';
import { IHomeTargetSupplierAssociatePage } from 'Module/HomeUserSupplier/Interface/HomeTargetSupplierAssociate';

function LeadListsPage(props: IHomeTargetSupplierAssociatePage) {
  const formDefault = React.useRef<any>(null);
  const handleChange = (e: any) => {
    props.toggleChangeState('itemSelectList', e && e.value ? e.value : '');
  }

  const handleSubmit = (data: any) => {

  }

  const handlerItemSelect = {
    options: props.optionsSelectList,
    itemSelected: props.itemSelectList,
    handleChange
  }

  React.useEffect(() => {
    if (formDefault.current) {
      formDefault.current.setFieldValue('itemselectedlist2', props.optionsSelectList[0]);
    }
  }, [props.optionsSelectList])

  return (
    <Box>
      <Grid container>
        <Grid item xs={8} sm={4} md={3} lg={4} xl={4}>
          <Form
            ref={formDefault}
            id="myFormDefault"
            name="myFormDefault"
            onSubmit={handleSubmit}
          >
            <Typography component="div" align="left">
              <SelectItemPage {...handlerItemSelect} />
            </Typography>
          </Form>
        </Grid>
      </Grid>
    </Box>
  )
}


export default LeadListsPage;