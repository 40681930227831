import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAssociatesReport } from '../Interface/index';
import { IHomeTargetsSupplier } from 'Module/HomeUser/Interface/HomeTargetsSupplier';

interface AssociatesReportState {
  associatesReportSelected: IAssociatesReport,
  associatesReportList: [IAssociatesReport?],
  targetsSupplierSelected: IHomeTargetsSupplier,
}

const initialState: AssociatesReportState = {
  associatesReportSelected: {
    id: '',
    nome: '',
  },
  associatesReportList: [],
  targetsSupplierSelected: {}
};

export const AssociatesReportSlice = createSlice({
  name: 'AssociatesReport',
  initialState,
  reducers: {
    saveAssociatesReport: (state, action: PayloadAction<IAssociatesReport>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.associatesReportSelected = payload;
    },
    saveAssociatesReportList: (state, action: PayloadAction<[IAssociatesReport?]>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.associatesReportList = payload;
    },
    saveTargetsSupplierSelected: (state, action: PayloadAction<IHomeTargetsSupplier>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.targetsSupplierSelected = payload;
    },
  },
});

export default AssociatesReportSlice.reducer;
export const { saveAssociatesReport, saveAssociatesReportList, saveTargetsSupplierSelected } = AssociatesReportSlice.actions;
