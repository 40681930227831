import {
  Box, Typography,
} from '@mui/material';

import BannerTop from 'Assets/banner1200x300.jpg';

function HomeBannerPage() {
  return (
    <Box id="HomeBannerPage" className="HomeUserBannerRoot">
      <Typography component="div" align="center">
        <img
          src={`${BannerTop}`}
          srcSet={`${BannerTop}`}
          alt="Banner"
          loading="lazy"
          className='HomeUserBannerImgLogo'
        />
      </Typography>
    </Box>
  );
}

export default HomeBannerPage;
