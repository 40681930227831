import React from 'react';
import momenttz from 'moment-timezone';
import {
  Box, Button, Grid, Typography,
} from '@mui/material';

import { IReportsFilters, IReportsPage } from '../../Interface';
import { Form } from '@unform/web';
import InputCustom from 'Shared/Components/Form/InputReactCustom';

function ReportsFilterFormPage(props: IReportsPage) {
  const formDefault = React.useRef<any>(null);

  const [initialData, setInitialData] = React.useState<IReportsFilters>({
    nomecliente: props.filters.nomecliente,
    nomeproduto: props.filters.nomeproduto,
    nomefornecedor: props.filters.nomefornecedor,
    datainicio: props.filters.datainicio,
    datafim: props.filters.datafim,
  });

  // const schema = Yup.object().shape({
  //   nomecliente: Yup.string(),
  //   nomeproduto: Yup.string(),
  //   nomefornecedor: Yup.string(),
  //   datainicio: Yup.string(),
  //   datafim: Yup.string(),
  // });

  const handleCleanFilters = () => {
    const _filters: IReportsFilters = {
      nomecliente: '',
      nomeproduto: '',
      nomefornecedor: '',
      datainicio: momenttz().tz('America/Sao_Paulo').add(-15, 'days').format('YYYY-MM-DD'),
      datafim: momenttz().tz('America/Sao_Paulo').format('YYYY-MM-DD')
    };
    props.toggleChangeState('filters', _filters);
  }

  const handleSubmit = (data: any) => {
    const _filters: IReportsFilters = {
      nomecliente: data.nomecliente.toUpperCase(),
      nomeproduto: data.nomeproduto.toUpperCase(),
      nomefornecedor: data.nomefornecedor.toUpperCase(),
      datainicio: data.datainicio,
      datafim: data.datafim
    };
    props.toggleGetReports(_filters);
  };

  React.useEffect(() => {
    setInitialData(props.filters);
    if (formDefault.current) {
      formDefault.current.setFieldValue('datainicio', props.filters.datainicio);
      formDefault.current.setFieldValue('datafim', props.filters.datafim);
      formDefault.current.setFieldValue('nomecliente', props.filters.nomecliente);
      formDefault.current.setFieldValue('nomeproduto', props.filters.nomeproduto);
      formDefault.current.setFieldValue('nomefornecedor', props.filters.nomefornecedor);
    }
  }, [props.filters, props.dateUpdateInfo])

  return (
    <Box id="Reports" key="Reports" className="FormRoot" data-label={props.dateUpdateInfo}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="justify">
            <Box id="AcountFilterForm" key="AcountFilterForm">
              <Form
                ref={formDefault}
                id="myFormDefault"
                name="myFormDefault"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.datainicio}
                        id="datainicio"
                        name="datainicio"
                        label="Data inicial"
                        type="date"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.datafim}
                        id="datafim"
                        name="datafim"
                        label="Data fim"
                        type="date"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.nomecliente}
                        id="nomecliente"
                        name="nomecliente"
                        label="Associado"
                        type="text"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.nomeproduto}
                        id="nomeproduto"
                        name="nomeproduto"
                        label="Produto:&nbsp;"
                        type="text"
                        className="FormInput FormInput100"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="left">
                      <InputCustom
                        autoComplete="off"
                        handleChangeCustom={() => { }}
                        disabled={false}
                        classCustom="FormInput FormInput100"
                        defaultValue={initialData.nomefornecedor}
                        id="nomefornecedor"
                        name="nomefornecedor"
                        label="Fornecedor:&nbsp;"
                        type="text"
                        className="FormInput FormInput100" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="right">
                      <Button
                        variant="contained"
                        color="success"
                        className="ButtonPrimary"
                        type="submit"
                      >
                        <span>Pesquisar</span>
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Typography component="div" align="right">
                      <Button
                        variant="contained"
                        color="success"
                        className="ButtonDefault"
                        type="button"
                        onClick={handleCleanFilters}
                      >
                        <span>Limpar filtros</span>
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
                <span>&nbsp;</span>
              </Form>
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ReportsFilterFormPage;
