import React, { useEffect, useState, memo } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import {
  Box,
} from '@material-ui/core';


function ExportExcel(props) {

  const [headerList, setHeaderList] = useState([]);
  const [bodyList, setBodyList] = useState([]);
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    setHeaderList(props.headerList);
    setBodyList(props.bodyList);
    setFileName(props.fileName);
  }, [props.headerList, props.bodyList, props.fileName]);

  return (
    <Box>
      <i className="fas fa-external-link-alt homeExportCasesLink"></i>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename={fileName}
        sheet="Resultado"
        buttonText="Exportar arquivo XLS" />
      <Box hidden='hidden'>
        <html>
          <head>
            <meta charSet="UTF-8" />
          </head>
          <body>
            <table id="table-to-xls">
              <thead>
                <tr>
                  {headerList && headerList.length > 0 &&
                    headerList.map((elHeader, index) => {
                      return (<th key={index}>{elHeader}</th>)
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {bodyList && bodyList.length > 0 &&
                  bodyList.map((elBody, index) => {
                    console.log(elBody)
                    return (<tr key={index}>
                      {
                        Object.keys(elBody).map((key, index) => {
                          return (
                            <td key={index}>{elBody[key]}</td>
                          )
                        })
                      }
                    </tr>)
                  })
                }
              </tbody>
            </table>
          </body>
        </html>
      </Box>
    </Box>
  );
}

export default memo(ExportExcel);