import React from 'react';
import {
  Box, CircularProgress, Grid, Typography
} from '@mui/material';

import PanelCustomPage from 'Shared/Components/PanelCustom/index';
import SuppliersReportPage from '../../../SuppliersReport/Controller/index';
import { IHomePage, IHomeResultPanel } from 'Module/Home/Interface';

function HomeSupplierPage(props: IHomePage) {

  const [resultPanelSuppliers, setResultPanelSuppliers] = React.useState<IHomeResultPanel>({ result: [] })
  const toggleResultPanelSuppliers = (values: IHomeResultPanel) => {
    setResultPanelSuppliers(values)
  }

  React.useEffect(() => {
    if (props.resultPanelSuppliers) {
      toggleResultPanelSuppliers(props.resultPanelSuppliers);
    }

  }, [props.resultPanelSuppliers, props.dateUpdateInfo])


  return (
    <Box id="HomeSupplierPage" key="HomeSupplierPage">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            {props.showLoadingGoalMix ?
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component={'div'} align='center'>
                  <CircularProgress size={50} />
                </Typography>
              </Grid>
              :
              <React.Fragment>
                {resultPanelSuppliers && resultPanelSuppliers.result.length > 0 &&
                  resultPanelSuppliers.result.map((el, index) => {
                    return (
                      <Grid item key={index} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Box className='PanelCustomPageBox'>
                          <PanelCustomPage
                            key={index}
                            title={el ? el.title : ''}
                            value={el ? el.value : ''}
                            type={el ? el.type : ''}
                            alert={el ? el.alert : ''}
                            anotation={el ? el.anotation : ''}
                          />
                        </Box>
                      </Grid>
                    )
                  })
                }
              </React.Fragment>
            }
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <SuppliersReportPage {...props} />
        </Grid>
      </Grid>
    </Box >
  );
}

export default HomeSupplierPage;
